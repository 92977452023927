import app from '@/app';

/* @ngInject */
function translationStorageProvider($cookies, $log, LANGUAGES) {
	return {
		get: get,
		put: put
	};

	function get(name) {
		if (LANGUAGES.indexOf($cookies.getObject(name)) === -1) {
			$log.info(
				'Resetting invalid cookie language "' +
					$cookies.getObject(name) +
					'" to preferred language "pt-br"'
			);
			$cookies.putObject(name, 'pt-br');
		}
		return $cookies.getObject(name);
	}

	function put(name, value) {
		$cookies.putObject(name, value);
	}
}

app.factory('translationStorageProvider', translationStorageProvider);
