import app from '@/app';
import templateUrl from './decision-tree-leaf.html';

/* @ngInject */
function DecisionTreeLeafController($q, DecisionTree, Solution, Principal) {
	var $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	$ctrl.querySolutions = function(query) {
		$ctrl.loading = true;
		Solution.query({ query, size: 30, sort: 'name,asc' })
			.$promise.then(data => ($ctrl.allSolutions = data.content))
			.finally(() => ($ctrl.loading = false));
	};

	$ctrl.querySubtrees = function(query) {
		$ctrl.loading = true;
		DecisionTree.query({ query, size: 30, sort: 'name,asc' })
			.$promise.then(data => {
				$ctrl.allSubtrees = data.content;
			})
			.finally(() => ($ctrl.loading = false));
	};
}

const component = {
	templateUrl,
	controller: DecisionTreeLeafController,
	bindings: {
		model: '='
	}
};

app.component('prDecicionTreeLeaf', component);
