import app from '@/app';

/* @ngInject */
function StateHelperProvider(PAGE_SIZES) {
	this.$get = () => {};
	this.paramTypes = {
		number: 'int',
		string: 'string'
	};
	this.param = (value, type) => ({
		value: value,
		type: type || this.paramTypes[typeof value],
		squash: true,
		dynamic: true
	});
	this.search = function(base) {
		base.url =
			(base.url || '/' + base.name + 's') +
			(base.url && base.url.includes('?') ? '&' : '?') +
			'page&size&sort&query';
		base.component = base.component || base.name + 'Search';
		base.data = base.data || {};
		base.data.authorities = base.data.authorities || [];
		base.params = base.params || {};
		base.params.page = this.param(0);
		base.params.size = this.param(PAGE_SIZES[0]);
		base.params.sort = this.param('name,asc');
		base.params.query = this.param('');
		base.resolve = base.resolve || {};
		return base;
	};
}

app.provider('stateHelper', StateHelperProvider);
