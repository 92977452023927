import app from '@/app';

/* @ngInject */
function pagerConfig(uibPagerConfig, ITEMS_PER_PAGE) {
	uibPagerConfig.itemsPerPage = ITEMS_PER_PAGE;
	uibPagerConfig.previousText = '«';
	uibPagerConfig.nextText = '»';
}

app.config(pagerConfig);
