import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, PAGE_SIZES, SMALL_PAGE_SIZES) {
	const states = [
		{
			name: 'iqdTest',
			parent: 'analysis',
			url: '/iqdTests?page&sort&size&road&campaign&iqdtype',
			component: 'iqdTestSearch',
			data: {
				viewAuthorities: ['ANALYSIS_READ', 'IQDTEST_READ'],
				editAuthorities: ['ANALYSIS_WRITE', 'ANALYSIS_PARTIAL_WRITE', 'IQDTEST_WRITE']
			},
			params: {
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'name,asc',
					squash: true,
					dynamic: true
				},
				road: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				campaign: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				iqdtype: {
					type: 'int',
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allOrganizations: [
					'Organization',
					Organization => {
						return Organization.query({ sort: 'name,asc' }).$promise;
					}
				],
				/* @ngInject */
				allRoads: Road => Road.query({ sort: 'name,asc', empty: false }).$promise,
				/* @ngInject */
				allCampaigns: Campaign =>
					Campaign.query({ sort: 'name,asc', empty: false }).$promise
			}
		},
		{
			name: 'iqdTest.detail',
			url: '/{id}',
			component: 'iqdTestDetail',
			resolve: {
				/* @ngInject */
				entity: (IqdTest, $transition$, entityInjector) =>
					entityInjector(IqdTest, $transition$.params().id, { items: [] }),
        /* @ngInject */
				allPlans: Plan =>
        Plan.query({plantype: 'P21'}).$promise
			}
		},
		{
			name: 'iqdTest.result',
			url: '/{id}/result',
			component: 'iqdTestResult',
			resolve: {
				/* @ngInject */
				allMetrics: Metadata => Metadata.getMetrics({ measurement: 'KPI' }),
				/* @ngInject */
				entity: (IqdTest, $transition$, entityInjector) =>
					entityInjector(IqdTest, $transition$.params().id, {})
			}
		},
		{
			name: 'iqdTest.overview',
			url: '/{iqdTestId}/overviews/{overviewId}',
			component: 'iqdTestOverviewWrapper'
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
