import app from '@/app';

var component = {
	templateUrl: '/app/components/line-chart/line-chart.html',
	controller: Controller,
	bindings: {
		title: '<',
		entities: '<',
		metric: '<',
		minX: '<',
		maxX: '<',
		minY: '<',
		maxY: '<'
	}
};

/* @ngInject */
function Controller($scope, $translate, _get, _unique, _diff, _plot) {
	var $ctrl = this;

	$ctrl.series = [];
	$ctrl.data = [];
	$ctrl.xAxis = {
		type: 'linear',
		position: 'bottom',
		ticks: {
			min: 0,
			max: 0
		},
		scaleLabel: {
			display: true,
			labelString: $translate.instant('milestone.unit')
		}
	};
	$ctrl.yAxis = {
		id: 'y-axis-1',
		type: 'linear',
		position: 'left',
		ticks: {
			min: 0,
			max: 0
		},
		scaleLabel: {
			display: true,
			labelString: ''
		}
	};

	$ctrl.good = {
		type: 'line',
		mode: 'horizontal',
		scaleID: 'y-axis-1',
		value: 0,
		borderColor: 'rgba(0, 0, 255, 0.5)',
		borderWidth: 4
	};

	$ctrl.bad = {
		type: 'line',
		mode: 'horizontal',
		scaleID: 'y-axis-1',
		value: 0,
		borderColor: 'rgba(255, 0, 0, 0.5)',
		borderWidth: 4
	};

	$ctrl.options = {
		elements: {
			line: {
				fill: false,
				tension: 0
			}
		},
		title: {
			display: true,
			text: ''
		},
		legend: {
			display: true
		},
		scales: {
			xAxes: [$ctrl.xAxis],
			yAxes: [$ctrl.yAxis]
		},
		annotation: {
			annotations: [$ctrl.good, $ctrl.bad]
		},
		animation: false
	};

	var toPoints = _.map(function(entity) {
		return {
			serie: [
				entity.campaign.code,
				$translate.instant('direction.' + entity.direction + '.short'),
				entity.lane
			].join('-'),
			x: entity.milestone,
			y: entity[$ctrl.metric.name]
		};
	});

	$ctrl.$onChanges = function(changes) {
		if ($ctrl.entities && $ctrl.metric) {
			$ctrl.options.title.text = $ctrl.title;
			$ctrl.yAxis.scaleLabel.labelString = $ctrl.metric.unit;

			var points = toPoints($ctrl.entities);
			$ctrl.yAxis.ticks.min = $ctrl.minY || Math.floor(_.minBy('y', points).y);
			$ctrl.yAxis.ticks.max = $ctrl.maxY || Math.ceil(_.maxBy('y', points).y);
			$ctrl.xAxis.ticks.min = $ctrl.minX || Math.floor(_.minBy('x', points).x);
			$ctrl.xAxis.ticks.max = $ctrl.maxX || Math.ceil(_.maxBy('x', points).x);

			$ctrl.grouped = _.flow(_.sortBy('x'), _.groupBy('serie'))(points);

			$ctrl.series = [];
			$ctrl.data = [];

			for (var key in $ctrl.grouped) {
				var group = $ctrl.grouped[key];
				$ctrl.series.push(key);
				$ctrl.data.push(group);
			}
		}
	};

	$scope.$on('chart-create', function(event, chart) {
		$ctrl.exportLink = chart.toBase64Image();
	});
}

app.component('wfLineChart', component);
