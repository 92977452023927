import app from '@/app';
import templateUrl from './campaign-search.html';

/* @ngInject */
function Controller($controller, Campaign, Principal) {

  const $ctrl = this;
  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractEntitySearchController', {
		$ctrl: this,
		resource: Campaign,
		type: 'campaign'
	});

    $ctrl.exportLinkModelDB = '/api/campaigns/results/bd_monitoracao/excel/';

}

const component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<',
		allOrganizations: '<'
	}
};

app.component('campaignSearch', component);
