import React from 'react';
import axios from 'axios';
import AbstractChart from './../../ui/AbstractChart';
import { MetricOverviewData } from '../../pavrunner-api';

interface IPlanOverviewProps {
	planId: number,
	overviewId: number
}

interface IPlanOverviewState {
	data: Array<MetricOverviewData>
}

const metrics = {
	KPI_IGG: 'IGG',
	KPI_QI: 'QI',
	KPI_TR: 'TR',
	KPI_COST: 'Custo',
	KPI_INTERVENTION_AREA: 'Área de Obra'
}

export default class PlanOverview extends React.Component<IPlanOverviewProps, IPlanOverviewState> {
	componentDidMount() {
		axios
			.get(`/api/plans/${this.props.planId}/overviews/${this.props.overviewId}`)
			.then(response => this.setState({ data: response.data }))
	}
	render() {
		return (
			<div>
				{this.state && this.state.data && this.state.data.map((it, index) => (
					<div key={index}>
						<h5>{metrics[it.metric]}</h5>
						{ it.type == 'RANKED' ?
							(
								<AbstractChart height={200} width={600} options={
									{
										type: 'bar',
										data: {
											labels: it.items.map(it => it.year.toString()),
											datasets: [{
												type: 'line',
												label: 'Média ',
												borderWidth: 2,
												backgroundColor: 'rgb(255, 0, 0)',
												borderColor: 'rgb(255, 0, 0)',
												fill: false,
												lineTension: 0,
												yAxisID: 'y-axis-1',
												data: it.items.map(it => it.average)
											}, {
												type: 'bar',
												label: 'Ruim',
												yAxisID: 'y-axis-2',
												backgroundColor: 'rgb(80, 0, 0)',
												data: it.items.map(it => it.ranked.badArea),
											}, {
												type: 'bar',
												label: 'Limítrofe',
												yAxisID: 'y-axis-2',
												backgroundColor: 'rgb(0, 80, 80)',
												data: it.items.map(it => it.ranked.borderlineArea),
											}, {
												type: 'bar',
												label: 'Bom',
												yAxisID: 'y-axis-2',
												backgroundColor: 'rgb(0, 80, 0)',
												data: it.items.map(it => it.ranked.goodArea)
											}, {
												type: 'bar',
												label: 'Sem medição',
												yAxisID: 'y-axis-2',
												data: it.items.map(it => it.ranked.goodArea)
											}]
										},
										options: {
											responsive: true,
											scales: {
												xAxes: [{
													stacked: true
												}],
												yAxes: [{
													id: 'y-axis-1',
													position: 'left',
													scaleLabel: {
														labelString: metrics[it.metric] + ' Média',
														display: true
													}
												},
												{
													id: 'y-axis-2',
													stacked: true,
													position: 'right',
													scaleLabel: {
														labelString: 'Área',
														display: true
													}
												}]
											}
										}
									}
								}/>
							): it.type == 'SUM' ? (
								<AbstractChart height={200} width={600} options={
									{
										type: 'line',
										data: {
											labels: it.items.map(it => it.year.toString()),
											datasets: [{
												borderWidth: 2,
												backgroundColor: 'rgb(0, 0, 255)',
												borderColor: 'rgb(0, 0, 255)',
												fill: false,
												lineTension: 0,
												yAxisID: 'y-axis-1',
												data: it.items.map(it => it.sum)
											}]
										},
										options: {
											responsive: true,
											scales: {
												yAxes: [{
													id: 'y-axis-1',
													position: 'left',
													scaleLabel: {
														labelString: metrics[it.metric] + ' Soma',
														display: true
													}
												}]
											}
										}
									}
								}/>
							) : null
						}
					</div>
				))}
			</div>
		)
	}
}
