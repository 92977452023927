import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	var states = [
		stateHelperProvider.search({
			name: 'cost-table',
			component: 'costTableSearch',
			parent: 'entity',
			data: {
				viewAuthorities: ['ENTITIES_READ', 'COSTTABLE_READ'],
				editAuthorities: ['ENTITIES_WRITE', 'COSTTABLE_WRITE']
			}
		}),
		{
			name: 'cost-table.detail',
			url: '/{id}',
			component: 'costTableDetail',
			resolve: {
				/* @ngInject */
				entity: (CostTable, $transition$, entityInjector) =>
					entityInjector(CostTable, $transition$.params().id, { items: [] })
			}
		},
		{
			name: 'cost-table.detail.item',
			url: '/items/{iid}',
			component: 'costTableItemDetail'
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
