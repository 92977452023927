import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, PAGE_SIZES) {
	var states = [
		{
			name: 'measurement',
			parent: 'measurements',
			url:
				'/measurement?organization&road&headMilestone&tailMilestone&lanes&direction&campaigns&output&filters&roadtype&measurement&metric',
			data: {
				viewAuthorities: ['MEASUREMENTS_READ', 'MEASUREMENT_READ'],
				editAuthorities: ['MEASUREMENTS_WRITE', 'ANALYSIS_WRITE', 'MEASUREMENT_WRITE']
			},
			component: 'measurementSearch',
			params: {
				organization: {
					type: 'int',
					value: null,
					squash: true,
					dynamic: true
				},
				road: {
					type: 'int',
					value: null,
					squash: true,
					dynamic: true
				},
				headMilestone: {
					value: null,
					squash: true,
					dynamic: true
				},
				tailMilestone: {
					value: null,
					squash: true,
					dynamic: true
				},
				direction: {
					value: null,
					squash: true,
					dynamic: true
				},
				lanes: {
					array: true,
					value: [],
					squash: true,
					dynamic: true
				},
				campaigns: {
					array: true,
					type: 'int',
					squash: true,
					dynamic: true
				},
				output: {
					value: 'table',
					squash: true,
					dynamic: true
				},
				roadtype: {
					value: null,
					squash: true,
					dynamic: true
				},
        filters: {
					value: null
				},
        measurement: {
					squash: true,
					dynamic: true
				},
        metric: {
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allResources: (
					Fwd,
					Hs,
					Vrd,
					Ifi,
					Iri,
					Urci,
					Noise,
					Sat,
					Tr,
					Igg,
					Probing,
					Structure,
					Weighing,
					Grip,
					Test,
					UnifilarWorks,
					Works,
					Icp,
					GeoData
				) => ({
					FWD: Fwd,
					HS: Hs,
					VRD: Vrd,
					IFI: Ifi,
					IRI: Iri,
					URCI: Urci,
					NOISE: Noise,
					SAT: Sat,
					TR: Tr,
					IGG: Igg,
					PROBING: Probing,
					STRUCTURE: Structure,
					WEIGHING: Weighing,
					GRIP: Grip,
					TEST: Test,
					UNIFILARWORKS: UnifilarWorks,
					WORKS: Works,
					ICP: Icp,
					GEODATA: GeoData,

				}),
				/* @ngInject */
				allOrganizations: Organization => Organization.query({ sort: 'name,asc' }).$promise,
				/* @ngInject */
				allRoads: Road => Road.query({ sort: 'name,asc', empty: false }).$promise,
				/* @ngInject */
				allCampaigns: Campaign =>
					Campaign.query({ sort: 'name,desc', empty: false }).$promise,
				/* @ngInject */
				allMeasurements: Metadata => Metadata.getMeasurements({ calculated: false }),
				/* @ngInject */
				allMetrics: Metadata => Metadata.getMetrics(),
				/* @ngInject */
				allMetricLimits: MetricLimit => MetricLimit.query().$promise
			}
		},
		{
			name: 'measurement.table',
			url: '/table?page&size&sort',
			component: 'measurementTable',
			params: {
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				size: {
					value: PAGE_SIZES[0],
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'campaign,asc',
					squash: true,
					dynamic: true
				}
			}
		},
		{
			name: 'measurement.line-chart',
			url: '/line-chart',
			component: 'measurementLineChart'
		},
		{
			name: 'measurement.pie-chart',
			url: '/pie-chart',
			component: 'measurementPieChart'
		},
		{
			name: 'measurement.map',
			url: '/map',
			component: 'measurementMap'
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
