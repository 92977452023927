import app from '@/app';
import templateUrl from './organization-detail.html';

/* @ngInject */
function Controller($controller, Organization, User, Principal) {
	var $ctrl = this;
	$controller('AbstractEntityDetailController', {
		$ctrl: $ctrl,
		resource: Organization,
		type: null
	});

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})


	$ctrl.addChild('Road', 'roads', 'organizationForm');

	$ctrl.queryUsers = query => {
		$ctrl.allUsers = [];
		if (query) {
			return User.all({ query: query }, data => ($ctrl.allUsers = data)).$promise;
		}
	};
}

const component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<',
		entity: '<'
	}
};

app.component('organizationDetail', component);
