import app from '@/app';
import templateUrl from './metric-limit-search.html';

/* @ngInject */
function Controller($controller, $state, DialogService, MetricLimit, Principal) {
	var $ctrl = this;
	var resource = MetricLimit;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	})

	$controller('AbstractPageSortController', { $ctrl: $ctrl });

	var transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		transition(params);
		$ctrl.organization = params.organization;
		$ctrl.measurement = params.measurement;
		$ctrl.metric = params.metric;
		MetricLimit.query(
			{
				organization: $ctrl.organization,
				measurement: $ctrl.measurement,
				metric: $ctrl.metric,
				sort: $ctrl.sort,
				page: $ctrl.page,
				size: $ctrl.size
			},
			data => {
				$ctrl.entities = data.content;
				$ctrl.count = data.totalElements;
			}
		);
	};

	$ctrl.remove = id => resource.remove({ id: id }, () => $state.reload());

	$ctrl.onQueryChange = () => {
		$state.go(
			$state.current,
			{
				page: 0,
				organization: $ctrl.organization,
				measurement: $ctrl.measurement,
				metric: $ctrl.metric
			},
			{
				reload: true
			}
		);
	};

	$ctrl.getMetric = code => $ctrl.allMetrics.find(it => it.code == code);

	$ctrl.getMeasurement = code => $ctrl.allMeasurements.find(it => it.code == code);

	$ctrl.getMeasurementKey = code =>
		'name.' + $ctrl.getMeasurement($ctrl.getMetric(code).measurement).name;

	$ctrl.getMetricKey = code => 'measurement.' + $ctrl.getMetric(code).full;
}

const component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<',
		allOrganizations: '<',
		allMeasurements: '<',
		allMetrics: '<'
	}
};

app.component('metricLimitSearch', component);
