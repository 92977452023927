import app from '@/app';

app.factory('Account', Account);

/* @ngInject */
function Account($resource) {
	var service = $resource(
		'api/account',
		{},
		{
			get: {
				method: 'GET',
				params: {},
				isArray: false,
				interceptor: {
					response: response => response
				}
			}
		}
	);

	return service;
}
