import app from '@/app';
import angular from 'angular';
import templateUrl from './decision-tree-detail.html';

/* @ngInject */
function DecisionTreeDetailController(
	$controller,
	$scope,
	$timeout,
	DecisionTree,
	Principal,
	DecisionTreeProject,
	$http
) {
	const $ctrl = this;

	$ctrl.currentAccount = null;

	Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: DecisionTree,
		type: 'decisionTree'
	});

	$ctrl.dirtyItems = function() {
		$timeout(() => $ctrl.decisionTreeForm.items.$setDirty());
	};

	$ctrl.touchNode = function(action, key, value) {
		let item =
			value &&
			value.type &&
			!(value.type === 'LEAF' && value.solutions.length == 0 && value.subtrees.length == 0)
				? value
				: null;
		let node;
		for (var index = 0; index < key.length; ++index) {
			var last = index + 1 == key.length;
			var code = key.charAt(index);
			switch (code) {
				case 'R':
					if (last && action === 'set') {
						$ctrl.entity.root = item;
					}
					node = $ctrl.entity.root;
					break;
				case 'P':
					if (last && action === 'set') {
						node.positive = item;
					}
					node = node.positive;
					break;
				case 'N':
					if (last && action === 'set') {
						node.negative = item;
					}
					node = node.negative;
					break;
			}
		}
		return angular.extend({ type: 'LEAF', key: key, solutions: [], subtrees: [] }, node);
	};

	$ctrl.loadNode = function(key) {
		return $ctrl.touchNode('get', key);
	};

	$ctrl.saveNode = function(key, node) {
		$ctrl.touchNode('set', key, node);
		$ctrl.dirtyItems();
	};

	$ctrl.projectType = 'SELECT';

	var projectDefault = { id: null, name: null, status: 'SELECT' };

	$ctrl.onProjectChange = () => {
		switch ($ctrl.projectType) {
			case 'NEW':
				$ctrl.entity.decisionTreeProject.name = null;
				$ctrl.entity.decisionTreeProject.id = null;
				break;
			case 'SELECT':
				$ctrl.entity.decisionTreeProject.name = null;
				$ctrl.entity.decisionTreeProject.id = null;
				break;
			case 'OLD':
				$ctrl.entity.decisionTreeProject = null;
				break;
			default:
		}
	};

	this.$onInit = function() {
		$http
			.get('api/decisionTreeProjects', { params: { onlyUsed: false } })
			.then(function(response) {
				$ctrl.allProjects = response;
			});

		if ($ctrl.entity.decisionTreeProject == null) {
			$ctrl.entity.decisionTreeProject = projectDefault;
		}
	};
}

const component = {
	templateUrl,
	controller: DecisionTreeDetailController,
	require: {
		parent: '^decisionTreeSearch'
	},
	bindings: {
		$transition$: '<',
		allSolutions: '<',
		entity: '<'
	}
};

app.component('decisionTreeDetail', component);
