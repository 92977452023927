import app from '@/app';

var component = {
	templateUrl: '/app/components/search-bar/search-bar.html',
	bindings: {
		query: '<',
		onClick: '&'
	}
};

app.component('wfSearchBar', component);
