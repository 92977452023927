import app from '@/app';
import templateUrl from './campaign-detail.html';

/* @ngInject */
function Controller($controller, Campaign, Principal) {

  const $ctrl = this;
  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractEntityDetailController', {
		$ctrl: this,
		resource: Campaign,
		type: 'campaign'
	});
}

const component = {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^campaignSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<'
	}
};

app.component('campaignDetail', component);
