import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	const states = [
		stateHelperProvider.search({
			name: 'road',
			parent: 'entity',
			data: {
				viewAuthorities: ['ENTITIES_READ', 'ROAD_READ'],
				editAuthorities: ['ENTITIES_WRITE', 'ROAD_WRITE']
			},
			resolve: {
				/* @ngInject */
				allOrganizations: Organization => Organization.query({ sort: 'name,asc' }).$promise
			}
		}),
		{
			name: 'road.detail',
			url: '/{id}',
			component: 'roadDetail',
			resolve: {
				/* @ngInject */
				entity: (Road, $transition$) =>
					Road.inject($transition$.params().id, { segments: [], accessories: [] })
			}
		},
		{
			name: 'road.detail.segment',
			url: '/segments/{iid}',
			component: 'roadSegmentDetail'
		},
		{
			name: 'road.detail.accessory',
			url: '/accessories/{iid}',
			component: 'roadAccessoryDetail'
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);