import app from '@/app';
import templateUrl from './duplicate-plans-for-project-button.html';

/* @ngInject */
function DuplicatePlansForProjectButtonController(DialogService, $http) {
	const $ctrl = this;
	$ctrl.disableButton = true;
	$ctrl.loading = false;

	$ctrl.confirm = (message, project) => {
		if (!$ctrl.readonly && project && !$ctrl.loading) {
			DialogService.confirmDuplicate(message).then(function(result) { // Alterado para DialogService.confirmMessage e aguardando a promessa
				if (result === true) { // Verificar se o usuário clicou em "Sim"
					const projectResult = JSON.parse(project);

					$ctrl.loading = true; // Definir o estado de carregamento como verdadeiro

					$http
						.post('api/plans/duplicate/plansForProject', projectResult)
						.then(function(response) {
							const plansSize = response.data.size;
							const nameProject = projectResult.name;
							DialogService.alertMessage(
								plansSize +
									' planejamentos do projeto "' +
									nameProject +
									'" foram duplicados com sucesso!'
							);
						})
						.finally(function() {
							$ctrl.loading = false; // Limpar o estado de carregamento, independentemente do resultado
						});
					$ctrl.disableButton = false;
				}
			});
		}
	};
}

app.component('prDuplicatePlansForProjectButton', {
	templateUrl,
	controller: DuplicatePlansForProjectButtonController,
	bindings: {
		type: '@',
		named: '<',
		count: '<',
		state: '@',
		params: '<',
		readonly: '<',
		onClick: '&',
		message: '@',
		project: '@'
	}
});
