import app from '@/app';

var component = {
	templateUrl: '/app/components/dialog/confirm-dialog.html',
	controller: Controller,
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	}
};

function Controller() {
	var $ctrl = this;
  $ctrl.activeDelete = true;

	this.$onInit = function() {
		$ctrl.title = $ctrl.resolve.bindings.title;
		$ctrl.question = $ctrl.resolve.bindings.question;
		$ctrl.solution = $ctrl.resolve.bindings.solution;
		$ctrl.params = $ctrl.resolve.bindings.params;
		$ctrl.purpose = $ctrl.resolve.bindings.purpose;
		$ctrl.icon = $ctrl.resolve.bindings.icon;
		$ctrl.action = $ctrl.resolve.bindings.action;
    $ctrl.deleteCascade = $ctrl.action == 'action.delete' && ($ctrl.params.type == 'road' || $ctrl.params.type == 'campaign');
    if($ctrl.deleteCascade){
      $ctrl.activeDelete = false;
    };
	};

	this.$onChange = function(change) {
		if (change.resolve) {
			$ctrl.title = $ctrl.resolve.bindings.title;
			$ctrl.question = $ctrl.resolve.bindings.question;
			$ctrl.solution = $ctrl.resolve.bindings.solution;
			$ctrl.title = $ctrl.resolve.bindings.title;
			$ctrl.title = $ctrl.resolve.bindings.title;
		}
	};

  $ctrl.activeButtonDelete = function(){
    $ctrl.activeDelete = true;
  }

}

app.component('confirmDialog', component);
