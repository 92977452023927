import app from '@/app';
import templateUrl from './auditLog-search.html';

/* @ngInject */
function AuditLogSearchController($controller, $state, DialogService, AuditLog, $http, Principal) {
	var $ctrl = this;
	var resource = AuditLog;

	$ctrl.currentAccount = null;

	Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractPageSortController', { $ctrl: $ctrl });

	var transition = $ctrl.transition;

	$ctrl.transition = params => {
		transition(params);
		resource.query(
			{
				userName: params.userName,
				module: params.module,
				operation: params.operation,
				startDate: params.startDate,
				endDate: params.endDate,
				sort: params.sort,
				page: $ctrl.page,
				size: $ctrl.size
			},
			data => {
				$ctrl.entities = data.content;
				$ctrl.count = data.totalElements;
			}
		);
	};


	$ctrl.onQueryChange = () => {
		var userName = $ctrl.userName ? $ctrl.userName : null;
		var module = $ctrl.module ? $ctrl.module : null;
		var operation = $ctrl.operation ? $ctrl.operation : null;
		var startDate = $ctrl.startDate ? $ctrl.startDate : null;
		var endDate = $ctrl.endDate ? $ctrl.endDate : null;

		resource.query(
			{
				userName: userName,
				module: module,
				operation: operation,
				startDate: startDate,
				endDate: endDate,
				sort: $ctrl.sort,
				page: 0,
				size: $ctrl.size
			},
			data => {
				$ctrl.entities = data.content;
				$ctrl.count = data.totalElements;
			}
		);
	};
}

app.component('auditLogSearch', {
	templateUrl,
	controller: AuditLogSearchController,
	bindings: {
		$transition$: '<'
	}
});
