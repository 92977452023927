import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	var states = [
		{
			name: 'metricLimit',
			parent: 'entity',
			url: '/metrics/limits?organization&measurement&metric&page&sort',
			component: 'metricLimitSearch',
			data: {
				viewAuthorities: ['ENTITIES_READ', 'METRICLIMIT_READ'],
				editAuthorities: ['ENTITIES_WRITE', 'METRICLIMIT_WRITE']
			},
			params: {
				organization: {
					type: 'int',
					value: null,
					squash: true,
					dynamic: true
				},
				measurement: {
					value: null,
					squash: true,
					dynamic: true
				},
				metric: {
					value: null,
					squash: true,
					dynamic: true
				},
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'metric,asc',
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allOrganizations: Organization => Organization.query({ sort: 'name,asc' }).$promise,
				/* @ngInject */
				allMeasurements: Metadata => Metadata.getMeasurements({ calculated: false }),
				/* @ngInject */
				allMetrics: Metadata => Metadata.getMetrics({ view: 'LIMIT' })
			}
		},
		{
			name: 'metricLimit.detail',
			url: '/{id}',
			component: 'metricLimitDetail',
			resolve: {
				/* @ngInject */
				entity: (MetricLimit, $transition$, entityInjector) =>
					entityInjector(MetricLimit, $transition$.params().id)
			}
		}
	];
	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
