import app from '@/app';
import templateUrl from './constant-set-search.html';

/* @ngInject */
function Controller($controller, ConstantSet, Principal) {

  const $ctrl = this;
  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	})

	$controller('AbstractEntitySearchController', {
		$ctrl: this,
		resource: ConstantSet,
		type: 'constantSet'
	});
}

const component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<'
	}
};

app.component('constantSetSearch', component);
