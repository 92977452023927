import app from '@/app';
import templateUrl from './validation-messages.html';

const component = {
	templateUrl,
	bindings: {
		for: '<',
		maxlength: '@',
		minlength: '@',
		pattern: '@',
		min: '@',
		max: '@',
		step: '@'
	}
};

app.component('wfValidationMessages', component);
