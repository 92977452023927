import app from '@/app';

/* @ngInject */
function httpConfig(
	$urlRouterProvider,
	$httpProvider,
	httpRequestInterceptorCacheBusterProvider,
	$urlMatcherFactoryProvider
) {
	httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/], true);

	$urlRouterProvider.otherwise('/');
	$httpProvider.interceptors.push('errorHandlerInterceptor');
	$httpProvider.interceptors.push('authExpiredInterceptor');

	$urlMatcherFactoryProvider.type('boolean', {
		name: 'boolean',
		decode: val => val === true || val === 'true',
		encode: val => (val ? 1 : 0),
		equals: function(a, b) {
			return this.is(a) && a === b;
		},
		is: val => [true, false, 0, 1].indexOf(val) >= 0,
		pattern: /bool|true|0|1/
	});
}

app.config(httpConfig);
