import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider) {
	$stateProvider.state('measurements', {
		abstract: true,
		parent: 'app'
	});
}
app.config(stateConfig);
