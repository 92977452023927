import app from '@/app';
import { GrowthRateResourceClient } from '../../pavrunner-api';
import templateUrl from './growth-rate-search.html';

/* @ngInject */
function Controller($controller, GrowthRate, Principal) {
	
  var $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});
  
  $controller('AbstractEntitySearchController', {
		$ctrl: this,
		resource: GrowthRate,
		type: 'growthRate'
	});



}

const component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<'
	}
};

app.component('growthRateSearch', component);