import app from '@/app';
import templateUrl from './metric-limit-detail.html';

/* @ngInject */
function Controller($controller, MetricLimit, Principal) {
	var $ctrl = this;

  $ctrl.currentAccount = null;

  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	})


	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: MetricLimit,
		type: 'metricLimit'
	});

	$ctrl.$onInit = function() {
		if ($ctrl.entity.metric) {
			$ctrl.measurement = _.find({ code: $ctrl.entity.metric })(
				$ctrl.parent.allMetrics
			).measurement;
		}
	};

	$ctrl.filterByMeasurement = function(value) {
		return $ctrl.measurement && $ctrl.measurement == value.measurement;
	};


	$ctrl.getMaxLimit = (metric) => {
		metric = $ctrl.parent.allMetrics.filter(m => m.code == metric)[0];
		return metric && metric.unit == 'PERCENT';
	};


}

const component = {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^metricLimitSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<'
	}
};

app.component('metricLimitDetail', component);
