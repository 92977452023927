import app from '@/app';

/* @ngInject */
function Service($uibModal, AlertService) {
	this.confirm = function confirm(
		title,
		question,
		solution,
		params,
		purpose,
		icon,
		action,
		callback
	) {
		return $uibModal
			.open({
				component: 'confirmDialog',
				resolve: {
					bindings: function() {
						return {
							title: title,
							question: question,
							params: params,
							purpose: purpose,
							icon: icon,
							action: action
						};
					}
				}
			})
			.result.then(function() {
				var promise = callback();
				promise.then(function() {
					AlertService.success(solution, params);
				});
			});
	};

	this.confirmDelete = function(params, callback) {
		var arity = params.count && params.count > 1 ? 'all' : 'one';
		this.confirm(
			'entity.delete.title',
			'entity.delete.' + arity + '.question',
			'entity.delete.' + arity + '.solution',
			params,
			'btn-danger',
			'fa-trash',
			'action.delete',
			callback
		);
	};

	this.confirmDuplicate = function(message) {
		return $uibModal
			.open({
				component: 'confirmDialog',
				resolve: {
					bindings: function() {
						return {
							title: '',
							question: message,
							params: null,
							purpose: 'btn-warning',
							icon: 'fa-copy',
							action: 'duplicatePlansForProject.confirm'
						};
					}
				}
			})
			.result.then(
				function() {
					return true;
				},
				function() {
					return false;
				}
			);
	};

	this.confirmCheckUpdatedDependencies= function(message) {
		return $uibModal
			.open({
				component: 'confirmDialog',
				resolve: {
					bindings: function() {
						return {
							title: '',
							question: message,
							params: null,
							purpose: 'btn-warning',
							icon: 'fa-refresh',
							action: 'updatedDependencies.confirm'
						};
					}
				}
			})
			.result.then(
				function() {
					return true;
				},
				function() {
					return false;
				}
			);
	};

	this.alertMessage = function(message) {
		return $uibModal.open({
			component: 'warnDuplicateDialog',
			size: 'lg',
			resolve: {
				bindings: function() {
					return {
						message: message
					};
				}
			}
		});
	};
}

app.service('DialogService', Service);
