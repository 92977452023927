import app from '@/app';
import templateUrl from './criteria-set-search.html';

/* @ngInject */
function Controller($controller, CriteriaSet, Principal) {
	
  var $ctrl = this;

  $ctrl.currentAccount = null
  
  $controller('AbstractEntitySearchController', {
		$ctrl: this,
		resource: CriteriaSet,
		type: 'criteriaSet'
	});

;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	})

}

const component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<'
	}
};

app.component('criteriaSetSearch', component);
