import app from '@/app';
import templateUrl from './table-holder.html';

function controller() {
	var $ctrl = this;

	$ctrl.toggle = function(field) {
		const order = $ctrl.field == field && $ctrl.order == 'asc' ? 'desc' : 'asc';
		$ctrl.onSortChange(field + ',' + order);
	};

	$ctrl.onSortChange = function(sort) {
		$ctrl.onChange({ page: $ctrl.page, size: $ctrl.size, sort });
	};

	$ctrl.onPageChange = function(page, size) {
		$ctrl.onChange({ page, size, sort: $ctrl.sort });
	};

	$ctrl.$onChanges = function(changes) {
		if (changes.sort && $ctrl.sort) {
			var parts = $ctrl.sort.split(',');
			$ctrl.field = parts[0];
			$ctrl.order = parts[1];
		}
	};
}

const component = {
	templateUrl,
	controller,
	transclude: {
		northWestTools: '?northWestTools',
		northEastTools: '?northEastTools',
		southWestTools: '?southWestTools',
		southEastTools: '?southEastTools'
	},
	bindings: {
		page: '<',
		size: '<',
		sort: '<',
		count: '<',
		onChange: '&'
	}
};

app.component('wfTableHolder', component);
