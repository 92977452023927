import app from '@/app';
import templateUrl from './cost-table-search.html';

/* @ngInject */
function Controller($controller, CostTable, Principal) {

  var $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	})

	$controller('AbstractEntitySearchController', {
		$ctrl: this,
		resource: CostTable,
		type: 'costTable'
	});
}

const component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<'
	}
};

app.component('costTableSearch', component);
