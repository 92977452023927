import app from '@/app';
import templateUrl from './solution-historic.html';

/* @ngInject */
function Controller($controller, SolutionHistoric, Principal) {
	var $ctrl = this;

  $ctrl.currentAccount = null;

  Principal.identity().then(account => {
    $ctrl.currentAccount = account;
    })

    $controller('AbstractController', { $ctrl });

    this.$onInit = function() {
      SolutionHistoric.query(
        {
          idplan: $ctrl.idplan,
          version: $ctrl.version,
        },
        data => {
          $ctrl.entities = data.content;
          $ctrl.count = data.totalElements;
        }
      );
    };
    


}

app.component('solutionHistoric', {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^planHistoric'
	},
	bindings: {
		$transition$: '<',
		idplan: '<',
    version: '<'
	}
});

