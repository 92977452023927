import app from '@/app';

var component = {
	templateUrl: '/app/components/alert/alert.html',
	controller: AlertController
};

/* @ngInject */
function AlertController($scope, AlertService) {
	var vm = this;

	vm.alerts = AlertService.alerts;
	vm.timeout = AlertService.timeout;
	vm.close = function(index) {
		AlertService.close(index);
	};

	$scope.$on('$destroy', function() {
		vm.alerts = [];
	});
}
app.component('jhAlert', component);
