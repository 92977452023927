import app from '@/app';

/* @ngInject */
function Service($http) {
	function get(url, params) {
		return $http.get(url, { params: params }).then(function(response) {
			return response.data;
		});
	}
}

app.service('Metadata', Service);
