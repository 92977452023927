import app from '@/app';
import templateUrl from './input.html';

/* @ngInject */
function InputController($scope) {
	const $ctrl = this;

	$ctrl.$onInit = function() {
		$ctrl.type = $ctrl.type || 'text';
		$ctrl.name = $ctrl.name || $ctrl.label || 'input_' + $ctrl.type + '_' + $scope.$id;
		if ($ctrl.type == 'number') {
			$ctrl.step = $ctrl.step || 'any';
		}
	};
}

app.component('wfInput', {
	templateUrl,
	controller: InputController,
	require: {
		form: '^form'
	},
	bindings: {
		type: '@',
		name: '@',
		label: '@',
		model: '=',
		readonly: '<',
		disabled: '<',
		required: '<',
		pattern: '@',
		minlength: '<',
		maxlength: '<',
		max: '<',
		min: '<',
		step: '<',
		percent: '<',
		tooltip: '@'
	}
});
