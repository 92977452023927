import angular, { forEach } from 'angular';
import app from '@/app';
import templateUrl from './measurement-line-chart.html';

/* @ngInject */
function MeasurementLineChartController($controller, $scope, $translate) {
  const $ctrl = this;
  $controller('AbstractController', { $ctrl });

  $ctrl.transition = params => {

    $ctrl.road = $ctrl.parent.allRoads.content.find(it => it.id == params.road);

    $ctrl.charts = [];
    angular.forEach(angular.copy(JSON.parse(params.filters)), function (item, key) {
      $ctrl.charts.push({
        "measurement": {
          "name": item.measurement.name,
          "code": item.measurement.code,
          "type": item.measurement.type
        },
        "metric": item.metric,
        "options":
        {
          backgroundColor: 'rgb(240, 240, 240)',
          elements: {
            line: {
              fill: false,
              tension: 0
            }
          },
          title: {
            display: true,
            text: ''
          },
          legend: {
            display: true
          },
          scales: {
            xAxes: [
              {
                type: 'linear',
                position: 'bottom',
                ticks: {
                  min: 0,
                  max: 0
                },
                scaleLabel: {
                  display: true,
                  labelString: $translate.instant('measurement.milestone.unit')
                }
              }
            ],
            yAxes: [
              {
                id: 'y-axis-1',
                type: 'linear',
                position: 'left',
                ticks: {
                  min: 0,
                  max: 0
                },
                scaleLabel: {
                  display: true,
                  labelString: ''
                }
              }
            ],
          },
          annotation: {
            annotations: [{
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-1',
              value: 0,
              borderColor: 'rgba(0, 0, 255, 0.5)',
              borderWidth: 2
            }, {
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-1',
              value: 0,
              borderColor: 'rgba(255, 0, 0, 0.5)',
              borderWidth: 2
            }]
          },
          animation: false
        },
        "yAxis": {
          id: 'y-axis-1',
          type: 'linear',
          position: 'left',
          ticks: {
            min: 0,
            max: 0
          },
          scaleLabel: {
            display: true,
            labelString: ''
          }
        },
        "xAxis": {
          type: 'linear',
          position: 'bottom',
          ticks: {
            min: 0,
            max: 0
          },
          scaleLabel: {
            display: true,
            labelString: $translate.instant('measurement.milestone.unit')
          }
        },
        "entities": {},
        "metricLimit": {},
        "good": { ...$ctrl.good },
        "bad": { ...$ctrl.bad },
        "grouped": {},
        "override": { ...$ctrl.override }
      });
    })

    //for(item of $ctrl.charts){
    angular.forEach($ctrl.charts, function (item, key) {
      item.metricLimit = $ctrl.parent.allMetricLimits.content.find(
        it => it.organization.id == $ctrl.road.owner.id && it.metric == item.metric
      );

      item.metric = $ctrl.parent.allMetrics.find(it => it.code == item.metric);

      item.measurement_metric = 'measurement.' + item.measurement.name.toLowerCase() + '.' + item.metric.name;

      item.options.title.text = $ctrl.road.name;

      item.yAxis.scaleLabel.labelString = $translate.instant(`unit.${item.metric.unit}`);


      $scope.$on('chart-create', function (event, chart) {
        if('canvas-'+key == chart.canvas.id){
          item.exportLink = chart.toBase64Image();
        }
      });

      $ctrl.parent.allResources[item.measurement.code].query(angular.extend({}, params), data => {
        item.entities = data.content;
        $ctrl.refresh(item);
      });



    });

  };

  $ctrl.refresh = (item) => {
    item.series = [];
    item.data = [];
    if (item.entities && item.entities.length) {
      const points = item.entities.map(entity => ({
        serie:
          entity.campaign.name +
          '-' +
          $translate.instant('common.lane') +
          ' ' +
          entity.lane +
          ' (' +
          $translate.instant(`direction.${entity.direction}.short`) +
          ')',
        x: entity.milestone,
        y: entity[item.metric.name] * (item.metric.factor || 1.0)
      }));
      if (item.metricLimit) {
        item.options.scales.yAxes[0].ticks.min =
          item.metricLimit.minLimit || item.metricLimit.minLimit == 0
            ? item.metricLimit.minLimit
            : Math.floor(_.minBy('y', points).y);
        item.options.scales.yAxes[0].ticks.max = item.metricLimit.maxLimit
          ? item.metricLimit.maxLimit
          : Math.ceil(_.maxBy('y', points).y);
        item.options.annotation.annotations[0].value = item.metricLimit.goodLimit;
        item.options.annotation.annotations[1].value = item.metricLimit.badLimit;
      } else {
        item.options.scales.yAxes[0].ticks.min = Math.floor(_.minBy('y', points).y);
        item.options.scales.yAxes[0].ticks.max = Math.ceil(_.maxBy('y', points).y);
        item.options.annotation.annotations[0].value = 0;
        item.options.annotation.annotations[1].value = 0;
      }
      item.options.scales.xAxes[0].ticks.min = Math.floor(_.minBy('x', points).x);
      item.options.scales.xAxes[0].ticks.max = Math.ceil(_.maxBy('x', points).x);

      item.grouped = _.flow(_.sortBy('x'), _.groupBy('serie'))(points);

      for (var key in item.grouped) {
        var group = item.grouped[key];
        item.series.push(key);
        item.data.push(group);
      }
    }
  };

  $ctrl.xAxis = {
    type: 'linear',
    position: 'bottom',
    ticks: {
      min: 0,
      max: 0
    },
    scaleLabel: {
      display: true,
      labelString: $translate.instant('measurement.milestone.unit')
    }
  };

  $ctrl.yAxis = {
    id: 'y-axis-1',
    type: 'linear',
    position: 'left',
    ticks: {
      min: 0,
      max: 0
    },
    scaleLabel: {
      display: true,
      labelString: ''
    }
  };


  $ctrl.good = {
    type: 'line',
    mode: 'horizontal',
    scaleID: 'y-axis-1',
    value: 0,
    borderColor: 'rgba(0, 0, 255, 0.5)',
    borderWidth: 2
  };

  $ctrl.bad = {
    type: 'line',
    mode: 'horizontal',
    scaleID: 'y-axis-1',
    value: 0,
    borderColor: 'rgba(255, 0, 0, 0.5)',
    borderWidth: 2
  };

  $ctrl.options = {
    backgroundColor: 'rgb(240, 240, 240)',
    elements: {
      line: {
        fill: false,
        tension: 0
      }
    },
    title: {
      display: true,
      text: ''
    },
    legend: {
      display: true
    },
    scales: {
      xAxes: [{ ...$ctrl.xAxis }],
      yAxes: [{ ...$ctrl.yAxis }],
    },
    annotation: {
      annotations: [{ ...$ctrl.good }, { ...$ctrl.bad }]
    },
    animation: false
  };

  $ctrl.override = [{}];

  
}
const component = {
  templateUrl,
  controller: MeasurementLineChartController,
  require: {
    parent: '^measurementSearch'
  },
  bindings: {
    road: '<',
    $transition$: '<'
  }
};

app.component('measurementLineChart', component);
