import app from '@/app';
import templateUrl from './reinforcementCalculation-search.html';

/* @ngInject */
function ReinforcementCalculationSearchController(
	$controller,
	$state,
	DialogService,
	ReinforcementCalculation,
  Principal
) {
	var $ctrl = this;
	var resource = ReinforcementCalculation;
	$controller('AbstractPageSortController', { $ctrl: $ctrl });

	var transition = $ctrl.transition;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	$ctrl.transition = params => {
		transition(params);
		$ctrl.road = $ctrl.allRoads.content.find(it => it.id == params.road);
		$ctrl.campaigns = _.flow(
			_.keyBy('id'),
			_.at(params.campaigns || [])
		)($ctrl.allCampaigns.content);
		resource.query(
			{
				plantype: 'REINFORCEMENT',
				road: params.road,
				campaign: params.campaign,
				sort: params.sort,
				page: params.page,
				size: params.size
			},
			data => {
				$ctrl.entities = data.content;
				$ctrl.count = data.totalElements;
			}
		);
		$ctrl.roadtype = params.roadtype;
	};

	$ctrl.onRoadTypeChange = roadtype => {
		$ctrl.road = null;
		$ctrl.campaigns = [];
	};

	$ctrl.onOrganizationChange = organization => {
		$ctrl.road = null;
		$ctrl.campaigns = [];
	};

	$ctrl.byOrganization = value =>
		$ctrl.organization && value.organization.id == $ctrl.organization.id;

	$ctrl.byOwner = value => $ctrl.organization && value.owner.id == $ctrl.organization.id;

	$ctrl.onQueryChange = () => {
		$state.go(
			$state.current,
			{
				page: 0,
				road: $ctrl.road.id,
				campaigns: $ctrl.campaigns.map(it => it.id),
				plantype: 'REINFORCEMENT'
			},
			{
				reload: true
			}
		);
	};

	$ctrl.remove = (id, name) => resource.remove({ id: id }, () => $state.reload()).$promise;
}

app.component('reinforcementCalculationSearch', {
	templateUrl,
	controller: ReinforcementCalculationSearchController,
	bindings: {
		$transition$: '<',
		allOrganizations: '<',
		allRoads: '<',
		allCampaigns: '<',
		allDecisionTrees: '<',
		allCostTables: '<'
	}
});
