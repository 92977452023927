import app from '@/app';

/* @ngInject */
function GeoData($resource) {
	return $resource(
		'api/metadata/geodata/:id',
		{ id: '@id' },
		{
			query: { method: 'GET' },
			purge: { method: 'DELETE' }
		}
	);
}

app.factory('GeoData', GeoData);
