import app from '@/app';
import templateUrl from './ng-main.html';

var component = {
	templateUrl,
	controller: () => {}
};

app.component('prMain', component);

export default component;
