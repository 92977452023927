import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, PAGE_SIZES, SMALL_PAGE_SIZES) {
	const states = [
		{
			name: 'gapIdentification',
			parent: 'measurements',
			url: '/gapIdentification?page&sort&size&road&campaign&plantype',
			component: 'gapIdentificationSearch',
			data: {
				viewAuthorities: ['ANALYSIS_READ', 'GAPIDENTIFICATION_READ'],
				editAuthorities: ['ANALYSIS_WRITE', 'ANALYSIS_PARTIAL_WRITE', 'GAPIDENTIFICATION_WRITE']
			},
			params: {
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'name,asc',
					squash: true,
					dynamic: true
				},
				road: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				campaign: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				plantype: {
					type: 'int',
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allOrganizations: [
					'Organization',
					Organization => {
						return Organization.query({ sort: 'name,asc' }).$promise;
					}
				],
				/* @ngInject */
				allRoads: Road => Road.query({ sort: 'name,asc', empty: false }).$promise,
				/* @ngInject */
				allCampaigns: Campaign =>
					Campaign.query({ sort: 'name,asc', empty: false }).$promise,
          /* @ngInject */
        allCriteriaSets: CriteriaSet => CriteriaSet.query({ sort: 'name,asc' }).$promise

			}
		},
		{
			name: 'gapIdentification.detail',
			url: '/{id}',
			component: 'gapIdentificationDetail',
			resolve: {
				/* @ngInject */
				entity: (GapIdentification, $transition$, entityInjector) =>
					entityInjector(GapIdentification, $transition$.params().id, {})
			}
		},
		{
			name: 'gapIdentification.overview',
			url: '/{gapIdentificationId}/overviews/{overviewId}',
			component: 'gapIdentificationOverviewWrapper'
		},
    {
			name: 'gapIdentification.result',
			url:
				'/{id}/result?milestonePage&milestoneSize&yearPage&yearSize&direction&lanes&metric',
			component: 'gapIdentificationResult',
			params: {
				milestonePage: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				milestoneSize: {
					value: PAGE_SIZES[3],
					type: 'int',
					squash: true,
					dynamic: true
				},
				yearSize: {
					value: SMALL_PAGE_SIZES[1],
					type: 'int',
					squash: true,
					dynamic: true
				},
				yearPage: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				direction: {
					value: null,
					squash: true,
					dynamic: true
				},
				lanes: {
					array: true,
					value: [],
					type: 'int',
					squash: true,
					dynamic: true
				},
				metric: {
					value: null,
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				entity: (UnifilarParameters, $transition$, entityInjector) =>
					entityInjector(UnifilarParameters, $transition$.params().id, {})
			}
		},
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
