import app from '@/app';

/* @ngInject */
function Resource($resource) {
	return $resource(
		'api/plans/:id',
		{ id: '@id' },
		{
			create: { method: 'POST' },
			query: { method: 'GET' },
			update: { method: 'PUT' }
		}
	);
}

app.factory('UnifilarParameters', Resource);
