import app from '@/app';
import templateUrl from './unifilarParametersComments-search.html';

/* @ngInject */
function Controller($controller, UnifilarParameters) {
	const $ctrl = this;
	const resource = UnifilarParameters;
	$controller('AbstractPageSortController', { $ctrl: $ctrl });

	var transition = $ctrl.transition;

	$ctrl.transition = params => {
		transition(params);
		$ctrl.road = $ctrl.allRoads.content.find(it => it.id == params.road);
		$ctrl.campaigns = _.flow(
			_.keyBy('id'),
			_.at(params.campaigns || [])
		)($ctrl.allCampaigns.content);
		resource.query(
			{
				plantype: 'P21',
				road: params.road,
				campaign: params.campaign,
				sort: params.sort,
				page: params.page,
				size: params.size
			},
			data => {
				$ctrl.entities = data.content;
				$ctrl.count = data.totalElements;
			}
		);
		$ctrl.roadtype = params.roadtype;
	};
}

const component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<',
		allOrganizations: '<',
		allRoads: '<',
		allCampaigns: '<',
		allDecisionTrees: '<',
		allCriteriaSets: '<'
	}
};

app.component('unifilarParametersCommentsSearch', component);
