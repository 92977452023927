import app from '@/app';
import templateUrl from './iqdCalculation-result.html';

/* @ngInject */
function controller($controller, Principal) {
	var $ctrl = this;

  $ctrl.currentAccount = null;
    
  Principal.identity().then(account => {
    $ctrl.currentAccount = account;
    })

	$controller('AbstractController', { $ctrl: $ctrl });
}

const component = {
	templateUrl,
	controller,
	bindings: {
		$transition$: '<',
		allMetrics: '<',
		entity: '<'
	}
};

app.component('iqdCalculationResult', component);
