import app from '@/app';
import templateUrl from './asBuilt-import-dialog.html';

/* @ngInject */
function Controller($translate, Upload, Principal) {
	var $ctrl = this;

	$ctrl.$onInit = () => {
		$ctrl.uploading = $ctrl.resolve.files.length;
		$ctrl.resolve.files.forEach(file => {
			file.progress = 0;
		});

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

		$ctrl.resolve.files.forEach(file => {
			file.upload = Upload.upload({
				url: '/api/import/excel',
				data: { file: file }
			});

			file.upload.then(onSuccess, onError, onProgress);

			function onSuccess(response) {
				--$ctrl.uploading;
				file.progress = 100.0;
				file.result = 'success';
			}

			function onError(response) {
				--$ctrl.uploading;
				file.progress = 100.0;
				if (response.data.errors) {
					file.errors = response.data.errors;
				} else if (response.data.message) {
					file.errors = [$translate.instant(response.data.message)];
				} else {
					file.errors = [$translate.instant('message.unexpectedError')];
				}
				file.result = 'danger';
			}

			function onProgress(event) {
				file.progress = Math.min(100, parseInt((90.0 * event.loaded) / event.total));
			}
		});
	};
}

app.component('asBuiltImportDialog', {
	templateUrl,
	controller: Controller,
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	}
});
