import app from '@/app';
import templateUrl from './remove-button.html';

app.component('prRemoveButton', {
	templateUrl,
	bindings: {
		isDisabled: '<',
		onClick: '&',
		readonly: '<'
	}
});
