import app from '@/app';
import templateUrl from './gapIdentification-result.html';

/* @ngInject */
function controller(
	$controller,
	$filter,
	$http,
	$httpParamSerializer,
	$state,
	$translate,
	DialogService,
	wfPivotUtils,
	wfPager2Service,
	PAGE_SIZES,
	SMALL_PAGE_SIZES,
  Principal
) {
	var $ctrl = this;
	$controller('AbstractController', { $ctrl: $ctrl });

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})


	$ctrl.options = {
		formatter: wfPivotUtils.formatters.asis(),
		rows: [
			{
				name: 'headMilestone',
				translation: 'common.headMilestone',
				formatter: wfPivotUtils.formatters.number(3)
			},
			{
				name: 'tailMilestone',
				translation: 'common.tailMilestone',
				formatter: wfPivotUtils.formatters.number(3)
			}
		],
		cols: [
			{
				name: 'year',
				translation: 'common.year'
			},
			{
				name: 'direction',
				translation: 'common.direction',
				sorter: wfPivotUtils.sorters.sortAs(['DEC', 'INC']),
				formatter: direction => $translate.instant('direction.' + direction)
			},
			{
				name: 'lane',
				translation: 'common.lane',
				formatter: lane => {
					const val = Math.abs(lane);
					return val == 100 ? 'acost' : val;
				}
			}
		]
	};

	$ctrl.yearSizes = SMALL_PAGE_SIZES;

	$ctrl.segmentSizes = PAGE_SIZES;

	function solutionAggregator() {
		return {
			solutions: null,
			missings: null,
			push: function(record) {
				this.missings = record['missingMeasurements'];
				this.solutions = record['solutions'].map(it => it.name);
			},
			isMissing: function() {
				return this.missings && this.missings.length > 0;
			},
			isSolution: function() {
				return this.solutions && this.solutions.length > 0;
			},
			value: function() {
				return (this.isMissing()
					? this.missings
					: this.isSolution()
					? this.solutions
					: []
				).join('+');
			},
			style: function() {
				return this.isMissing() ? 'error' : null;
			}
		};
	}

	var transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		transition(params);
		$ctrl.milestonePage = params.milestonePage != null ? parseInt(params.milestonePage) : 0;
		$ctrl.milestoneSize =
			params.milestoneSize != null ? parseInt(params.milestoneSize) : PAGE_SIZES[3];
		$ctrl.milestoneCount =
			($ctrl.entity.tailMilestone - $ctrl.entity.headMilestone) / $ctrl.entity.segmentLength;
		const milestoneRange = wfPager2Service.createPage(
			$ctrl.milestonePage,
			$ctrl.milestoneSize,
			$ctrl.milestoneCount,
			$ctrl.entity.segmentLength,
			$ctrl.entity.headMilestone
		);

		$ctrl.headMilestone = milestoneRange.head;
		$ctrl.tailMilestone = milestoneRange.tail;

		$ctrl.yearPage = params.yearPage != null ? parseInt(params.yearPage) : 0;
		$ctrl.yearSize = params.yearSize != null ? parseInt(params.yearSize) : SMALL_PAGE_SIZES[0];
		$ctrl.yearCount = $ctrl.entity.processedYears;
		const yearRange = wfPager2Service.createPage(
			$ctrl.yearPage,
			$ctrl.yearSize,
			$ctrl.entity.processedYears,
			1,
			$ctrl.entity.headYear
		);
		$ctrl.headYear = yearRange.head;
		$ctrl.tailYear = yearRange.tail + 1;

		$ctrl.direction = params.direction;
		$ctrl.lanes = params.lanes;
		$ctrl.metric = params.metric;
		$ctrl.options.aggregator = solutionAggregator;
		$ctrl.road = $ctrl.entity.road.name;
		$ctrl.campaign = $ctrl.entity.campaign.name;

		const query = _.pick([
			'headMilestone',
			'tailMilestone',
			'headYear',
			'tailYear',
			'direction',
			'lanes',
			'metric',
			'road',
			'campaign'
		])($ctrl);
		$ctrl.exportLink =
			'/api/plans/' + $ctrl.entity.id + '/results/excel?' + $httpParamSerializer(query);
		$http
			.get('api/plans/' + $ctrl.entity.id + '/results', {
				params: query
			})
			.then(function(response) {
				$ctrl.entities = response.data.filter(item => item.missingMeasurements.length > 0);
			});
	};

	$ctrl.filterLane = function(lane) {
		return $ctrl.lanes || !_.includes(lane)($ctrl.lanes);
	};

	$ctrl.onQueryChange = function(query) {
		$state.go(
			$state.current,
			{
				milestonePage: $ctrl.milestonePage,
				milestoneSize: $ctrl.milestoneSize,
				yearPage: $ctrl.yearPage,
				yearSize: $ctrl.yearSize,
				direction: $ctrl.direction,
				lanes: $ctrl.lanes,
				metric: $ctrl.metric
			},
			{
				reload: true
			}
		);
	};

	$ctrl.onMilestonePageChange = function(page, size) {
		$state.go(
			$state.current,
			{
				milestonePage: page,
				milestoneSize: size
			},
			{
				reload: true
			}
		);
	};

	$ctrl.onYearPageChange = function(page, size) {
		$state.go(
			$state.current,
			{
				yearPage: page,
				yearSize: size
			},
			{
				reload: true
			}
		);
	};
}

const component = {
	templateUrl,
	controller,
	bindings: {
		$transition$: '<',
		allMetrics: '<',
		entity: '<'
	}
};

app.component('gapIdentificationResult', component);
