import React from 'react';

function AllowedValue(props) {
	if (props.layer === 'layer1Et') {
		if (props.aashtoCoefficient && props.aashtoExponent) {
			if (props.model === '1') {
				return Math.pow(
					(props.aashtoCoefficient * Math.pow(10, props.aashtoExponent)) /
						(1.092 * Math.pow(10, -6)),
					-1 / 3.512
				).toFixed(6);
			}

			if (props.model === '2') {
				return Math.pow(
					(props.aashtoCoefficient * Math.pow(10, props.aashtoCoefficient)) /
						(2.961 * Math.pow(10, -5)),
					-1 / 3.291
				).toFixed(6);
			}
		}
	}

	if (props.usaceCoefficient && props.usaceExponent) {
		if (props.layer === 'layer1D') {
			if (props.model === '1') {
				return Math.pow(
					10,
					3.01 -
						0.174 *
							Math.log10(props.usaceCoefficient * Math.pow(10, props.usaceExponent))
				).toFixed(1);
			}

			if (props.model === '2') {
				return Math.pow(
					10,
					3.148 -
						0.188 *
							Math.log10(props.usaceCoefficient * Math.pow(10, props.usaceExponent))
				).toFixed(1);
			}
		}

		if (props.layer === 'layer2' || props.layer === 'layer3') {
			if (props.model === '1') {
				return (
					(props.RT *
						(17.137 -
							Math.log10(
								props.usaceCoefficient * Math.pow(10, props.usaceExponent)
							))) /
					19.608
				).toFixed(3);
			}

			if (props.model === '2') {
				return (
					(props.RT / 100) *
					(125.63 -
						14.92 *
							Math.log10(props.usaceCoefficient * Math.pow(10, props.usaceExponent)))
				).toFixed(3);
			}

			if (props.model === '3') {
				return (
					(props.RT / 100) *
					(64.01 -
						0.822 *
							Math.log10(props.usaceCoefficient * Math.pow(10, props.usaceExponent)))
				).toFixed(3);
			}

			if (props.model === '4') {
				return (
					(props.RT / 100) *
					(94.76 -
						2.5 *
							Math.log10(props.usaceCoefficient * Math.pow(10, props.usaceExponent)))
				).toFixed(3);
			}

			if (props.model === '5') {
				return (
					(props.RT / 100) *
					(67.59 -
						1.03 *
							Math.log10(props.usaceCoefficient * Math.pow(10, props.usaceExponent)))
				).toFixed(3);
			}
		}

		if (props.layer === 'subgrade') {
			if (props.model === '1') {
				return Math.pow(
					(props.usaceCoefficient * Math.pow(10, props.usaceExponent)) /
						(6.069 * Math.pow(10, -10)),
					-1 / 4.762
				).toFixed(6);
			}

			if (props.model === '2') {
				return Math.pow(
					(props.usaceCoefficient * Math.pow(10, props.usaceExponent)) /
						(1.338 * Math.pow(10, -9)),
					-1 / 4.484
				).toFixed(6);
			}

			if (props.model === '3') {
				return Math.pow(
					(props.usaceCoefficient * Math.pow(10, props.usaceExponent)) /
						(1.05 * Math.pow(10, -7)),
					-1 / 4.0
				).toFixed(6);
			}
		}
	}

	return;
}

export default AllowedValue;
