import app from '@/app';
import angular from 'angular';
import templateUrl from './measurement-table.html';

/* @ngInject */
function Controller($controller, $httpParamSerializer, DialogService, Principal) {
	var $ctrl = this;
	$controller('AbstractPageSortController', { $ctrl });

	var transition = $ctrl.transition;
	$ctrl.transition = params => {
		transition(params);
		$ctrl.params = angular.extend({}, params);
		$ctrl.resource = $ctrl.parent.allResources[params.measurement];
		$ctrl.measurement = $ctrl.parent.allMeasurements.find(it => it.code == params.measurement);
		$ctrl.metrics = $ctrl.parent.allMetrics.filter(it => it.measurement == params.measurement && it.measurement != 'icp');
		$ctrl.resource.query(params, data => {
			$ctrl.entities = data.content;
			$ctrl.count = data.totalElements;
			$ctrl.exportLink =
				'/api/' + $ctrl.measurement.name + 's/excel?' + $httpParamSerializer($ctrl.params);
		});
	};

  $ctrl.currentAccount = null;

  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	$ctrl.remove = () =>
		$ctrl.resource.purge($ctrl.params, () => $ctrl.transition($ctrl.params)).$promise;
}

app.component('measurementTable', {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^measurementSearch'
	},
	bindings: {
		measurement: '<',
		$transition$: '<'
	}
});
