import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider) {
	$stateProvider.state({
		name: 'home',
		parent: 'app',
		url: '/',
		component: 'home'
	});
}

app.config(stateConfig);


