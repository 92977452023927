import app from '@/app';
import { nominalTypeHack } from 'prop-types';
import { AxiosSystemResourceClient } from '../../pavrunner-api';

function clearIdentify(obj){
    if (obj){
      obj.id = null;
    }
    
}
function clearIdentifyDecisionTreeCondition(obj){
  if (obj.condition){
    clearIdentify(obj.condition);
    obj.condition.items.forEach(item=>clearIdentify(item));    
  }
}

function clearIdentifyDecisionTreePositive(obj){
  if (obj.positive){
    clearIdentify(obj.positive);
    clearIdentifyDecisionTreePositive(obj.positive);    
    clearIdentifyDecisionTreeNegative(obj.positive);    
    clearIdentifyDecisionTreeCondition(obj.positive)
  }
}
function clearIdentifyDecisionTreeNegative(obj){
  if (obj.negative){
    clearIdentify(obj.negative);
    clearIdentifyDecisionTreeNegative(obj.negative);    
    clearIdentifyDecisionTreePositive(obj.negative);
    clearIdentifyDecisionTreeCondition(obj.negative)
  }
}
function clearDecisionTreeRoot(obj){
  if (obj.root){
    clearIdentify(obj.root)
    clearIdentifyDecisionTreeCondition(obj.root)
    clearIdentifyDecisionTreeNegative(obj.root)
    clearIdentifyDecisionTreePositive(obj.root)
  }
}

function clearIdentifiesDecisionTree(obj){
  obj.name = null;
  clearIdentify(obj)                            
  clearDecisionTreeRoot(obj)
}


/* @ngInject */
function stateConfig($stateProvider, PAGE_SIZES) {
  
	const states = [
		{
			name: 'decision-tree',
			parent: 'analysis',
			url: '/decision-trees?page&sort&query',
			component: 'decisionTreeSearch',
			data: {
				viewAuthorities: ['ANALYSIS_READ', 'DECISIONTREE_READ'],
				editAuthorities: ['ANALYSIS_WRITE', 'DECISIONTREE_WRITE']
			},
			params: {
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				size: {
					value: PAGE_SIZES[0],
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'name,asc',
					squash: true,
					dynamic: true
				},
				query: {
					value: '',
					squash: true,
					dynamic: true
				}
			}
		},
		{
			name: 'decision-tree.detail',
			url: '/{id}',
			component: 'decisionTreeDetail',
			resolve: {
				/* @ngInject */
				entity: (DecisionTree, $transition$, entityInjector) => entityInjector(DecisionTree,$transition$.params().id,{})
					
			}
		},
    {
			name: 'decision-tree.duplicate',
			url: '/{id}',
			component: 'decisionTreeDetail',
			resolve: {
				/* @ngInject */
				entity: (DecisionTree, $transition$, entityInjector) => {          
					let decisionTreeDetail = entityInjector(DecisionTree,$transition$.params().id,{});					
          
					return decisionTreeDetail.then(function(response) {              							              
              clearIdentifiesDecisionTree(response);
              return response;
						});

					
				}
			}
		},
		{
			name: 'decision-tree.duplicate.node',
			url: '/{iid}',
			component: 'decisionTreeNodeDetail',
			resolve: {
				/* @ngInject */
				entity: (DecisionTree, $transition$, entityInjector) => {},
				/* @ngInject */
				allMetrics: Metadata =>
					Metadata.getMetrics({ measurement: 'KPI', view: 'DECISION' })
			}
		},
    {
			name: 'decision-tree.detail.node',
			url: '/{iid}',
			component: 'decisionTreeNodeDetail',
			resolve: {
				/* @ngInject */
				entity: (DecisionTree, $transition$, entityInjector) => {},
				/* @ngInject */
				allMetrics: Metadata =>
					Metadata.getMetrics({ measurement: 'KPI', view: 'DECISION' })
			}
		}
	];

	states.forEach(state => $stateProvider.state(state));
}


app.config(stateConfig);
