import app from '@/app';
import templateUrl from './budget-limits-detail.html';

/* @ngInject */
function Controller($controller, $timeout, BudgetLimit, Principal) {

  const $ctrl = this;

  $ctrl.currentAccount = null;

  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: BudgetLimit,
		type: 'budgetLimit'
	});

  $ctrl.incorrectDate = false;

  this.$onInit = function() {
    if($ctrl.entity.items.length > 0){
      for(var item of $ctrl.entity.items){
        item.readonly = true;
      }

      var lastIndex = $ctrl.entity.items.length - 1;
      var lastItem = $ctrl.entity.items[lastIndex];
      lastItem.readonly = false;


    }
  };

    $ctrl.checkDateValid = (item) => {
      var index = $ctrl.entity.items.indexOf(item);
      if(index <= 0){
        $ctrl.incorrectDate = false;
      }else if(item.dateLimit <= $ctrl.entity.items[index - 1].dateLimit){
        $ctrl.incorrectDate = true;
      }else{
        $ctrl.incorrectDate = false;
      }
      }
  
  $ctrl.idgen = 1;
  $ctrl.addItem = () => {
    var i = $ctrl.entity.items.length -1;
    
    if(i >= 0){
      var item = $ctrl.entity.items[i];
      if(!$ctrl.incorrectDate){
        if(item.dateLimit && item.limit){
          item.readonly = true;
          $ctrl.entity.items.splice(i, 1, item);
          $ctrl.entity.items.push({ id: --$ctrl.idgen, 
            readonly: false });
        }
      }
    }

    if(i == -1){
      $ctrl.entity.items.push({ id: --$ctrl.idgen, 
        readonly: false });
    }
    
  };

  $ctrl.removeItem = id => {
		var i = $ctrl.entity.items.length -2;
    var item = $ctrl.entity.items[i];
    item.readonly= false;
    $ctrl.entity.items.splice(i, 1, item);
    $ctrl.entity.items = $ctrl.entity.items.filter(it => it.id != id);
		$ctrl.dirtyItems();
		$ctrl.items = true;
    
	};
}

var component = {
	templateUrl,
	controller: Controller,
  require: {
		parent: '^budgetLimitsSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<'
	}
};

app.component('budgetLimitsDetail', component);