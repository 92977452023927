import app from '@/app';
import templateUrl from './pivot-table.html';

/* @ngInject */
function controller($translate, wfPivotUtils) {
	const $ctrl = this;

	const ZERO = String.fromCharCode(0);

	const DEFAULT_OPTIONS = {
		cols: [],
		rows: [],
		translations: {},
		aggregator: wfPivotUtils.aggregators.count(),
		formatter: wfPivotUtils.formatters.number(3)
	};

	function spanLoop(keys, fast, slow, pre, pos) {
		for (let index = 0; index <= slow; ++index) {
			if (keys[fast - pre][index].value !== keys[fast + pos][index].value) {
				return true;
			}
		}
		return false;
	}

	$ctrl.spanDraw = function(keys, fast, slow) {
		return fast === 0 || spanLoop(keys, fast, slow, 1, 0);
	};

	$ctrl.spanSize = function(keys, fast, slow) {
		let len = 0;
		while (fast + len < keys.length && !spanLoop(keys, fast, slow, 0, len)) {
			++len;
		}
		return len;
	};

	const flatKey = (...entries) =>
		entries.map(keys => keys.map(key => key.value).join(ZERO)).join(ZERO + ZERO);

	const makeKey = (keys, item) =>
		keys.map(key => ({
			name: key.name || key,
			value: item[key.name || key],
			formatter: key.formatter
		}));

	const keySort = keys => (lhs, rhs) =>
		keys
			.map((key, index) =>
				(key.sorter || wfPivotUtils.sorters.naturalSort)(lhs[index].value, rhs[index].value)
			)
			.find(value => value != 0) || 0;

	$ctrl.$onChanges = function(changes) {
		Object.assign($ctrl, DEFAULT_OPTIONS, $ctrl.options);

		const rowKeys = {};
		const colKeys = {};
		const tree = {};
		if ($ctrl.model) {
			for (let item of $ctrl.model) {
            const rowKey = makeKey($ctrl.rows, item);
            const colKey = makeKey($ctrl.cols, item);
            const flatRowKey = flatKey(rowKey);
            const flatColKey = flatKey(colKey);
            const flatAllKey = flatKey(rowKey, colKey);

            if (rowKey.length !== 0 && !rowKeys[flatRowKey]) {
              rowKeys[flatRowKey] = rowKey;
            }

            if (colKey.length !== 0 && !colKeys[flatColKey]) {
              colKeys[flatColKey] = colKey;
            }

            if (colKey.length !== 0 && rowKey.length !== 0) {
              if (!tree[flatAllKey]) {
                tree[flatAllKey] = $ctrl.aggregator();
              }
              tree[flatAllKey].push(item);
            }
        }
        
			$ctrl.rowKeys = Object.values(rowKeys).sort(keySort($ctrl.rows));
			$ctrl.colKeys = Object.values(colKeys).sort(keySort($ctrl.cols));
			$ctrl.tree = tree;
		}
	};

	$ctrl.formatHead = head => $translate.instant(head.translation || head.type || head);
	$ctrl.formatNeck = neck => (neck.formatter ? neck.formatter(neck.value) : neck.value);

	$ctrl.aggregatorOf = (rowKey, colKey) => $ctrl.tree[flatKey(rowKey, colKey)];

	$ctrl.valueOfAggregator = aggregator => aggregator && aggregator.value && aggregator.value();
	$ctrl.valueOf = (rowKey, colKey) => $ctrl.valueOfAggregator($ctrl.aggregatorOf(rowKey, colKey));

	$ctrl.styleOfAggregator = aggregator => aggregator && aggregator.style && aggregator.style();
	$ctrl.styleOf = (rowKey, colKey) => $ctrl.styleOfAggregator($ctrl.aggregatorOf(rowKey, colKey));
}

const component = {
	templateUrl,
	controller,
	bindings: {
		model: '<',
		options: '<',
    metric: '<'
	}
};

app.component('wfPivotTable', component);
