import app from '@/app';
import templateUrl from './decision-tree-search.html';

/* @ngInject */
function DecisionTreeSearchController($controller, DecisionTree, Principal, $http) {
	const $ctrl = this;

  $ctrl.currentAccount = null;

  var resource = DecisionTree;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	$controller('AbstractEntitySearchController', {
		$ctrl: $ctrl,
		resource: DecisionTree,
		type: 'decisionTree'
	});

	const transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		transition(params);
		$ctrl.routeParams = params;
	};

  $http.get('api/decisionTreeProjects', { params: {onlyUsed : true} }).then(function(response) {
    $ctrl.allProjects = response;
  })

  $ctrl.clearUiSelect = function($event, $select) {
    $event.stopPropagation();
    $select.selected = null;
    $select.search = null;

    $ctrl.project = null;

  };

  $ctrl.onQueryChange = function(){

    var pDecisionTreeProject = $ctrl.decisionTreeProject && $ctrl.decisionTreeProject.id ? $ctrl.decisionTreeProject.id : null;
    var pSituation = $ctrl.situation;

		resource.query(
			{
        query: $ctrl.nameDecisionTree,
        decisionTreeProject: pDecisionTreeProject,
        situation: pSituation
			},
			data => {
				$ctrl.entities = data.content;
				$ctrl.count = data.totalElements;
			}
		);
	};


}

const component = {
	templateUrl,
	controller: DecisionTreeSearchController,
	bindings: {
		$transition$: '<'
	}
};

app.component('decisionTreeSearch', component);
