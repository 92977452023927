import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	var states = [
		stateHelperProvider.search({
			name: 'organization',
			parent: 'system',
			data: {
				viewAuthorities: ['ORGANIZATIONS_READ'],
				editAuthorities: ['ORGANIZATIONS_WRITE']
			}
		}),
		{
			name: 'organization.detail',
			url: '/{id}',
			component: 'organizationDetail',
			resolve: {
				/* @ngInject */
				entity: (Organization, $transition$) =>
					Organization.inject($transition$.params().id, { roads: [] })
			}
		},
		{
			name: 'organization.detail.road',
			url: '/roads/{iid}',
			component: 'organizationRoadDetail'
		}
	];
	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
