import app from '@/app';
import { Chart } from 'chart.js';

/* @ngInject */
function config(
	$qProvider,
	$uibModalProvider,
	uibDatepickerConfig,
	uibDatepickerPopupConfig,
	uiSelectConfig,
	uibPaginationConfig,
	ChartJsProvider,
	ITEMS_PER_PAGE
) {
	uibDatepickerPopupConfig.showButtonBar = false;
	uibDatepickerPopupConfig.datepickerPopup = 'shortDate';
	uiSelectConfig.theme = 'bootstrap';
	uibPaginationConfig.itemsPerPage = ITEMS_PER_PAGE;
	$qProvider.errorOnUnhandledRejections(false);
	$uibModalProvider.options.backdrop = 'static';
	ChartJsProvider.setOptions({
		chartColors: [
			'#F9AE41',
			'#BFBFBF',
			'#161A44',
			'#000000',
			'#C00000',
			'#4F6228',
			'#0000ff',
			'#ffff00',
			'#00ff00',
			'#ff00ff',
			'#ff0000',
			'#ff8000'
		]
	});
	Chart.plugins.register({
		beforeDraw: function(chart) {
			var backgroundColor = chart.config.options.backgroundColor;
			if (backgroundColor) {
				var ctx = chart.chart.ctx;
				ctx.save();
				ctx.fillStyle = backgroundColor;
				ctx.fillRect(0, 0, chart.chart.canvas.width, chart.chart.canvas.height);
				ctx.restore();
			}
		}
	});
}

app.config(config);
