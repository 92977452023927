import app from '@/app';

/* @ngInject */
function Service() {
	this.createPage = (page, size, count, step, offset) => ({
		page: page,
		size: size,
		head: offset + page * step * size,
		tail: offset + Math.min((page + 1) * size * step, count * step) - step
	});

	this.createPages = function(size, count, step, offset) {
		const pageCount = Math.ceil(count / size);
		let pages = [];
		for (let page = 0; page < pageCount; ++page) {
			pages.push(this.createPage(page, size, count, step, offset));
		}
		return pages;
	};
}

app.service('wfPager2Service', Service);
