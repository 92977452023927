import app from '@/app';

/* @ngInject */
function factory($resource) {
	return function(entity, extra) {
		const resource = $resource(
			'api/' + entity + 's/:id',
			{ id: '@id' },
			{
				create: { method: 'POST' },
				query: { method: 'GET' },
				all: { method: 'GET', params: { id: 'all' }, isArray: true },
				update: { method: 'PUT' }
			}
		);
		resource.inject = (id, values) =>
			id && id !== 'new' ? resource.get({ id: id }).$promise : new resource(values);
		resource.type = entity;
		return resource;
	};
}

app.factory('abstractResource', factory);
