(function() {
	'use strict';

	Igg.$inject = ['$resource'];
	function Igg($resource) {
		return $resource(
			'api/iggs/:id',
			{ id: '@id' },
			{
				query: { method: 'GET' },
				purge: { method: 'DELETE' }
			}
		);
	}

	angular.module('pavrunner').factory('Igg', Igg);
})();
