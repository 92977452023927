import app from '@/app';
import template from './pager.html';

var component = {
	templateUrl: template,
	controller: Controller,
	bindings: {
		page: '=',
		totalItems: '<',
		itemsPerPage: '<',
		onChange: '&'
	}
};

/* @ngInject */
function Controller(ITEMS_PER_PAGE) {
	var $ctrl = this;

	function refresh() {
		$ctrl.head = Math.min($ctrl.page * $ctrl.itemsPerPage + 1, $ctrl.totalItems);
		$ctrl.tail = Math.min(($ctrl.page + 1) * $ctrl.itemsPerPage, $ctrl.totalItems);
	}

	$ctrl.$onChanges = function(changes) {
		$ctrl.model = $ctrl.page + 1;
		$ctrl.itemsPerPage = $ctrl.itemsPerPage || ITEMS_PER_PAGE;
		refresh();
	};

	$ctrl.inChanges = function() {
		$ctrl.page = $ctrl.model - 1;
		refresh();
		$ctrl.onChange({ page: $ctrl.page });
	};
}

app.component('wfPager', component);
