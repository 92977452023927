import app from '@/app';
import templateUrl from './organization-road-detail.html';

/* @ngInject */
function controller($controller, $state, Principal) {
	var $ctrl = this;
	$controller('AbstractController', { $ctrl: $ctrl });

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})


	var transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		transition(params);
		$ctrl.entity = $ctrl.parent.loadRoad(params.iid);
	};

	$ctrl.save = function() {
		$ctrl.parent.saveRoad($ctrl.entity);
		$state.go('^');
	};
}

var component = {
	templateUrl,
	controller,
	require: {
		parent: '^organizationDetail'
	},
	bindings: {
		$transition$: '<'
	}
};

app.component('organizationRoadDetail', component);
