import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider) {
	$stateProvider.state('sessions', {
		parent: 'account',
		url: '/sessions',
		templateUrl: '/app/account/sessions/sessions.html',
		controller: 'SessionsController',
		controllerAs: 'vm'
	});
}

app.config(stateConfig);
