import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	const states = [
		stateHelperProvider.search({
			name: 'unifilarParametersComments',
			parent: 'measurements',
			url: '/unifilarParametersComments?road&campaign&plantype',
			data: {
				viewAuthorities: ['ANALYSIS_READ'],
				editAuthorities: ['ANALYSIS_WRITE', 'ANALYSIS_PARTIAL_WRITE']
			},
			params: {
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'name,asc',
					squash: true,
					dynamic: true
				},
				road: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				campaign: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				plantype: {
					type: 'int',
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allOrganizations: [
					'Organization',
					Organization => {
						return Organization.query({ sort: 'name,asc' }).$promise;
					}
				],
				/* @ngInject */
				allRoads: Road => Road.query({ sort: 'name,asc', empty: false }).$promise,
				/* @ngInject */
				allCampaigns: Campaign =>
					Campaign.query({ sort: 'name,asc', empty: false }).$promise,
				/* @ngInject */
				allCriteriaSets: CriteriaSet => CriteriaSet.query({ sort: 'name,asc' }).$promise
			}
		}),
		{
			name: 'unifilarParametersComments.detail',
			url: '/{id}',
			component: 'unifilarParametersCommentsDetail'
		},
		{
			name: 'unifilarParametersComments.detail.segment',
			url: '/segments/{iid}',
			component: 'unifilarParametersCommentsSegmentDetail'
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
