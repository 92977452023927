import app from '@/app';

/* @ngInject */
function Service($uibModal) {
	var modalInstance = null;

	var resetModal = function() {
		modalInstance = null;
	};

	this.open = function() {
		if (modalInstance === null) {
			modalInstance = $uibModal.open({
				component: 'warnDuplicateDialog',
				size: 'lg'
			});
			modalInstance.result.then(resetModal, resetModal);
		}
	};
}

app.service('WarnDuplicateService', Service);
