import app from '@/app';

/* @ngInject */
function controller($controller, $ctrl, $state, resource) {
	$controller('AbstractPageSortController', { $ctrl });

	var transition = $ctrl.transition;
	$ctrl.transition = params => {
		transition(params);

		$ctrl.query = params.query;
		resource.query(
			{
				query: $ctrl.query,
				sort: $ctrl.sort,
				page: $ctrl.page,
				size: $ctrl.size
			},
			data => {
				$ctrl.entities = data.content;
				$ctrl.totalItems = data.totalElements;
				$ctrl.count = data.totalElements;
			}
		);
	};

	$ctrl.remove = id => resource.remove({ id: id }, () => $state.reload()).$promise;

	$ctrl.onQueryChange = query => $state.go($state.current, { page: 0, query: query });
}

app.controller('AbstractEntitySearchController', controller);
