import app from '@/app';
import templateUrl from './constant-set-detail.html';

/* @ngInject */
function Controller($controller, ConstantSet, Principal) {

  const $ctrl = this;
  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	})

	$controller('AbstractEntityDetailController', {
		$ctrl: this,
		resource: ConstantSet,
		type: 'constantSet'
	});
}

var component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<',
		entity: '<'
	}
};

app.component('constantSetDetail', component);
