import app from '@/app';
import angular from 'angular';

/* @ngInject */
function Auth(
	$rootScope,
	$state,
	$sessionStorage,
	$q,
	$translate,
	Principal,
	Account,
	AuthServerProvider,
	LoginService,
	Password,
	PasswordResetInit,
	PasswordResetFinish
) {
	this.authorize = force => {
		Principal.identity(force).then(() => {
			var isAuthenticated = Principal.isAuthenticated();
			if (
				isAuthenticated &&
				$rootScope.toState.parent === 'account' &&
				$rootScope.toState.name === 'login'
			) {
				$state.go('home');
			} else if (
				isAuthenticated &&
				!$rootScope.fromState.name &&
				$sessionStorage.previousState
			) {
				var previousState = $sessionStorage.previousState;
				delete $sessionStorage.previousState;
				$state.go(previousState.name, previousState.params);
			} else {
				const authorities = []
					.concat(
						$rootScope.toState.data.authorities,
						$rootScope.toState.data.viewAuthorities,
						$rootScope.toState.data.editAuthorities
					)
					.filter(it => it);
				if (authorities && authorities.length > 0 && !Principal.hasAuthority(authorities)) {
					if (isAuthenticated) {
						$state.go('accessdenied');
					} else {
						$sessionStorage.previousState = {
							name: $rootScope.toState.name,
							params: $rootScope.toStateParams
						};
						$state.go('accessdenied').then(() => LoginService.open());
					}
				}
			}
		});
	};

	this.changePassword = (password, cb = angular.noop) =>
		Password.save(
			password,
			() => cb(),
			error => cb(error)
		).$promise;

	this.login = (credentials, cb = angular.noop) => {
		var deferred = $q.defer();

		AuthServerProvider.login(credentials)
			.then(data => {
				Principal.identity(true).then(account => {
					if (account !== null) {
						$translate.use(account.language).then(() => $translate.refresh());
					}
					deferred.resolve(data);
				});
				return cb();
			})
			.catch(err => {
				this.logout();
				deferred.reject(err);
				return cb(err);
			});

		return deferred.promise;
	};

	this.logout = async () => {
		await AuthServerProvider.logout();
		Principal.authenticate(null);
	};

	this.resetPasswordFinish = (keyAndPassword, cb = angular.noop) =>
		PasswordResetFinish.save(
			keyAndPassword,
			() => cb(),
			error => cb(error)
		).$promise;

	this.resetPasswordInit = (mail, cb = angular.noop) =>
		PasswordResetInit.save(
			mail,
			() => cb(),
			error => cb(error)
		).$promise;

	this.updateAccount = (account, cb = angular.noop) =>
		Account.save(
			account,
			() => cb(account),
			error => cb(error)
		).$promise;
}

app.service('Auth', Auth);
