import app from '@/app';

app.factory('AuthServerProvider', AuthServerProvider);

/* @ngInject */
function AuthServerProvider($http, $localStorage) {
	var service = {
		getToken: () => $localStorage.authenticationToken,
		hasValidToken: () => !!$localStorage.authenticationToken,
		login: credentials => {
			const username = encodeURIComponent(credentials.username);
			const password = encodeURIComponent(credentials.password);
			const remember = credentials.rememberMe;
			const data = `j_username=${username}&j_password=${password}&remember-me=${remember}&submit=Login`;
			const headers = {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			};
			return $http.post('api/authentication', data, headers).then(response => response);
		},
		logout: async () => {
			await $http.post('api/logout').then(response => {
				delete $localStorage.authenticationToken;
				// to get a new csrf token call the api
				$http.get('api/account');
				return response;
			});
		}
	};

	return service;
}
