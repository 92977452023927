import app from '@/app';

app.factory('Password', Password);

/* @ngInject */

function Password($resource) {
	var service = $resource('api/account/change_password', {}, {});

	return service;
}
