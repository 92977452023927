import React, { Component } from 'react';
import {
	Jumbotron,
	Col,
	Row,
	Form,
	FormGroup,
	Label,
	Input,
	Button,
	Table,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';

import './style.css';
import SelectData from './reducedData';
import AllowedValue from './AllowedValue';
import iconWarning from '../../../assets/iconWarning.png';
import axios from 'axios';

export default class Mechanical extends Component {
	constructor(props) {
		super(props);

		this.state = {
			values: {
				layer1: [],
				layer2: [],
				layer3: [],
				subgrade: [],
				pavingType: 0,
				usaceCoefficient: '',
				usaceExponent: '',
				aashtoCoefficient: '',
				aashtoExponent: ''
			},
			resistanceCementedLayer: '',
			expected: '',
			layerModel1D: '',
			layerModel1Et: '',
			layerModel2: '',
			layerModel3: '',
			subgradeModel: '',
			onSubmit: false,
			hideTable: false,
			showModal: true
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleChangeSeletLayer = this.handleChangeSeletLayer.bind(this);

		this.toggle = this.toggle.bind(this);

		this.baseState = this.state;
	}

	async componentDidUpdate(_, prevState) {
		if (this.state.onSubmit === true && prevState.values !== this.state.values) {
			await this.setState({
				onSubmit: false,
				hideTable: false
			});
		}

		if (
			prevState.values.pavingType !== 0 &&
			prevState.values.pavingType !== this.state.values.pavingType
		) {
			await this.setState({
				values: {
					...this.state.values,
					layer1: [],
					layer2: [],
					layer3: [],
					subgrade: []
				}
			});
			let currentType = this.state.values.pavingType;
			$('select').val('');
			$('#pavingType').val(currentType);
		}
	}

	async handleSubmit(e) {
		e.preventDefault();
		this.setState({
			onSubmit: true,
			hideTable: true,
			expected: ''
		});
		const response = await axios.post('/api/mechanical-analysis', this.state.values);

		this.setState({ expected: response.data });
	}

	handleChangeSeletLayer(event) {
		const camada = event.target.name.split('-')[0];
		const metric = event.target.name.split('-')[1];
		const index =
			metric == 'Thickness' ? 0 : metric == 'Poisson' ? 1 : metric == 'Module' ? 2 : '';
		let array = this.state.values[camada];
		array[index] = event.target.value;

		this.setState({
			values: {
				...this.state.values,
				[camada]: array
			}
		});
	}

	handleChange(event) {
		const state = Object.assign({}, this.state);
		const campo = event.target.name;

		this.setState({
			values: {
				...state.values,
				[campo]: event.target.value
			}
		});
	}

	handleChangeModel(event) {
		const campo = event.target.name;
		this.setState({ [campo]: event.target.value });
	}

	verify(expectedValue, AllowedValue) {
		if (expectedValue && AllowedValue) {
			return expectedValue > AllowedValue ? (
				<div
					className="container align-self-center"
					style={{
						width: 100,
						height: 30,
						backgroundColor: '#fab7bc',
						borderRadius: 4
					}}
				>
					<Label style={{ marginTop: '8%' }}>Não Aceito</Label>
				</div>
			) : (
				<div
					className="container align-self-center"
					style={{
						width: 100,
						height: 30,
						backgroundColor: '#aaf683',
						borderRadius: 4
					}}
				>
					<Label style={{ marginTop: '8%' }}>Aceito</Label>
				</div>
			);
		}
		return;
	}

	formatToPower(number) {
		if (number) {
			let formattedNumber = parseFloat(number).toExponential(2);
			formattedNumber = formattedNumber.split('e');
			return (
				<Label>
					{' '}
					{formattedNumber[0]} x 10 <sup>{formattedNumber[1]}</sup>{' '}
				</Label>
			);
		}
	}

	toggle() {
		this.setState({ showModal: !this.state.showModal });
	}

	warningUsedParameters() {
		return (
			<div>
				<button type="button" class="button_warning" onClick={this.toggle}>
					<img src={iconWarning} />
				</button>
				<Modal
					style={{ width: 1000, marginTop: 100 }}
					isOpen={this.state.showModal}
					toggle={this.toggle}
				>
					<ModalHeader
						style={{ margin: 10, padding: 20 }}
						toggle={this.toggle}
					></ModalHeader>
					<ModalBody>
						<p>{SelectData()['textWarningP1']}</p>
						<p>{SelectData()['textWarningP2']}</p>
						<p>{SelectData()['textWarningP3']}</p>
						<ul>
							<li>[1] Origem (0;0)</li>
							<li>[2] Afastada 28,8 cm da origem (28,8;0)</li>
							<li>[3] Afastada 176,1 cm da origem (176,1;0)</li>
							<li>[4] Afastada 204,9 cm da origem (204,9;0)</li>
						</ul>
						<p>{SelectData()['textWarningP4']}</p>
						<p>{SelectData()['textWarningP5']}</p>
						<p>{SelectData()['textWarningP6']}</p>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.toggle}>
							Entendido
						</Button>{' '}
					</ModalFooter>
				</Modal>
			</div>
		);
	}

	row1D() {
		if (this.state.expected !== '' && this.state.hideTable == true) {
			return (
				<tr align="center">
					<th scope="row">1</th>
					<td>D</td>
					<td>{this.state.expected.deflection.toFixed(2)}</td>
					<td>
						<Input
							className="container align-self-center"
							name="layerModel1D"
							type="select"
							value={this.state.layerModel1D}
							onChange={this.handleChangeModel}
							style={{ width: 215 }}
						>
							<option value="">Selecione</option>
							<option value="1">DNER-PRO-011 (1979)</option>
							<option value="2">DNER-PRO-269 (1994)</option>
						</Input>
					</td>
					<th>
						<Label>
							{this.state.expected.usaceCoefficient} x 10
							<sup>{this.state.expected.usaceExponent}</sup>
						</Label>
					</th>
					<th>
						<Label>
							{AllowedValue({
								layer: 'layer1D',
								model: this.state.layerModel1D,
								usaceCoefficient: this.state.expected.usaceCoefficient,
								usaceExponent: this.state.expected.usaceExponent
							})}
						</Label>
					</th>
					<th align="centeFormFeedbackr">
						{this.verify(
							this.state.expected.deflection,
							AllowedValue({
								layer: 'layer1D',
								model: this.state.layerModel1D,
								usaceCoefficient: this.state.expected.usaceCoefficient,
								usaceExponent: this.state.expected.usaceExponent
							})
						)}
					</th>
				</tr>
			);
		}
	}

	row1Et() {
		if (this.state.expected !== '' && this.state.hideTable == true) {
			if (this.state.expected.pavingType == 1 || this.state.expected.pavingType == 3) {
				return (
					<tr align="center">
						<th scope="row">1</th>
						<td>εt</td>
						<td>{this.formatToPower(this.state.expected.horizontalDeformation)}</td>
						<td>
							<Input
								required
								className="container align-self-center"
								name="layerModel1Et"
								type="select"
								value={this.state.layerModel1Et}
								onChange={this.handleChangeModel}
								style={{ width: 215 }}
							>
								<option value="">Selecione</option>
								<option value="1">FHWA (1976)</option>
								<option value="2">Asphalt Institute (1976)</option>
							</Input>
						</td>
						<th>
							<Label>
								{this.state.expected.aashtoCoefficient} x 10
								<sup>{this.state.expected.aashtoExponent}</sup>
							</Label>
						</th>
						<th>
							{this.formatToPower(
								AllowedValue({
									layer: 'layer1Et',
									model: this.state.layerModel1Et,
									aashtoCoefficient: this.state.expected.aashtoCoefficient,
									aashtoExponent: this.state.expected.aashtoExponent
								})
							)}
						</th>
						<th align="center">
							{this.verify(
								this.state.expected.horizontalDeformation,
								AllowedValue({
									layer: 'layer1Et',
									model: this.state.layerModel1Et,
									aashtoCoefficient: this.state.expected.aashtoCoefficient,
									aashtoExponent: this.state.expected.aashtoExponent
								})
							)}
						</th>
					</tr>
				);
			}
		}
	}

	row3Androw2(pavingType, row, value) {
		if (this.state.expected !== '' && this.state.hideTable == true) {
			if (this.state.expected.pavingType == pavingType) {
				return (
					<tr align="center">
						<th scope="row">{row}</th>
						<td>σt</td>
						<td>{this.state.expected.horizontalTension}</td>
						<td>
							<Input
								className="container align-self-center"
								name={'layerModel' + row}
								type="select"
								value={value}
								onChange={this.handleChangeModel}
								style={{ width: 215 }}
							>
								<option value="">Selecione</option>
								<option value="1">Balbo (1993)</option>
								<option value="2">Ceratti (1991) – NA</option>
								<option value="3">Ceratti (1991) – LA</option>
								<option value="4">Ceratti (1991) – LA’</option>
								<option value="5">Ceratti (1991) – LG’</option>
							</Input>
						</td>
						<th>
							<Label>
								{this.state.expected.usaceCoefficient} x 10
								<sup>{this.state.expected.usaceExponent}</sup>
							</Label>
						</th>
						<th>
							<Label>
								{AllowedValue({
									RT: this.state.resistanceCementedLayer,
									layer: 'layer' + row,
									model: value,
									usaceCoefficient: this.state.expected.usaceCoefficient,
									usaceExponent: this.state.expected.usaceExponent
								})}
							</Label>
						</th>
						<th align="center">
							{this.verify(
								this.state.expected.horizontalTension,
								AllowedValue({
									RT: this.state.resistanceCementedLayer,
									layer: 'layer' + row,
									model: value,
									usaceCoefficient: this.state.expected.usaceCoefficient,
									usaceExponent: this.state.expected.usaceExponent
								})
							)}
						</th>
					</tr>
				);
			}
		}
	}

	rowSubgrade() {
		if (this.state.expected !== '' && this.state.hideTable == true) {
			return (
				<tr align="center">
					<th scope="row">Subleito</th>
					<td>εv</td>
					<td>{this.formatToPower(this.state.expected.verticalDeformation)}</td>
					<td>
						<Input
							className="container align-self-center"
							name="subgradeModel"
							type="select"
							value={this.state.subgradeModel}
							onChange={this.handleChangeModel}
							style={{ width: 215 }}
						>
							<option value="">Selecione</option>
							<option value="1">Dormon e Metcalf (1965)</option>
							<option value="2">Asphalt Institute (1984)</option>
							<option value="3">Shell 95% Confiabilidade (1985)</option>
						</Input>
					</td>
					<th>
						<Label>
							{this.state.expected.usaceCoefficient} x 10
							<sup>{this.state.expected.usaceExponent}</sup>
						</Label>
					</th>
					<th>
						{this.formatToPower(
							AllowedValue({
								layer: 'subgrade',
								model: this.state.subgradeModel,
								usaceCoefficient: this.state.expected.usaceCoefficient,
								usaceExponent: this.state.expected.usaceExponent
							})
						)}
					</th>
					<th align="center">
						{this.verify(
							this.state.expected.verticalDeformation,
							AllowedValue({
								layer: 'subgrade',
								model: this.state.subgradeModel,
								usaceCoefficient: this.state.expected.usaceCoefficient,
								usaceExponent: this.state.expected.usaceExponent
							})
						)}
					</th>
				</tr>
			);
		}
	}

	equivalentThicknessValidation() {
		if (
			this.state.values.pavingType &&
			this.state.values.layer1[0] && //[0] = Thickness
			this.state.values.layer2[0] &&
			this.state.values.layer3[0] &&
			this.state.values.usaceCoefficient &&
			this.state.values.usaceExponent &&
			this.state.values.subgrade[2] //[2] = Module
		) {
			let totalThickness;

			if (this.state.values.pavingType == 1) {
				totalThickness =
					2 * parseFloat(this.state.values.layer1[0]) +
					parseFloat(this.state.values.layer2[0]) +
					parseFloat(this.state.values.layer3[0]);
			}
			if (this.state.values.pavingType == 2) {
				totalThickness =
					2 * parseFloat(this.state.values.layer1[0]) +
					1.7 * parseFloat(this.state.values.layer2[0]) +
					parseFloat(this.state.values.layer3[0]);
			}
			if (this.state.values.pavingType == 3) {
				totalThickness =
					2 * parseFloat(this.state.values.layer1[0]) +
					parseFloat(this.state.values.layer2[0]) +
					1.7 * parseFloat(this.state.values.layer3[0]);
			}
			const limite =
				77.67 *
					Math.pow(
						this.state.values.usaceCoefficient *
							Math.pow(10, this.state.values.usaceExponent),
						0.0482
					) *
					(this.state.values.subgrade[2] / 100) -
				0.598;
			if (totalThickness > limite) {
				return (
					<div
						style={{ opacity: 0.6 }}
						class="alert alert-warning alert-dismissible show"
						role="alert"
					>
						<button type="button" class="close" data-dismiss="alert" aria-label="true">
							<span aria-hidden="true">&times;</span>
						</button>
						<h5 style={{ color: '#000' }}>
							{' '}
							A espessura equivalente total de material granular <strong>
								Não
							</strong>{' '}
							atende ao mínimo do DNER
						</h5>
					</div>
				);
			}
		}
	}

	minimumCoatingThickness() {
		if (
			this.state.values.usaceCoefficient * Math.pow(10, this.state.values.usaceExponent) >
				0 &&
			this.state.values.usaceCoefficient * Math.pow(10, this.state.values.usaceExponent) <=
				1.0 * Math.pow(10, 6)
		) {
			return 1.5;
		}
		if (
			this.state.values.usaceCoefficient * Math.pow(10, this.state.values.usaceExponent) >
				1 * Math.pow(10, 6) &&
			this.state.values.usaceCoefficient * Math.pow(10, this.state.values.usaceExponent) <=
				5.0 * Math.pow(10, 6)
		) {
			return 5;
		}
		if (
			this.state.values.usaceCoefficient * Math.pow(10, this.state.values.usaceExponent) >
				5 * Math.pow(10, 6) &&
			this.state.values.usaceCoefficient * Math.pow(10, this.state.values.usaceExponent) <=
				1.0 * Math.pow(10, 7)
		) {
			return 7.5;
		}
		if (
			this.state.values.usaceCoefficient * Math.pow(10, this.state.values.usaceExponent) >
				1 * Math.pow(10, 7) &&
			this.state.values.usaceCoefficient * Math.pow(10, this.state.values.usaceExponent) <=
				5 * Math.pow(10, 7)
		) {
			return 10;
		}
		if (
			this.state.values.usaceCoefficient * Math.pow(10, this.state.values.usaceExponent) >
			5 * Math.pow(10, 7)
		) {
			return 12.5;
		}
	}

	coatingThicknessValidation() {
		if (
			this.state.values.layer1[0] &&
			this.state.values.usaceCoefficient &&
			this.state.values.usaceExponent
		) {
			if (this.state.values.layer1[0] < this.minimumCoatingThickness()) {
				return (
					<div
						style={{ opacity: 0.6 }}
						class="alert alert-warning alert-dismissible show"
						role="alert"
					>
						<button type="button" class="close" data-dismiss="alert" aria-label="true">
							<span aria-hidden="true">&times;</span>
						</button>
						<h5 style={{ color: '#000' }}>
							{' '}
							A espessura do revestimento <strong>Não</strong> atende ao mínimo do
							DNER
						</h5>
					</div>
				);
			}
		}
	}

	rowInputTable(layerName, stringNamelayer) {
		const createItem = (item, key) => (
			<option key={key} value={item.value}>
				{item.name}{' '}
			</option>
		);

		return (
			<Row>
				<Col md={3}>
					<Label>{stringNamelayer}</Label>
				</Col>
				{layerName !== 'subgrade' ? (
					<Col md={3}>
						<FormGroup>
							<Input
								id={layerName + '-Thickness'}
								className="container align-self-center"
								name={layerName + '-Thickness'}
								disabled={this.state.values.pavingType == 0 ? true : false}
								type="select"
								onChange={this.handleChangeSeletLayer}
								style={{ width: 150 }}
								required
							>
								<option value=""></option>
								{this.state.values.pavingType != 0
									? SelectData()[
											layerName +
												'ThicknessVectorType' +
												this.state.values.pavingType
									  ].map(createItem)
									: ''}
							</Input>
							{/* <Input name={layerName+"Thickness"} onChange={this.handleChange} lang="nb"  min="0" step=".1" type="number" required/> */}
						</FormGroup>
					</Col>
				) : (
					<Col md={3} />
				)}
				<Col md={3}>
					<FormGroup>
						<Input
							className="container align-self-center"
							name={layerName + '-Poisson'}
							disabled={this.state.values.pavingType == 0 ? true : false}
							type="select"
							onChange={this.handleChangeSeletLayer}
							style={{ width: 150 }}
							required
						>
							<option value=""></option>
							{this.state.values.pavingType != 0
								? SelectData()[
										layerName +
											'PoissonVectorType' +
											this.state.values.pavingType
								  ].map(createItem)
								: ''}
						</Input>
						{/* <Input name={layerName+"Poisson"} onChange={this.handleChange} lang="nb"  min="0" max="0.5" step=".01" type="number" required/> */}
					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup>
						<Input
							className="container align-self-center"
							name={layerName + '-Module'}
							disabled={this.state.values.pavingType == 0 ? true : false}
							type="select"
							onChange={this.handleChangeSeletLayer}
							style={{ width: 150 }}
							required
						>
							<option value=""></option>
							{this.state.values.pavingType != 0
								? SelectData()[
										layerName +
											'ModuleVectorType' +
											this.state.values.pavingType
								  ].map(createItem)
								: ''}
						</Input>
						{/* <Input name={layerName+"Module"} onChange={this.handleChange} lang="nb" min="0" step="" type="number" required/> */}
					</FormGroup>
				</Col>
			</Row>
		);
	}

	scientificNumberInput(NumerNName) {
		return (
			<>
				<Label for="E">
					{NumerNName.toUpperCase()} (n x 10<sup>t</sup>)
				</Label>
				<div
					style={{ marginRight: -17 }}
					className="container align-self-center text-center"
				>
					<Col md={3}>
						<Input
							name={NumerNName + 'Coefficient'}
							onChange={this.handleChange}
							lang="nb"
							min="0"
							step=".01"
							type="number"
							required
							style={{ width: 50, marginTop: 10 }}
						/>
					</Col>

					<Col md={4}>
						<Label style={{ marginTop: 20 }} for="E">
							x 10
						</Label>
					</Col>

					<Col md={3}>
						<Input
							name={NumerNName + 'Exponent'}
							onChange={this.handleChange}
							lang="nb"
							min="0"
							step=".01"
							type="number"
							required
							style={{ width: 40, marginLeft: -25, height: 20, fontSize: 10 }}
						/>
					</Col>
				</div>
			</>
		);
	}

	resistanceCementedLayerInput() {
		return (
			<>
				<Label style={{ marginTop: 10 }} for="E">
					Resistência tração da camada cimentada (kgf/cm<sup>2</sup>){' '}
				</Label>
				<Input
					disabled={
						this.state.values.pavingType == 0 || this.state.values.pavingType == 1
							? true
							: false
					}
					className="container align-self-center"
					name="resistanceCementedLayer"
					onChange={this.handleChangeModel}
					style={{ width: 150 }}
					lang="nb"
					min="0"
					step=".2"
					type="number"
					required
				/>
			</>
		);
	}

	render() {
		return (
			<div>
				<h1 style={{ margin: 10 }} className="display-6">
					Análise Mecanística
				</h1>
				<Jumbotron>
					<h4>
						<b>Dados de entrada</b>
					</h4>
					<Form
						onSubmit={this.handleSubmit}
						className="container align-self-center text-center"
					>
						<Row>
							<Col md={8}>
								<Row>
									<Col md={3}>
										<Label for="E"></Label>
									</Col>
									<Col md={3}>
										<Label for="E">Espessura (cm)</Label>
									</Col>
									<Col md={3}>
										<Label for="M">Coeficiente de Poisson</Label>
									</Col>
									<Col md={3}>
										<Label for="P">Módulo (kgf/cm²)</Label>
									</Col>
								</Row>
								{this.rowInputTable('layer1', '1° Camada')}
								{this.rowInputTable('layer2', '2° Camada')}
								{this.rowInputTable('layer3', '3° Camada')}
								{this.rowInputTable('subgrade', 'Subleito')}
							</Col>

							<Col md={3}>
								<Label for="P">Tipo do pavimento</Label>
								<Input
									id="pavingType"
									className="container align-self-center"
									name="pavingType"
									type="select"
									onChange={this.handleChange}
									style={{ width: 150 }}
									required
								>
									<option value="0">Selecione</option>
									<option value="1">Flexível</option>
									<option value="2">Semirrígido</option>
									<option value="3">Invertido</option>
								</Input>

								{this.scientificNumberInput('usace')}
								{this.scientificNumberInput('aashto')}
							</Col>

							<Col md={3}>{this.resistanceCementedLayerInput()}</Col>
						</Row>
						<div class="cloud_current_folder">
							<Button style={{ marginRight: 5 }} className="btn-primary">
								Calcular
							</Button>
							{this.warningUsedParameters()}
						</div>
					</Form>
				</Jumbotron>

				{this.equivalentThicknessValidation()}
				{this.coatingThicknessValidation()}

				<h4 style={{ marginLeft: 65, marginBottom: 20 }} className="display-6">
					<b>Verificação</b>
				</h4>
				<Table striped bordered>
					<thead>
						<tr>
							<th>Camada</th>
							<th>Parâmetro</th>
							<th>Valor Previsto</th>
							<th>Modelo</th>
							<th>Tráfego</th>
							<th>ValorAdmissível</th>
							<th>Aceito</th>
						</tr>
					</thead>
					<tbody>
						{this.row1D()}
						{this.row1Et()}
						{this.row3Androw2(2, 2, this.state.layerModel2)}
						{this.row3Androw2(3, 3, this.state.layerModel3)}
						{this.rowSubgrade()}
					</tbody>
				</Table>
			</div>
		);
	}
}
