import app from '@/app';

/* @ngInject */
function Service($http) {
	function get(url, params) {
		return $http.get(url, { params: params }).then(function(response) {
			return response.data;
		});
	}

	this.getMeasurements = function(params) {
		return get('api/metadata/measurements', params);
	};

	this.getMetrics = function(params) {
		return get('api/metadata/metrics', params);
	};

	this.getMeasurement = function(code) {
		return get('api/metadata/measurements/' + code);
	};

	this.getMetric = function(code) {
		return get('api/metadata/metrics/' + code);
	};
}

app.service('Metadata', Service);
