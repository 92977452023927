import app from '@/app';
import templateUrl from './lifespan-table.html';

/* @ngInject */
function controller($translate, wfPivotUtils) {
	const $ctrl = this;

	const ZERO = String.fromCharCode(0);

	const DEFAULT_OPTIONS = {
		rows: [],
		translations: {},
		aggregator: wfPivotUtils.aggregators.count(),
		formatter: wfPivotUtils.formatters.number(3)
	};

	function spanLoop(keys, fast, slow, pre, pos) {
		for (let index = 0; index <= slow; ++index) {
			if (keys[fast - pre][index].value !== keys[fast + pos][index].value) {
				return true;
			}
		}
		return false;
	}

	$ctrl.spanDraw = function(keys, fast, slow) {
		return fast === 0 || spanLoop(keys, fast, slow, 1, 0);
	};

	$ctrl.spanSize = function(keys, fast, slow) {
		let len = 0;
		while (fast + len < keys.length && !spanLoop(keys, fast, slow, 0, len)) {
			++len;
		}
		return len;
	};

	const flatKey = (...entries) =>
		entries.map(keys => keys.map(key => key.value).join(ZERO)).join(ZERO + ZERO);

	const makeKey = (keys, item) =>
		keys.map(key => ({
			name: key.name || key,
			value: item[key.name || key]
		}));

	const keySort = keys => (lhs, rhs) =>
		keys
			.map((key, index) =>
				(key.sorter || wfPivotUtils.sorters.naturalSort)(lhs[index].value, rhs[index].value)
			)
			.find(value => value != 0) || 0;

	$ctrl.drawLane = function(lane) {
		return lane == 100 || lane == -100 ? 'acost' : Math.abs(lane);
	};

	$ctrl.$onChanges = function(changes) {
		Object.assign($ctrl, DEFAULT_OPTIONS, $ctrl.options);
		const rowKeys = {};
		const colKeys = {};

		$ctrl.optionsChart.title.text = '';
		$ctrl.labels = [];
		$ctrl.data = [];

		if ($ctrl.model) {
			for (let item of $ctrl.model) {
				const colKey = makeKey(
					[
						{
							name: 'headMilestone',
							formatter: wfPivotUtils.formatters.number(3)
						},
						{
							name: 'tailMilestone',
							formatter: wfPivotUtils.formatters.number(3)
						},
						{
							name: 'direction'
						},
						{
							name: 'lane'
						},

						{
							name: 'firstAgeDcIntervention'
						},
						{
							name: 'firstAgeQiIntervention'
						},
						{
							name: 'firstAgeIggIntervention'
						},
						{
							name: 'firstAgeArrowIntervention'
						},
						{
							name: 'firstAgeTrIntervention'
						},
						{
							name: 'minimum'
						}
					],
					item
				);

				const rowKey = makeKey(
					[
						{
							name: 'headMilestone',
							formatter: wfPivotUtils.formatters.number(3)
						},
						{
							name: 'tailMilestone',
							formatter: wfPivotUtils.formatters.number(3)
						}
					],
					item
				);

				const flatRowKey = flatKey(rowKey);
				const flatColKey = flatKey(colKey);

				if (rowKey.length !== 0 && !rowKeys[flatRowKey]) {
					rowKeys[flatRowKey] = rowKey;
				}
				if (colKey.length !== 0 && !colKeys[flatColKey]) {
					colKeys[flatColKey] = colKey;
				}
			}

			let rows = Object.values(rowKeys).sort(keySort($ctrl.rows));
			$ctrl.colKeys = Object.values(colKeys).sort(keySort($ctrl.rows));

			$ctrl.rowKeys = group(rows, $ctrl.colKeys);

			let blockSizeINC = -1; // remove '100' acost
			$ctrl.model.lanes.map(lane => (lane > 0 ? blockSizeINC++ : 0));

			$ctrl.blockSizeINC = blockSizeINC;
			$ctrl.blockSize = $ctrl.model.lanes.length - 2; // - acost
			$ctrl.blockSizeDEC = $ctrl.blockSize - blockSizeINC;

			$ctrl.series = [];
			for (let i = 0; i < $ctrl.rowKeys[0][0].data.length; i++) {
				$ctrl.data[i] = [];
				$ctrl.series[i] = `Faixa ${Math.abs($ctrl.rowKeys[0][0].data[i].lane)} (${
					$ctrl.rowKeys[0][0].data[i].lane < 0 ? 'Decrescente' : 'Crescente'
				})`;
				for (let j = 0; j < $ctrl.rowKeys.length; j++) {
					if (i == 0) {
						$ctrl.labels.push($ctrl.rowKeys[j][0].value);
					}

					$ctrl.rowKeys[j][0].data[i].minimum == -1
						? $ctrl.data[i].push({ x: $ctrl.rowKeys[j][0].value, y: undefined })
						: $ctrl.data[i].push({
								x: $ctrl.rowKeys[j][0].value,
								y: $ctrl.rowKeys[j][0].data[i].minimum
						  });
				}
			}
		}
	};

	const insertionSort = (array, rowData) => {
		if (Math.abs(rowData[3].value) != 100) {
			array.push({
				lane: rowData[3].value,
				firstAgeDcIntervention: rowData[4] ? rowData[4].value : undefined,
				firstAgeQiIntervention: rowData[5] ? rowData[5].value : undefined,
				firstAgeIggIntervention: rowData[6] ? rowData[6].value : undefined,
				firstAgeArrowIntervention: rowData[7] ? rowData[7].value : undefined,
				firstAgeTrIntervention: rowData[8] ? rowData[8].value : undefined,
				minimum: rowData[9] ? rowData[9].value : undefined
			});
		}
		for (let i = 0; i < array.length; i++) {
			for (let j = i + 1; j < array.length; j++) {
				if (array[i].lane > array[j].lane) {
					let aux = array[i];
					array[i] = array[j];
					array[j] = aux;
				}
			}
		}
		return array;
	};

	const group = (rowKeys, colKeys) => {
		for (let i = 0; i < rowKeys.length; i++) {
			rowKeys[i][0].data = [];
			for (let j = 0; j < colKeys.length; j++) {
				if (colKeys[j][0].value == rowKeys[i][0].value) {
					rowKeys[i][0].data = insertionSort(rowKeys[i][0].data, colKeys[j]);
				}
			}

			if (rowKeys[i][0].data.length != $ctrl.model.lanes.length - 2) {
				$ctrl.model.lanes.map(lane => {
					if (
						!rowKeys[i][0].data.find(entity => lane == entity.lane) &&
						Math.abs(lane) != 100
					) {
						rowKeys[i][0].data = insertionSort(rowKeys[i][0].data, [
							,
							,
							,
							{ value: lane }
						]);
					}
				});
			}
		}
		return rowKeys;
	};

	$ctrl.xAxis = {
		scaleLabel: {
			display: true,
			labelString: 'KM'
		}
	};

	$ctrl.yAxis = {
		ticks: {
			min: 0
		},
		scaleLabel: {
			display: true,
			labelString: 'Vida Remanescente Mínima'
		}
	};

	$ctrl.optionsChart = {
		elements: {
			line: {
				fill: false,
				tension: 0
			}
		},
		title: {
			display: true,
			text: ''
		},
		legend: {
			display: true
		},
		scales: {
			xAxes: [$ctrl.xAxis],
			yAxes: [$ctrl.yAxis]
		},
		animation: false
	};
}

const component = {
	templateUrl,
	controller,
	bindings: {
		model: '<',
		options: '<'
	}
};

app.component('wfLifespanTable', component);
