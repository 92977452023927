import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	var states = [
		stateHelperProvider.search({
			name: 'constant-set',
			component: 'constantSetSearch',
			parent: 'entity',
			data: {
				viewAuthorities: ['ENTITIES_READ', 'CONSTANTSET_READ'],
				editAuthorities: ['ENTITIES_WRITE', 'CONSTANTSET_WRITE']
			}
		}),
		{
			name: 'constant-set.detail',
			url: '/{id}',
			component: 'constantSetDetail',
			resolve: {
				/* @ngInject */
				entity: (ConstantSet, $transition$, entityInjector) =>
					entityInjector(ConstantSet, $transition$.params().id)
			}
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
