import app from '@/app';
import angular from 'angular';

/* @ngInject */
function Controller($ctrl, $state, $timeout, Principal) {
	$ctrl.transition = params => {
		$ctrl.readonly =
			($ctrl.parent && $ctrl.parent.readonly) ||
			($state.current.data.editAuthorities &&
				!Principal.hasAuthorities($state.current.data.editAuthorities));
	};

	$ctrl.$onInit = () => {
		$ctrl.transition($ctrl.$transition$.params());
	};

	$ctrl.uiOnParamsChanged = (value, $transition$) => {
		$ctrl.transition($transition$.params());
	};

	$timeout(() => angular.element('.form-group:first input:first').focus());
}

app.controller('AbstractController', Controller);
