import app from '@/app';
import templateUrl from './check-updated-dependencies-plan-button.html';

/* @ngInject */
function CheckUpdatedDependenciesButtonController(DialogService, $http, $translate) {
	const $ctrl = this;
	$ctrl.disableButton = true;
	$ctrl.loading = false;

	$ctrl.confirm = (message, planId) => {
		if (!$ctrl.readonly && planId && !$ctrl.loading) {
			DialogService.confirmCheckUpdatedDependencies(message).then(function(result) {
				// Alterado para DialogService.confirmMessage e aguardando a promessa
				if (result === true) {
					// Verificar se o usuário clicou em "Sim"

					$ctrl.loading = true; // Definir o estado de carregamento como verdadeiro

					$http
						.get('api/plans/UpdatedDependencies', { params: { planId: planId } })
						.then(function(response) {
							var items = response.data.updatedDependencies;
							var templateString = '';

							if (items.length > 0) {
								for (var i = 0; i < items.length; i++) {
									templateString += $translate.instant('name.' + items[i]) + ', ';
								}
								DialogService.alertMessage(
									'Dependências que foram atualizadas para o planejamento "' + response.data.name + '": ' +
										templateString.slice(0, -2) +
										'  '
								);
							} else {
								templateString = $translate.instant("updatedDependencies.nullListDependencies");
								DialogService.alertMessage(
									templateString
								);
							}
						})
						.finally(function() {
							$ctrl.loading = false; // Limpar o estado de carregamento, independentemente do resultado
						});
				}
			});
		}
	};
}

app.component('prCheckUpdatedDependenciesPlan', {
	templateUrl,
	controller: CheckUpdatedDependenciesButtonController,
	bindings: {
		type: '@',
		named: '<',
		count: '<',
		state: '@',
		params: '<',
		readonly: '<',
		onClick: '&',
		message: '@',
		plan: '@'
	}
});
