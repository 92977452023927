import app from '@/app';

/* @ngInject */
function SettingsController(Principal, Auth, JhiLanguageService, $translate) {
	const $ctrl = this;

	$ctrl.error = null;
	$ctrl.settingsAccount = null;
	$ctrl.success = null;

	const copyAccount = account => ({
		activated: account.activated,
		email: account.email,
		name: account.name,
		language: account.language,
		login: account.login
	});

	Principal.identity().then(account => ($ctrl.settingsAccount = copyAccount(account)));

	$ctrl.save = () => {
		$ctrl.isSaving = true;
		Auth.updateAccount($ctrl.settingsAccount)
			.then(() => {
				$ctrl.error = null;
				$ctrl.success = 'OK';
				Principal.identity(true).then(
					account => ($ctrl.settingsAccount = copyAccount(account))
				);
				JhiLanguageService.getCurrent().then(current => {
					if ($ctrl.settingsAccount.language !== current) {
						$translate.use($ctrl.settingsAccount.language);
					}
				});
			})
			.catch(() => {
				$ctrl.success = null;
				$ctrl.error = 'ERROR';
			});
	};
}

app.controller('SettingsController', SettingsController);
