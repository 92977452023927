import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	var states = [
		stateHelperProvider.search({
			name: 'campaign',
			parent: 'entity',
			data: {
				viewAuthorities: ['ENTITIES_READ', 'CAMPAIGN_READ'],
				editAuthorities: ['ENTITIES_WRITE', 'CAMPAIGN_WRITE']
			},
			resolve: {
				/* @ngInject */
				allOrganizations: Organization => Organization.query({ sort: 'name,asc' }).$promise
			}
		}),
		{
			name: 'campaign.detail',
			url: '/{id}',
			component: 'campaignDetail',
			resolve: {
				/* @ngInject */
				entity: (Campaign, $transition$, entityInjector) =>
					entityInjector(Campaign, $transition$.params().id)
			}
		}
	];

	states.forEach(function(state) {
		$stateProvider.state(state);
	});
}

app.config(stateConfig);
