import app from '@/app';

var component = {
	templateUrl: '/app/components/search-bar-road/search-bar-road.html',
	bindings: {
		query: '<',
		onClick: '&'
	}
};

app.component('wfSearchBarRoad', component);
