import app from '@/app';
import angular from 'angular';

/* @ngInject */
function stateHandler(
	$transitions,
	$rootScope,
	$translate,
	JhiLanguageService,
	Auth,
	Principal,
	VERSION
) {
	return {
		initialize: () => {
			$rootScope.VERSION = VERSION;

			$transitions.onStart({}, trans => {
				$rootScope.toState = trans.to();
				$rootScope.fromState = trans.from();

				if (Principal.isAuthenticated()) {
					Auth.authorize();
				}

				JhiLanguageService.getCurrent().then(language => $translate.use(language));
			});

			$rootScope.$on('$destroy', () => {
				if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
					stateChangeStart();
				}
				if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
					stateChangeSuccess();
				}
			});
		}
	};
}

app.factory('stateHandler', stateHandler);
