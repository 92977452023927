import app from '@/app';
import templateUrl from './duplicate-button.html';


/* @ngInject */
function DuplicateButtonController(DialogService) {
	const $ctrl = this;
  
	$ctrl.confirm = (message) => {
		if (!$ctrl.readonly) {   
			DialogService.alertMessage(message);
		}
	};
}




app.component('prDuplicateButton', {
	templateUrl,
  controller: DuplicateButtonController,
	bindings: {
    type: '@',
		named: '<',
		count: '<',
		state: '@',
		params: '<',
    readonly: '<',
    onClick: '&',
    message: '@'
	}
});
