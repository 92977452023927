import app from '@/app';
import templateUrl from './asBuilt-search.html';

/* @ngInject */
function AsBuiltSearchController($controller, $state, $timeout, $uibModal, Upload, $http, Principal) {
	var $ctrl = this;
	$controller('AbstractController', { $ctrl });

	const transition = $ctrl.transition;

  $ctrl.currentAccount = null;

  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	$ctrl.transition = params => {
		transition(params);

		$ctrl.organization = $ctrl.allOrganizations.content.find(
			it => it.id == params.organization
		);
		$ctrl.road = $ctrl.allRoads.content.find(it => {
			return it.id == params.road && it.roadtype == params.roadtype;
		});

		if ($ctrl.road) {
			$ctrl.headMilestone = _.defaultTo(
				_.defaultTo(null, $ctrl.road.headMilestone),
				parseFloat(params.headMilestone)
			);
			$ctrl.tailMilestone = _.defaultTo(
				_.defaultTo(null, $ctrl.road.tailMilestone),
				parseFloat(params.tailMilestone)
			);

			$ctrl.lanes = _.flow(_.keyBy(_.identity), _.at(params.lanes))($ctrl.road.lanes);
		}
		$ctrl.direction = params.direction;
		$ctrl.output = params.output;
		$ctrl.allMeasurements = $ctrl.allMeasurements.filter(it => it.code == 'UNIFILARWORKS');
		$ctrl.measurement = $ctrl.allMeasurements.find(it => it.code == params.measurement);
		$ctrl.metric = params.metric;
		$ctrl.roadtype = params.roadtype;
		$ctrl.startDate = params.startDate;
		$ctrl.segmentLength = params.segmentLength;
		$ctrl.endDate = params.endDate;
	};

	$ctrl.upload = files => {
		if (!$ctrl.readonly && files && files.length) {
			$uibModal
				.open({
					component: 'asBuiltImportDialog',
					size: 'md',
					resolve: {
						measurement: () => $ctrl.measurement,
						files: () => files
					}
				})
				.result.then(() => $state.reload());
		}
	};

	$ctrl.onOrganizationChange = organization => {
		$ctrl.roadtype = null;
		$ctrl.road = null;
		$ctrl.lanes = [];
		$ctrl.headMilestone = null;
		$ctrl.tailMilestone = null;
		$ctrl.campaigns = [];
	};

	$ctrl.onRoadTypeChange = roadtype => {
		$ctrl.road = null;
		$ctrl.lanes = [];
		$ctrl.headMilestone = null;
		$ctrl.tailMilestone = null;
		$ctrl.campaigns = [];
	};

	$ctrl.byOrganization = value =>
		$ctrl.organization && value.organization.id == $ctrl.organization.id;

	$ctrl.byOwner = value => $ctrl.organization && value.owner.id == $ctrl.organization.id;

	$ctrl.onRoadSelected = road => {
		$ctrl.lanes = [];
		$ctrl.headMilestone = road.headMilestone;
		$ctrl.tailMilestone = road.tailMilestone;
	};

	$ctrl.onQueryChange = function() {
		var state = 'asBuilt.' + $ctrl.output;
		$state.go(
			state,
			{
				page: 0,
				organization: $ctrl.organization.id,
				road: $ctrl.road.id,
				headMilestone: $ctrl.headMilestone,
				startDate: $ctrl.startDate,
				segmentLength: $ctrl.segmentLength,
				endDate: $ctrl.endDate,
				roadtype: $ctrl.roadtype,
				tailMilestone: $ctrl.tailMilestone,
				direction: $ctrl.direction,
				lanes: $ctrl.lanes,
				output: $ctrl.output,
				measurement: "BUILT",
				metric: $ctrl.metric
			},
			{
				reload: true
			}
		);
	};
}

const component = {
	templateUrl,
	controller: AsBuiltSearchController,
	bindings: {
		allResources: '<', // move to measurement service
		allOrganizations: '<',
		allRoads: '<',
		allMeasurements: '<',
		allMetrics: '<',
		allMetricLimits: '<',
		$transition$: '<'
	}
};

app.component('asBuiltSearch', component);
