import app from '@/app';
import templateUrl from './measurement-search.html';

/* @ngInject */
function MeasurementSearchController($controller, $state, $timeout, $uibModal, Upload, $http, Principal, DialogService) {
  var $ctrl = this;
  $controller('AbstractController', { $ctrl });

  const transition = $ctrl.transition;

  $ctrl.currentAccount = null;

  $ctrl.filters = [{ measurement: null, metric: null }];

  const mapMetrics = ['d0', 'd20', 'd30', 'd45', 'd60', 'd90', 'd120', 'force', 'airTemperature', 'pavementTemperature', 'radius', 'iriAverage', 'qiAverage', 'innerFl', 'outerFl', 'atrAverage', 'igg', 'density', 'hrb', 'mct', 'moisture', 'll', 'lp', 'ip', 'granulometryN40', 'granulometryN10', 'granulometryN40', 'granulometryN200', 'max', 'hot', 'exp', 'cbr', 'fc1', 'fc2', 'fc3', 'pothole', 'sinking', 'others', 'lightVehicleDailyAverage', 'heavyVehicleDailyAverage', 'usaceVehicleFactor', 'aashtoVehicleFactor', 'hs', 'vrd', 'macrotextureMeasure', 'microtextureMeasure', 'fr60', 'ifiSp', 'ifiF60', 'urci', 'layer1thickness', 'layer2thickness', 'layer3thickness', 'layer4thickness', 'layer1Material', 'layer2Material', 'layer3Material', 'layer4Material', 'speed', 'noise', 'icp'];


  Principal.identity().then(account => {
    $ctrl.currentAccount = account;
  })

  $ctrl.transition = params => {
    transition(params);

    $ctrl.organization = $ctrl.allOrganizations.content.find(
      it => it.id == params.organization
    );
    $ctrl.road = $ctrl.allRoads.content.find(it => {
      return it.id == params.road && it.roadtype == params.roadtype;
    });

    if ($ctrl.road) {
      $ctrl.headMilestone = _.defaultTo(
        _.defaultTo(null, $ctrl.road.headMilestone),
        parseFloat(params.headMilestone)
      );
      $ctrl.tailMilestone = _.defaultTo(
        _.defaultTo(null, $ctrl.road.tailMilestone),
        parseFloat(params.tailMilestone)
      );

      $ctrl.lanes = _.flow(_.keyBy(_.identity), _.at(params.lanes))($ctrl.road.lanes);
    }
    $ctrl.direction = params.direction;
    $ctrl.campaigns = _.flow(
      _.keyBy('id'),
      _.at(params.campaigns || [])
    )($ctrl.allCampaigns.content);
    $ctrl.output = params.output;
    $ctrl.roadtype = params.roadtype;
    if (params.filters) {
      $ctrl.filters = [];
      angular.forEach(JSON.parse(params.filters), function (item, key) {

        var filter = {
          measurement: $ctrl.allMeasurements.find(it => it.code == item.measurement.code),
          metric: item.metric
        };

        $ctrl.filters.push(filter);
      })
    }

  };

  $ctrl.upload = files => {
    if (!$ctrl.readonly && files && files.length) {
      $uibModal
        .open({
          component: 'measurementImportDialog',
          size: 'md',
          resolve: {
            measurement: () => $ctrl.measurement,
            files: () => files
          }
        })
        .result.then(() => $state.reload());
    }
  };


  $ctrl.onOrganizationChange = organization => {
    $ctrl.roadtype = null;
    $ctrl.road = null;
    $ctrl.lanes = [];
    $ctrl.headMilestone = null;
    $ctrl.tailMilestone = null;
    $ctrl.campaigns = [];
  };

  $ctrl.onRoadTypeChange = roadtype => {
    $ctrl.road = null;
    $ctrl.lanes = [];
    $ctrl.headMilestone = null;
    $ctrl.tailMilestone = null;
    $ctrl.campaigns = [];
  };

  $ctrl.byOrganization = value =>
    $ctrl.organization && value.organization.id == $ctrl.organization.id;

  $ctrl.byOwner = value => $ctrl.organization && value.owner.id == $ctrl.organization.id;

  $ctrl.onRoadSelected = road => {
    $ctrl.lanes = [];
    $ctrl.headMilestone = road.headMilestone;
    $ctrl.tailMilestone = road.tailMilestone;
  };

  $ctrl.filterAllMetrics = (item) => {
    if (item.measurement) {
      switch ($ctrl.output) {
        case 'table':
          return [];
        case 'line-chart':
          return $ctrl.allMetrics.filter(metric => item.measurement.code == metric.measurement);
        case 'pie-chart':
          if (item.measurement && $ctrl.allMetricLimits.$resolved) {
            return $ctrl.allMetrics.filter(metric =>
              item.measurement.code == metric.measurement &&
              $ctrl.allMetricLimits.content
                .filter(item => item.organization.id == $ctrl.organization.id)
                .some(limit => metric.code == limit.metric)
            );
          }
        case 'map':
		  var result = $ctrl.allMetrics.filter(metric => item.measurement.code == metric.measurement &&  (mapMetrics.indexOf(metric.name) !== -1));
		  if(item.measurement.code == "IRI"){
				return result.filter(m => m.code != "IRI_SPEED");
		  }else{
			return result;
		  }
          ;
      }
    }
  };

  $ctrl.onQueryChange = function () {
    var state = 'measurement.' + $ctrl.output;
    $state.go(
      state,
      {
        page: 0,
        organization: $ctrl.organization.id,
        road: $ctrl.road.id,
        headMilestone: $ctrl.headMilestone,
        roadtype: $ctrl.roadtype,
        tailMilestone: $ctrl.tailMilestone,
        campaigns: _.map('id')($ctrl.campaigns),
        direction: $ctrl.direction,
        lanes: $ctrl.lanes,
        output: $ctrl.output,
        filters: JSON.stringify($ctrl.filters),
        //For loading map
        measurement: $ctrl.filters[0].measurement.code,
        metric: $ctrl.filters[0].metric
      },
      {
        reload: true
      }
    );
  };

  $ctrl.addFilter = function () {
    if ($ctrl.filters.length == 5) {
	  DialogService.alertMessage("Limite de filtros: 5");
      return;
    }
    $ctrl.filters.push({ measurement: null, metric: null });
  };

  $ctrl.removeFilter = function (index) {
    $ctrl.filters.splice(index, 1);
  };


}

const component = {
  templateUrl,
  controller: MeasurementSearchController,
  bindings: {
    allResources: '<', // move to measurement service
    allOrganizations: '<',
    allRoads: '<',
    allCampaigns: '<',
    allMeasurements: '<',
    allMetrics: '<',
    allMetricLimits: '<',
    $transition$: '<'
  }
};

app.component('measurementSearch', component);
