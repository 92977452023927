import app from '@/app';

function directive() {
	return {
		restrict: 'A',
		require: 'ngModel',
		scope: {
			factor: '&wfPercent'
		},
		link: function(scope, element, attrs, ngModel) {
			if (scope.factor() != 1) {
				ngModel.$formatters.push(value => value * (scope.factor() || 100));
				ngModel.$parsers.push(value => value / (scope.factor() || 100));
			}
		}
	};
}

app.directive('wfPercent', directive);
