import app from '@/app';
import templateUrl from './plan-overview-wrapper.html';
import PlanOverview from './plan-overview';
import { react2angular } from 'react2angular';

app.component('prPlanOverview', react2angular(PlanOverview, ['planId', 'overviewId']));

/* @ngInject */
function PlanOverviewController($controller, $scope) {
	var $ctrl = this;
	$controller('AbstractController', { $ctrl });

	$ctrl.transition = params => {
		$ctrl.planId = params.planId;
		$ctrl.overviewId = params.overviewId;
	};
}

const component = {
	templateUrl,
	controller: PlanOverviewController,
	bindings: {
		$transition$: '<'
	}
};

app.component('planOverviewWrapper', component);
