import app from '@/app';
import templateUrl from './unifilarParametersComments-detail.html';

/* @ngInject */
function Controller(
	$controller,
	UnifilarParametersComments,
	$http,
	$timeout,
	AlertService,
	$state
) {
	const $ctrl = this;
	const resource = UnifilarParametersComments;
	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: UnifilarParametersComments,
		type: 'unifilarParametersComments'
	});

	var transition = $ctrl.transition;
	$ctrl.transition = params => {
		transition(params);

		if (!$ctrl.entity) {
			$http.get('api/plans/' + params.id + '/segments', {}).then(function(response) {
				$ctrl.entity = response.data;
				$ctrl.params.id = params.id;
			});
		}
	};

	$ctrl.save = () => {
		$http
			.put('api/plans/' + $ctrl.state.params.id + '/segments', $ctrl.entity)
			.then(function(response) {
				if (response.status == 200) {
					AlertService.success('entity.update.message', {
						type: 'segments',
						name: 'Observações'
					});
					$ctrl.isSaving = false;
					$state.go('^', {}, { reload: true });
				} else {
					$ctrl.isSaving = false;
				}
			});
	};
}
const component = {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^unifilarParametersResult'
	},
	bindings: {
		$transition$: '<'
	}
};

app.component('unifilarParametersEditComments', component);
