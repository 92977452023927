import app from '@/app';
import templateUrl from './growth-rate-detail.html';

/* @ngInject */
function Controller($controller, $timeout, GrowthRate, Principal) {

  const $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: GrowthRate,
		type: 'growthRate'
	});

  $ctrl.incorrectTailDate = false;

  this.$onInit = function() {
    if($ctrl.entity.items.length > 0){
      for(var item of $ctrl.entity.items){
        item.readonlyHeadDate = true;
        item.readonlyTailDate = true;
      }
      var lastIndex = $ctrl.entity.items.length - 1;
      var lastItem = $ctrl.entity.items[lastIndex];
      if($ctrl.entity.items.length == 1){
        lastItem.readonlyHeadDate = false;
      }

      lastItem.readonlyTailDate = false;
      
    }
  };


    $ctrl.checkDateValid = (tailDate, headDate) => {
      if(headDate && tailDate && headDate > tailDate){
        $ctrl.incorrectTailDate = true;
      }else{
        $ctrl.incorrectTailDate = false;
      }
      }
  
  $ctrl.idgen = 1;
  $ctrl.addItem = () => {
    var i = $ctrl.entity.items.length -1;
    var item = $ctrl.entity.items[i];

    if($ctrl.entity.items.length == 0 
        || item.tailDate){
      if($ctrl.entity.items.length == 0){
        $ctrl.entity.items.push({ id: --$ctrl.idgen, 
          readonlyHeadDate: false, readonlyTailDate: false });
      }else if(item.tailDate && item.rate && item.tailDate >= item.headDate){
        $ctrl.incorrectTailDate = false;
        item.readonlyHeadDate = true;
        item.readonlyTailDate = true;
        //Tratamento para inserir o primeiro ano da linha subsequente com + 1 obrigatoriamente
        $ctrl.entity.items.splice(i, 1, item);
        var ano = item.tailDate.getFullYear();
        var mes = item.tailDate.getMonth();
        var data = new Date(ano + 1, mes);
        $ctrl.entity.items.push({ id: --$ctrl.idgen, 
          headDate: data, readonlyHeadDate: true, readonlyTailDate: false});
      }else if(item.tailDate <= item.headDate){
        $ctrl.incorrectTailDate = true;
      }
    }
    
    
  };

  $ctrl.removeItem = id => {
		var i = $ctrl.entity.items.length -2;
    var item = $ctrl.entity.items[i];
    item.readonlyHeadDate = false;
    item.readonlyTailDate = false;
    $ctrl.entity.items.splice(i, 1, item);
    $ctrl.entity.items = $ctrl.entity.items.filter(it => it.id != id);
		$ctrl.dirtyItems();
		$ctrl.items = true;
	};
}

var component = {
	templateUrl,
	controller: Controller,
  require: {
		parent: '^growthRateSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<'
	}
};

app.component('growthRateDetail', component);