import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	var states = [
		stateHelperProvider.search({
			name: 'criteria-set',
			component: 'criteriaSetSearch',
			parent: 'entity',
			data: {
				viewAuthorities: ['ENTITIES_READ', 'CRITERIASET_READ'],
				editAuthorities: ['ENTITIES_WRITE', 'CRITERIASET_WRITE']
			}
		}),
		{
			name: 'criteria-set.detail',
			url: '/{id}',
			component: 'criteriaSetDetail',
			resolve: {
				/* @ngInject */
				entity: (CriteriaSet, $transition$, entityInjector) =>
					entityInjector(CriteriaSet, $transition$.params().id)
			}
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
