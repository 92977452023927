import app from '@/app';
import angular from 'angular';

var component = {
	templateUrl: '/app/components/modal/modal-dialog.html',
	controller: Controller,
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	}
};

/* @ngInject */
function Controller($modalInstance, date) {
	var $ctrl = this;

	$ctrl.rememberMe = true;

	$ctrl.cancel = function() {
		$modalInstance.close();
		$ctrl.dismiss({ $value: 'cancel' });
	};

	$ctrl.save = function() {
		$ctrl.cancel();
		date.iqdCalculationSave();
	};
}

app.component('modalDialog', component);
