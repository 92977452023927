import app from '@/app';
import angular from 'angular';

/* @ngInject */
function controller($controller, $ctrl, $state, resource, type, $timeout, AlertService) {
	$controller('AbstractController', { $ctrl });

	$ctrl.state = $state;
	$ctrl.idgen = -1;
	$ctrl.type = resource.type || type;

	$ctrl.setDirty = function(form) {
		$timeout(() => form.$setDirty());
	};

	$ctrl.save = () => {
		if (!$ctrl.readonly) {
			$ctrl.isSaving = true;
			const onSuccess = () => {
				AlertService.success('entity.update.message', {
					type: $ctrl.type,
					name: $ctrl.entity.name
				});
				$ctrl.isSaving = false;
				$state.go('^', {}, { reload: true });
			};

			const onError = error => {
				$ctrl.isSaving = false;
			};
			if ($ctrl.entity.id) {
				resource.update($ctrl.entity, onSuccess, onError);
			} else {
				resource.create($ctrl.entity, onSuccess, onError);
			}
		}
	};

	$ctrl.addChild = function(name, field, form) {
		$ctrl['load' + name] = function(id) {
			return angular.extend(
				{},
				$ctrl.entity[field].find(it => it.id == parseInt(id)) || { id: $ctrl.idgen-- }
			);
		};

		$ctrl['remove' + name] = function(id) {
			$ctrl.entity[field] = $ctrl.entity[field].filter(it => it.id !== id);
			$ctrl.setDirty($ctrl[form][field]);
		};

		$ctrl['save' + name] = function(child) {
			$ctrl.entity[field] = $ctrl.entity[field].filter(it => it.id != child.id).concat(child);
			$ctrl.setDirty($ctrl[form][field]);
		};
	};

	$timeout(function() {
		angular.element('.form-group:first input:first').focus();
	});
}

app.controller('AbstractEntityDetailController', controller);
