import app from '@/app';

/* @ngInject */
function translationConfig($translateProvider, tmhDynamicLocaleProvider) {
	$translateProvider.useLoader('$translatePartialLoader', {
		urlTemplate: 'i18n/{lang}/{part}.json'
	});
	$translateProvider.preferredLanguage('pt-br');
	$translateProvider.useStorage('translationStorageProvider');
	$translateProvider.useSanitizeValueStrategy('escaped');
	$translateProvider.addInterpolation('$translateMessageFormatInterpolation');

	tmhDynamicLocaleProvider.localeLocationPattern('i18n/angular-locale_{{locale}}.js');
	tmhDynamicLocaleProvider.useCookieStorage();
	tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');
}

app.config(translationConfig);
