import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	const states = [
		stateHelperProvider.search({
			name: 'role',
			parent: 'system',
			data: {
				viewAuthorities: ['ROLE_READ'],
				editAuthorities: ['ROLE_WRITE']
			}
		}),
		{
			name: 'role.detail',
			url: '/{id}',
			component: 'roleDetail',
			resolve: {
				/* @ngInject */
				entity: (Role, $transition$) => Role.inject($transition$.params().id)
			}
		}
	];
	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
