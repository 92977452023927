import app from '@/app';
import templateUrl from './growth-rate-result.html';

/* @ngInject */
function controller($controller, GrowthRate, Principal) {
	var $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractController', { 
    $ctrl: $ctrl, 
  });
	
}

const component = {
	templateUrl,
	controller,
	bindings: {
		$transition$: '<',
		entity: '<'
	}
};

app.component('growthRateResult', component);