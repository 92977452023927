import app from '@/app';
import templateUrl from './system-parameters-detail.html';

/* @ngInject */
function Controller($controller, SystemParameters, Principal) {

  const $ctrl = this;

  $ctrl.currentAccount = null;

  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: SystemParameters,
		type: 'systemParameters'
	});

}

var component = {
	templateUrl,
	controller: Controller,
  require: {
		parent: '^systemParametersSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<',
    allParametersVersions: '<'
	}
};

app.component('systemParametersDetail', component);