import app from '@/app';

app.directive('hasAuthority', hasAuthority);

/* @ngInject */
function hasAuthority(Principal) {
	function linkFunc(scope, element, attrs) {
		var authorities = attrs.hasAuthority.replace(/\s+/g, '').split(',');

		const setVisible = visible => {
			if (visible) {
				element.removeClass('hidden');
			} else {
				element.addClass('hidden');
			}
		};

		const defineVisibility = reset => {
			if (reset) {
				setVisible(true);
			}
			setVisible(Principal.hasAuthorities(authorities));
		};

		if (authorities.length > 0) {
			defineVisibility(true);

			scope.$watch(
				() => Principal.isAuthenticated(),
				() => defineVisibility(true)
			);
		}
	}

	const directive = {
		restrict: 'A',
		link: linkFunc
	};

	return directive;
}
