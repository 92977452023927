import app from '@/app';
import templateUrl from './scenario-analysis-search.html';

/* @ngInject */
function scenarioAnalysisSearchController($controller, $scope, $state, DialogService, IndexCrossing, $http, Principal) {
	var $ctrl = this;
	var resource = IndexCrossing;

	$ctrl.currentAccount = null;

	Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractPageSortController', { $ctrl: $ctrl });

	var transition = $ctrl.transition;

	$ctrl.transition = params => {
		transition(params);
		$ctrl.road = $ctrl.allRoads.content.find(it => it.id == params.road);
		$ctrl.campaigns = _.flow(
			_.keyBy('id'),
			_.at(params.campaigns || [])
		)($ctrl.allCampaigns.content);
		resource.query(
			{
				plantype: 'INDEX_CROSSING',
				road: params.road,
				campaign: params.campaign,
				sort: params.sort,
				page: $ctrl.page,
				size: $ctrl.size
			},
			data => {
				$ctrl.entities = data.content;
				$ctrl.count = data.totalElements;
			}
		);
		$http.get('api/projects', { params: { onlyUsed: true } }).then(function(response) {
			$ctrl.allProjects = response;
		});
	};

	$ctrl.onRoadTypeChange = roadtype => {
		$ctrl.road = null;
		$ctrl.campaigns = [];
		$ctrl.campaign = null;
	};

	$ctrl.onOrganizationChange = organization => {
		$ctrl.road = null;
		$ctrl.campaigns = [];
		$ctrl.campaign = null;
	};

	$ctrl.byOrganization = value =>
		$ctrl.organization && value.organization.id == $ctrl.organization.id;

	$ctrl.byOwner = value => $ctrl.organization && value.owner.id == $ctrl.organization.id;

	$ctrl.onQueryChange = function() {
		var pCampaign = $ctrl.campaign && $ctrl.campaign.id ? $ctrl.campaign.id : null;
		var pRoad = $ctrl.road && $ctrl.road.id ? $ctrl.road.id : null;
		var pProject = $ctrl.project && $ctrl.project.id ? $ctrl.project.id : null;
		var pSituationPlan = $ctrl.situationPlan;

		resource.query(
			{
				organization: $ctrl.organization.id,
				plantype: 'INDEX_CROSSING',
				road: pRoad,
				roadtype: $ctrl.roadtype,
				campaign: pCampaign,
				sort: $ctrl.sort,
				page: 0,
				size: $ctrl.size,
				project: pProject,
				situationPlan: pSituationPlan
			},
			data => {
				$ctrl.entities = data.content;
				$ctrl.count = data.totalElements;
			}
		);
	};

	$ctrl.clearUiSelect = function($event, $select, field) {
		$event.stopPropagation();
		$select.selected = null;
		$select.search = null;

		field = null;
	};

	$ctrl.remove = (id, name) => resource.remove({ id: id }, () => $state.reload()).$promise;
}

app.component('scenarioAnalysisSearch', {
	templateUrl,
	controller: scenarioAnalysisSearchController,
	bindings: {
		$transition$: '<',
		allOrganizations: '<',
		allRoads: '<',
		allCampaigns: '<',
		allDecisionTrees: '<',
		allCostTables: '<',
		allGrowthRates: '<'
	}
});
