import app from '@/app';
import templateUrl from './plan-detail.html';

/* @ngInject */
function Controller($controller, Plan, ConstantSet, BudgetLimit, GrowthRate, Principal, Project, $http) {
	var $ctrl = this;

  $ctrl.currentAccount = null;

  $ctrl.allRoadtype = ["ROAD", "STRETCH", "RIVER"];

  $ctrl.allRateType = ["FIXED", "VARIABLE"];

  $ctrl.projectType = "SELECT";

  var projectDefault = {"id":null,"name":null, "status":"SELECT"};

    	$ctrl.onProjectChange = () => {
        switch ($ctrl.projectType) {
          case 'NEW':
              $ctrl.entity.project.name = null;
              $ctrl.entity.project.id = null;
              break;
          case 'SELECT':
              $ctrl.entity.project.name = null;
              $ctrl.entity.project.id = null;
              break;
          case 'OLD':
              $ctrl.entity.project = null;
              break;
          default:
        }

      };


  Principal.identity().then(account => {
    $ctrl.currentAccount = account;
  })

	$controller('AbstractEntityDetailController', { $ctrl, resource: Plan, type: 'plan' });



  this.$onInit = function() {
    $http.get('api/projects', { params: {onlyUsed : false} }).then(function(response) {
      $ctrl.allProjects = response;
    })

    if($ctrl.entity.project == null){
      $ctrl.entity.project = projectDefault;
    }

    $http.get('api/budgetlimits?query=null').then(function(response) {
      $ctrl.allBudgetLimit = response;
    })

  }

	$ctrl.planSave = () => {
		$ctrl.entity.plantype = 'DEFAULT';
		$ctrl.save();
	};

	$ctrl.onRoadSelected = road => {
		$ctrl.entity.road = road;
		$ctrl.entity.headMilestone = road.headMilestone;
		$ctrl.entity.tailMilestone = road.tailMilestone;
	};

	$ctrl.onOrganizationChange = organization => {
		$ctrl.entity.road = null;
		$ctrl.entity.headMilestone = null;
		$ctrl.entity.tailMilestone = null;
		$ctrl.entity.campaigns = null;
	};

	$ctrl.onRoadTypeChange = roadtype => {
		$ctrl.entity.road = null;
		$ctrl.entity.headMilestone = null;
		$ctrl.entity.tailMilestone = null;
		$ctrl.entity.campaigns = null;
	};

	$ctrl.queryConstantSets = query => {
		$ctrl.loading = true;
		return ConstantSet.query({ query: query, size: 10, sort: 'name,asc' }, data => {
			$ctrl.allConstantSets = data;
			$ctrl.loading = false;
		}).$promise;
	};

  $ctrl.queryGrowthRates = query => {
		$ctrl.loading = true;
		return GrowthRate.query({ query: query, size: 10, sort: 'name,asc' }, data => {
			$ctrl.allGrowthRates = data;
			$ctrl.loading = false;
		}).$promise;
	};

  
  $ctrl.queryBudgetLimit = query => {
    if(!query){
        query = "";
    }
		$ctrl.loading = true;
		return BudgetLimit.query({ query: query, size: 10, sort: 'name,asc' }, data => {
			$ctrl.allBudgetLimit = data;
			$ctrl.loading = false;
		}).$promise;
	};

	$ctrl.byOrganization = value =>
		$ctrl.entity.organization && value.organization.id == $ctrl.entity.organization.id;

	$ctrl.byOwner = value =>
		$ctrl.entity.organization && value.owner.id == $ctrl.entity.organization.id;
    
    $ctrl.clearUiSelect = function($event, $select) {
      $event.stopPropagation();
      $select.selected = null;
      $select.search = null;
      $ctrl.entity.budgetLimit= null;
  
    };

}

app.component('planDetail', {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^planSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<',
    idGrowthRate: '<'
	}
});
