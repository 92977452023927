import app from '@/app';

/* @ngInject */
function Resource($resource) {
	return $resource(
		'api/solutionhistorics/:version/:idplan',
		{ idplan: '@idplan',
      version: '@version'},
		{
			query: { method: 'GET' }
		}
	);
}

app.factory('SolutionHistoric', Resource);



