import app from '@/app';
import templateUrl from './pavementPerformance-search.html';

/* @ngInject */
function PavementPerformanceSearchController($controller, $state, PavementPerformance, Principal) {
	var $ctrl = this;
	var resource = PavementPerformance;
	$controller('AbstractPageSortController', { $ctrl: $ctrl });

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	var transition = $ctrl.transition;

	$ctrl.transition = params => {
		transition(params);
		$ctrl.road = $ctrl.allRoads.content.find(it => it.id == params.road);
		$ctrl.campaigns = _.flow(
			_.keyBy('id'),
			_.at(params.campaigns || [])
		)($ctrl.allCampaigns.content);
		resource.query(
			{
				plantype: 'PAVEMENTPERFORMANCE',
				road: params.road,
				campaign: params.campaign,
				sort: params.sort,
				page: params.page,
				size: params.size
			},
			data => {
				$ctrl.entities = data.content;
				$ctrl.count = data.totalElements;
			}
		);
	};

	$ctrl.onOrganizationChange = organization => {
		$ctrl.road = null;
		$ctrl.campaigns = [];
	};

	$ctrl.onRoadTypeChange = roadtype => {
		$ctrl.road = null;
		$ctrl.campaigns = [];
	};

	$ctrl.byOrganization = value =>
		$ctrl.organization && value.organization.id == $ctrl.organization.id;

	$ctrl.byOwner = value => $ctrl.organization && value.owner.id == $ctrl.organization.id;

	$ctrl.onQueryChange = () => {
		var pCampaign = $ctrl.campaign && $ctrl.campaign.id ? $ctrl.campaign.id : null;
		var pRoad = $ctrl.road && $ctrl.road.id ? $ctrl.road.id : null;
    var pOrganization = $ctrl.organization && $ctrl.organization.id ? $ctrl.organization.id : null;

		resource.query(
			{
				plantype: 'PAVEMENTPERFORMANCE',
        organization: pOrganization,
				road: pRoad,
				roadtype: $ctrl.roadtype,
				campaign: pCampaign,
				sort: $ctrl.sort,
				page: 0,
				size: $ctrl.size
			},
			data => {
				$ctrl.entities = data.content;
				$ctrl.count = data.totalElements;
			}
		);
	};

	$ctrl.remove = (id, name) => resource.remove({ id: id }, () => $state.reload()).$promise;
}

app.component('pavementPerformanceSearch', {
	templateUrl,
	controller: PavementPerformanceSearchController,
	bindings: {
		$transition$: '<',
		allOrganizations: '<',
		allRoads: '<',
		allCampaigns: '<',
		allDecisionTrees: '<',
		allCostTables: '<',
		allCriteriaSets: '<'
	}
});
