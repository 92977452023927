import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	var states = [
		stateHelperProvider.search({
			name: 'system-parameters',
			component: 'systemParametersSearch',
			parent: 'entity',
			data: {
				viewAuthorities: ['ENTITIES_READ'],
				editAuthorities: ['ENTITIES_WRITE']
			}
		}),
		{
			name: 'system-parameters.detail',
			url: '/{id}',
			component: 'systemParametersDetail',
			resolve: {
        /* @ngInject */
        entity: (SystemParameters, $transition$, entityInjector) =>
        entityInjector(SystemParameters, $transition$.params().id),
        /* @ngInject */
        allParametersVersions: ParametersVersions => ParametersVersions.query({query: ''}).$promise
      }
		},
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);