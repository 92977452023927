import app from '@/app';
import templateUrl from './iqdCalculation-detail.html';

/* @ngInject */
function Controller($controller, IqdCalculation, Road, Plan, ModalService, Principal) {
	var $ctrl = this;
  
  $ctrl.currentAccount = null;
    
  Principal.identity().then(account => {
    $ctrl.currentAccount = account;
    })

	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: IqdCalculation,
		type: 'iqdCalculation'
	});

	$ctrl.modal = () => {
		ModalService.open($ctrl);
	};

	$ctrl.iqdCalculationSave = () => {
		$ctrl.entity.iqdtype = 'DEFAULT';
		$ctrl.save();
	};

	$ctrl.onOrganizationChange = organization => {
		organization
			? Road.query({ sort: 'name,asc', organization: organization.id }, data => {
					$ctrl.allRoads = data.content;
					$ctrl.loading = false;
					let items = [];

					for (let i = 0; i < $ctrl.allRoads.length; i++) {
            
            var plansFind = getPlan($ctrl.allRoads[i].id, $ctrl.allPlans);

						if ($ctrl.entity.items.length > 0) {
							let roadPlan = $ctrl.entity.items.find(
								it => it.road.id == $ctrl.allRoads[i].id
							);
							let selected = roadPlan ? roadPlan.plan : null;

              items.push({
                road: $ctrl.allRoads[i],
                unifilar: { data: plansFind, selected: selected }
              }).$promise;
								
                
						} else {

                  items.push({
                    road: $ctrl.allRoads[i],
                    unifilar: { data: plansFind, selected: null }
                  }).$promise;
						}
					}
					$ctrl.items = items;
			  }).$promise
			: '';
	};

	$ctrl.clearUiSelect = function($event, $select, item) {
		$event.stopPropagation();
		$select.selected = null;
		$select.search = null;

		$ctrl.entity.items.splice($ctrl.entity.items.indexOf(item), 1);
	};

	$ctrl.addItem = data => {
		let itemToUpdate = $ctrl.entity.items.find(it => it.road.id == data.road.id);
		itemToUpdate
			? (itemToUpdate.plan.id = data.id)
			: $ctrl.entity.items.push({
					road: { id: data.road.id, name: '' },
					plan: { id: data.id, name: '' }
			  });
	};
}

app.component('iqdCalculationDetail', {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^iqdCalculationSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<',
    allPlans: '<'
	}
});

function getPlan(idRoad, plans) {
  let plansFind = [];
  for (let i = 0; i < plans.content.length; i++) {
    if(idRoad == plans.content[i].road.id){
      plansFind.push(plans.content[i]);
    }
  }
  return plansFind;
}