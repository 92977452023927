import app from '@/app';
import templateUrl from './organization-search.html';

/* @ngInject */
function controller($controller, Organization, Principal) {

  var $ctrl = this;
  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})


	$controller('AbstractEntitySearchController', { $ctrl: this, resource: Organization });
}

const component = {
	templateUrl,
	controller,
	bindings: {
		$transition$: '<'
	}
};

app.component('organizationSearch', component);
