import app from '@/app';

app.config(stateConfig);

/* @ngInject */
function stateConfig($stateProvider) {
	$stateProvider.state('analysis', {
		abstract: true,
		parent: 'app'
	});
}
