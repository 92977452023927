import app from '@/app';
import templateUrl from './save-button-bar-decision-tree.html';

var component = {
	templateUrl,
	require: {
		form: '^form'
	},
	bindings: {
		soft: '<',
		disabled: '<',
		readonly: '<',
		onAccept: '&',
		onReject: '&'
	}
};

app.component('prSaveButtonBarDecisionTree', component);
