import app from '@/app';
import templateUrl from './table.html';

function controller() {
	var $ctrl = this;
}
const component = {
	templateUrl
};

app.component('tableHolder', component);
