import app from '@/app';
import angular from 'angular';

/* @ngInject */
function resource($resource, DateUtils) {
	return $resource(
		'api/parameters-versions/:id',
		{ id: '@id' },
		{
			query: { method: 'GET' },
			all: { method: 'GET', params: { id: 'all' }, isArray: true },
		}
	);
}

app.factory('ParametersVersions', resource);