import app from '@/app';
import angular from 'angular';

var component = {
	templateUrl: '/app/entities/cost-table/cost-table-item-detail.html',
	controller: Controller,
	require: {
		parent: '^costTableDetail'
	},
	bindings: {
		$transition$: '<'
	}
};

/* @ngInject */
function Controller($controller, $state, $timeout, Solution, Principal) {
	var $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	})

	$controller('AbstractController', { $ctrl: $ctrl });
	$ctrl.allSolution = null;
	var transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		transition(params);
		if (params.iid === 'new') {
			$ctrl.origin = null;
			$ctrl.entity = { id: $ctrl.parent.idgen-- };
		} else {
			$ctrl.origin = _.find({ id: parseInt(params.iid) })($ctrl.parent.entity.items);
			$ctrl.entity = angular.extend({}, $ctrl.origin);
		}
	};

	$ctrl.querySolutions = function(query) {
		$ctrl.loading = true;
		return Solution.all({ query: query, size: 10, sort: 'name,asc' }, function(data) {
			$ctrl.allSolutions = data;
			$ctrl.loading = false;
		}).$promise;
	};

	$ctrl.filterSolutions = function(value) {
		return (
			($ctrl.origin && $ctrl.origin.solution.id === value.id) ||
			$ctrl.parent.entity.items.every(function(item) {
				return item.solution.id !== value.id;
			})
		);
	};

	$ctrl.save = function() {
		if ($ctrl.origin) {
			angular.extend($ctrl.origin, $ctrl.entity);
		} else {
			$ctrl.parent.entity.items.push($ctrl.entity);
		}
		$ctrl.parent.dirtyItems();
		$state.go('^');
	};
}

app.component('costTableItemDetail', component);
