import app from '@/app';
import templateUrl from './historic-button.html';


  app.component('prHistoricButton', {
    templateUrl,
    bindings: {
      type: '@',
      state: '@',
      params: '<'
    }
  });
