import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, PAGE_SIZES, SMALL_PAGE_SIZES) {
	const states = [
		{
			name: 'iqdCalculation',
			parent: 'analysis',
			url: '/iqdCalculations?page&sort&size&road&campaign',
			component: 'iqdCalculationSearch',
			data: {
				viewAuthorities: ['ANALYSIS_READ'],
				editAuthorities: ['ANALYSIS_WRITE', 'ANALYSIS_PARTIAL_WRITE']
			},
			params: {
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'name,asc',
					squash: true,
					dynamic: true
				},
				road: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				campaign: {
					type: 'int',
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allOrganizations: [
					'Organization',
					Organization => {
						return Organization.query({ sort: 'name,asc' }).$promise;
					}
				],
				/* @ngInject */
				allRoads: Road => Road.query({ sort: 'name,asc', empty: false }).$promise,
				/* @ngInject */
				allCampaigns: Campaign =>
					Campaign.query({ sort: 'name,asc', empty: false }).$promise
			}
		},
		{
			name: 'iqdCalculation.detail',
			url: '/{id}',
			component: 'iqdCalculationDetail',
			resolve: {
				/* @ngInject */
				entity: (IqdCalculation, $transition$, entityInjector) =>
					entityInjector(IqdCalculation, $transition$.params().id, { items: [] }),
          /* @ngInject */
          allPlans: Plan =>
          Plan.query({plantype: 'P21'}).$promise
			}
		},
		{
			name: 'iqdCalculation.result',
			url: '/{id}/result',
			component: 'iqdCalculationResult',
			resolve: {
				/* @ngInject */
				allMetrics: Metadata => Metadata.getMetrics({ measurement: 'KPI' }),
				/* @ngInject */
				entity: (IqdCalculation, $transition$, entityInjector) =>
					entityInjector(IqdCalculation, $transition$.params().id, {})
			}
		},
		{
			name: 'iqdCalculation.overview',
			url: '/{iqdCalculationId}/overviews/{overviewId}',
			component: 'iqdCalculationOverviewWrapper'
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
