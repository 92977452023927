import app from '@/app';
import templateUrl from './tree.html';

/* @ngInject */
function Controller($filter, Principal) {
	var $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	$ctrl.$onInit = () => {
		$ctrl.prefix = $ctrl.prefix || '';
		$ctrl.decision = $ctrl.decision || 'R';
		$ctrl.path = $ctrl.prefix + $ctrl.decision;
	};

	$ctrl.getFactor = code => (code == 'KPI_TR' ? 100 : 1); // TODO: Review

	$ctrl.translate = value => {
		if (!value || _.isNumber(value)) {
			return value;
		} else {
			var splitted = value.split('_');
			var head = splitted.slice(0, 1);
			var tail = splitted.slice(1);
			var translationPath =
				'measurement.' + _.lowerCase(head) + '.' + _.camelCase(tail.join('_'));
			return $filter('translate')(translationPath);
		}
	};

  $ctrl.translatePavingType = value => {
		if (value) {
      switch (value) {
        case 1:
          return 'Flexível';
        case 2:
           return 'Semirrígido';
        case 3:
          return 'Semirrígido Invertido';
        case 4:
          return 'Rígido';
        case '1':
          return 'Flexível';
        case '2':
           return 'Semirrígido';
        case '3':
          return 'Semirrígido Invertido';
        case '4':
          return 'Rígido';
        default: return 'Não identificado';
      }
		}
	};

	$ctrl.propagate = (model, decision) => {
		$ctrl.model[decision] = model;
		$ctrl.onChange({ model: $ctrl.model, decision: $ctrl.decision });
	};
}

app.component('prTree', {
	templateUrl,
	bindings: {
		model: '<',
		prefix: '<',
		decision: '@',
		readonly: '<',
		onChange: '&'
	},
	controller: Controller
});
