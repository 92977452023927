import app from '@/app';
import angular from 'angular';
import templateUrl from './reset-request.html';

const component = {
	templateUrl,
	controller,
  bindings: {
    $transition$: '<',
    vm: '<',
    entity: '<'
  }
  
	
};

/* @ngInject */
function controller($controller,$timeout, Auth, Principal) {
	

	var $ctrl = this;

  const transition = $ctrl.transition;
  $ctrl.transition = function(params) {
    transition(params);
    $ctrl.routeParams = params;
  };

  $ctrl.requestReset = requestReset;
  $ctrl.resetAccount = {email: " " };
  $ctrl.success = null;

  $ctrl.$onInit = () => {
		$ctrl.doNotMatch = null;
		$ctrl.resetAccount = {email: " " };
		$ctrl.success = null;
	};
  
  
	$timeout(() => angular.element('#email').focus());

	function requestReset() {
		$ctrl.error = null;
		$ctrl.errorEmailNotExists = null;


		Auth.resetPasswordInit($ctrl.email)
			.then(() => ($ctrl.success = 'OK'))
			.catch(response => {
				$ctrl.success = null;
				if (response.status === 400 && response.data === 'e-mail address not registered') {
					$ctrl.errorEmailNotExists = 'ERROR';
				} else {
					$ctrl.error = 'ERROR';
				}
			});
	}
}



app.component('requestReset', component);
