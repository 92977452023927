import app from '@/app';
import templateUrl from './pager2.html';

/* @ngInject */
function controller(wfPager2Service, PAGE_SIZES) {
	var $ctrl = this;

	$ctrl.$onChanges = function(changes) {
		$ctrl.sizes = $ctrl.sizes || PAGE_SIZES;
		$ctrl.size = $ctrl.size || $ctrl.sizes[0];
		$ctrl.offset = $ctrl.offset != null ? $ctrl.offset : 1;
		$ctrl.count = $ctrl.count || 0;
		$ctrl.precision = $ctrl.precision || 0;
		$ctrl.steped = $ctrl.step || 1;
		$ctrl.pages = wfPager2Service.createPages(
			$ctrl.size,
			$ctrl.count,
			$ctrl.steped,
			$ctrl.offset
		);
		$ctrl.page = Math.min(Math.max($ctrl.page || 0, 0), $ctrl.pages.length - 1);
		const page = $ctrl.pages[$ctrl.page];
		$ctrl.head = page && page.head;
		$ctrl.tail = page && page.tail;
	};

	$ctrl.setPage = function(page) {
		if (page !== $ctrl.page) {
			$ctrl.onChange($ctrl.pages[page]);
		}
	};

	$ctrl.setSize = function(size) {
		if (size !== $ctrl.size) {
			$ctrl.onChange(
				wfPager2Service.createPage(0, size, $ctrl.count, $ctrl.steped, $ctrl.offset)
			);
		}
	};

	$ctrl.first = function() {
		$ctrl.setPage(0);
	};

	$ctrl.hasPages = () => !!$ctrl.pages && !!$ctrl.pages.length;

	$ctrl.hasPrevious = () => $ctrl.hasPages && $ctrl.page - 1 >= 0;

	$ctrl.hasNext = () => $ctrl.hasPages() && $ctrl.page + 1 < $ctrl.pages.length;

	$ctrl.last = function() {
		$ctrl.setPage($ctrl.pages.length - 1);
	};

	$ctrl.previous = function() {
		if ($ctrl.page > 0) {
			$ctrl.setPage($ctrl.page - 1);
		}
	};

	$ctrl.next = function() {
		if ($ctrl.page + 1 < $ctrl.pages.length) {
			$ctrl.setPage($ctrl.page + 1);
		}
	};
}

const component = {
	templateUrl,
	controller,
	bindings: {
		page: '<', // required
		size: '<', // required
		count: '<', // required
		sizes: '<',
		offset: '<',
		named: '@',
		step: '<',
		precision: '<',
		onChange: '&'
	}
};

app.component('wfPager2', component);
