/* eslint-disable no-undef */
import angular from 'angular';
import app from '@/app';
import templateUrl from './measurement-map.html';

/* @ngInject */
function MeasurementMapController($controller, $scope, $translate) {
	const $ctrl = this;
	$controller('AbstractController', { $ctrl });

	$ctrl.loadingMap = true;
	$ctrl.emptible = false;

	$ctrl.transition = params => {
		$ctrl.parent.allResources['GEODATA'].query(angular.extend({}, params), data => {
			if (data.features) {
				data.features = data.features.filter(feature => feature.geometry !== null);

				$ctrl.loadingMap = false;

				var initLat = 0;
				var initLong = 0;

				$ctrl.measurement = $ctrl.parent.filters[0].measurement.code;
				$ctrl.metric = null;
				$ctrl.metric = $ctrl.parent.allMetrics.find(
					it => it.code == $ctrl.parent.filters[0].metric
				);

				if (data.features[0].geometry.type == 'Point') {
					initLat = data.features[0].geometry.coordinates[0];
					initLong = data.features[0].geometry.coordinates[1];
				} else {
					initLat = data.features[0].geometry.coordinates[0][0];
					initLong = data.features[0].geometry.coordinates[0][1];
				}

				$ctrl.onEachFeature = function (feature, layer) {
					var popupContent = '<p><b>Informações:</b></p>';
					popupContent +=
						'<p><b>km:</b> ' + feature.properties['head_milestone'] + '</p>';

					if (feature.properties) {
						for (var key in feature.properties) {
							if ($ctrl.metric && feature.properties[key] && key != 'head_milestone') {
								popupContent +=
									'<p><b>' +
									$translate.instant(
										'measurement.' +
											$ctrl.measurement.toLowerCase() +
											'.' +
											$ctrl.metric.name
									) +
									':</b> ' +
									feature.properties[key] +
									'</p>';
							} else if(key != 'head_milestone') {
								popupContent +=
									'<p><b>' +
									$translate.instant(
										'measurement.' +
											$ctrl.measurement.toLowerCase() +
											'.' +
											key.toString()
									) +
									':</b> ' +
									feature.properties[key] +
									'</p>';
							}
						}

						//popupContent = '<p><b>' + $translate.instant('measurement.' + $ctrl.measurement.toLowerCase() + '.' + $ctrl.metric.name) + ':</b> ' + feature.properties['metric'] + '</p>';
					}

					layer.bindPopup(popupContent);
				};

				var map = L.map('measurement-map').setView([initLong, initLat], 10);

				L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
					maxZoom: 19,
					attribution:
						'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
				}).addTo(map);

				L.geoJSON(data, {
					onEachFeature: $ctrl.onEachFeature,
					pointToLayer(feature, latlng) {
						if (feature.geometry.type !== 'Point') {
							return L.marker(latlng);
						}

						var goodIcon = new L.Icon({
							iconUrl:
								'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
							shadowUrl:
								'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
							iconSize: [25, 41],
							iconAnchor: [12, 41],
							popupAnchor: [1, -34],
							shadowSize: [41, 41]
						});

						var badIcon = new L.Icon({
							iconUrl:
								'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
							shadowUrl:
								'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
							iconSize: [25, 41],
							iconAnchor: [12, 41],
							popupAnchor: [1, -34],
							shadowSize: [41, 41]
						});

						var mediumIcon = new L.Icon({
							iconUrl:
								'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-gold.png',
							shadowUrl:
								'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
							iconSize: [25, 41],
							iconAnchor: [12, 41],
							popupAnchor: [1, -34],
							shadowSize: [41, 41]
						});

						var defaultIcon = new L.Icon({
							iconUrl:
								'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-black.png',
							shadowUrl:
								'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
							iconSize: [25, 41],
							iconAnchor: [12, 41],
							popupAnchor: [1, -34],
							shadowSize: [41, 41]
						});

						let metricStatus = $ctrl.metricLimitStatus(feature.properties['retorno']);
						let icons = {
							good: goodIcon,
							bad: badIcon,
							default: defaultIcon,
							medium: mediumIcon
						};

						return L.marker(latlng, { icon: icons[metricStatus] });
					},
					style: feature => {
						let color = colorDefault;
						let colorsStatus = {
							good: '#00ff00',
							bad: '#ff0000',
							default: '#000000'
						};

						let metricStatus = $ctrl.metricLimitStatus(feature.properties['retorno']);
						color = colorsStatus[metricStatus];

						// var averageMetric;
						// $ctrl.road = $ctrl.parent.allRoads.content.find(it => it.id == params.road);
						// $ctrl.metricLimit = $ctrl.parent.allMetricLimits.content.find(
						//   it => it.organization.id == $ctrl.road.owner.id && it.metric == $ctrl.metric.code
						// );

						// if ($ctrl.metricLimit) {
						//   averageMetric = feature.properties['retorno'];
						//   if (averageMetric <= $ctrl.metricLimit.goodLimit) {
						//     color = colorGood;
						//   }
						//   if (averageMetric > $ctrl.metricLimit.badLimit) {
						//     color = colorBad;
						//   }
						// }

						return {
							color: color,
							weight: 6
						};
					}
				}).addTo(map);
			} else {
				$ctrl.emptible = true;
			}
			$ctrl.loadingMap = false;
		});
	};

	$ctrl.metricLimitStatus = averageMetric => {
		var status = 'default';
		$ctrl.road = $ctrl.parent.allRoads.content.find(it => it.id == $ctrl.parent.road.id);
		$ctrl.metricLimit = null;
		if($ctrl.metric){
			$ctrl.metricLimit = $ctrl.parent.allMetricLimits.content.find(
				it => it.organization.id == $ctrl.road.owner.id && it.metric == $ctrl.metric.code
			);
		}

		if ($ctrl.metricLimit) {
			if (
				$ctrl.metricLimit.badLimit > averageMetric &&
				averageMetric > $ctrl.metricLimit.goodLimit
			) {
				status = 'medium';
			}
			if (averageMetric <= $ctrl.metricLimit.goodLimit) {
				status = 'good';
			}
			if (averageMetric >= $ctrl.metricLimit.badLimit) {
				status = 'bad';
			}
		}

		return status;
	};

	$ctrl.override = [{}];



}
const component = {
	templateUrl,
	controller: MeasurementMapController,
	require: {
		parent: '^measurementSearch'
	},
	bindings: {
		road: '<',
		$transition$: '<'
	}
};

app.component('measurementMap', component);
