import app from '@/app';

function AlertService() {
	this.$get = getService;

	/* @ngInject */
	function getService($timeout, $translate) {
		return {
			alerts: [],
			timeout: 5000,
			add: add,
			clear: clear,
			close: close,
			success: success,
			error: error,
			info: info,
			warning: warning
		};

		function add(alert) {
			this.alerts.unshift(alert);
		}

		function close(index) {
			this.alerts.splice(index, 1);
		}

		function clear() {
			this.alerts.splice(0, this.alerts.length);
		}

		function success(message, params) {
			this.add({
				type: 'success',
				message: message,
				params: params
			});
		}

		function error(message, params) {
			this.add({
				type: 'danger',
				message: message,
				params: params
			});
		}

		function warning(message, params) {
			this.add({
				type: 'warning',
				message: message,
				params: params
			});
		}

		function info(message, params) {
			this.add({
				type: 'info',
				message: message,
				params: params
			});
		}
	}
}

app.provider('AlertService', AlertService);
