import app from '@/app';

function filter() {
	return value => {
		if (Array.isArray(value)) {
			return value
				.map(Math.abs)
				.filter((value, index, self) => self.indexOf(value) === index)
				.sort();
		} else {
			return null; // value == 100 ? 'Acost' : value;
		}
	};
}

app.filter('lanes', filter);
