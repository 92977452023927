import app from '@/app';

app.component('selector', {
	templateUrl: '/app/components/selector/selector.html',
	controller: SelectorController,
	transclude: true,
	replace: true,
	bindings: {
		select: '@',
		desc: '@',
		from: '=',
		groupBy: '=',
		ngModel: '=model'
	}
});

/* @ngInject */
function SelectorController($element) {
	var $ctrl = this;
	$ctrl.model = [];
	$ctrl.search = 'a';

	$ctrl.add = function(item) {
		$ctrl.model.push(item);
		$ctrl.search = '';
		$ctrl.open = false;
		$element.find('input').focus();
	};

	$ctrl.remove = function(item) {
		var index = $ctrl.model.indexOf(item);
		if (index > -1) {
			$ctrl.model.splice(index, 1);
		}
	};

	$ctrl.keydown = function(event) {
		switch (event.keyCode) {
			case 40:
				$ctrl.open = true;
				break;
			case 38:
				$ctrl.open = false;
				break;
		}
	};
}
