import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider) {
	$stateProvider.state({
		name: 'settings',
		parent: 'account',
		url: '/settings',
		resolve: {
			/* @ngInject */
			entity: Principal => Principal.identity().$promise
		}
	});
}

app.config(stateConfig);
