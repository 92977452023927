import app from '@/app';
import ReactApp from './react-app';
import { lazyInjector } from './lazyInjector';

/* @ngInject */
function run($injector, $state, stateHandler, translationHandler, $trace) {
	$state.defaultErrorHandler(rejection => {});
	//$trace.enable();
	stateHandler.initialize();
	translationHandler.initialize();
	lazyInjector.$injector = $injector;
	//ReactApp();
}

app.run(run);
