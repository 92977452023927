import app from '@/app';
import templateUrl from './decision-tree-node.html';

function DecisionTreeNodeController() {
	var $ctrl = this;
  
  $ctrl.isPaving = false;
 
	$ctrl.$onInit = () => {
    $ctrl.allMetrics = $ctrl.allMetrics.filter(item => item.code != 'KPI_INTERVENTION_YEAR');
		$ctrl.model = $ctrl.model || {
			type: 'GROUP',
			operator: 'OR',
			items: []
		};
    $ctrl.allPavingTypes = [];
	};

  $ctrl.pavingTypes = [1, 2, 3, 4];

  $ctrl.translatePavingType = value => {
		if (value) {
      switch (value) {
        case 1:
          return 'Flexível';
        case 2:
           return 'Semirrígido';
        case 3:
          return 'Semirrígido Invertido';
        case 4:
          return 'Rígido';
        case '1':
          return 'Flexível';
        case '2':
           return 'Semirrígido';
        case '3':
          return 'Semirrígido Invertido';
        case '4':
          return 'Rígido';
        default: return 'Não identificado';
      }
		}
	};

  $ctrl.checkIfIsPaving = lhsMetric => {
    if(lhsMetric == 'PAVING_TYPE'){
      $ctrl.isPaving = true;
    }
  }

	$ctrl.onRhsMetricChange = index => {
		var node = $ctrl.model.items[index];
		if (node.rhsMetric) {
			node.rhsValue = undefined;
		}
	};

	$ctrl.getFactor = code => code ? $ctrl.allMetrics.find(item => item.code == code).factor : 1;

	$ctrl.addItem = () => {
		$ctrl.model.items.push({ type: 'SINGLE' });
	};

	$ctrl.removeNode = node => {
		$ctrl.model.items = $ctrl.model.items.filter(item => item != node);
	};
}

const component = {
	templateUrl,
	controller: DecisionTreeNodeController,
	bindings: {
		model: '=',
		allMetrics: '<'
	}
};

app.component('prDecisionTreeNode', component);
