import app from '@/app';
import angular from 'angular';

var component = {
	templateUrl: '/app/components/dialog/duplicate/duplicate-dialog.html',
	controller: Controller,
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	}
};

/* @ngInject */
function Controller() {
	var $ctrl = this;
  

  this.$onInit = function() {
		$ctrl.message = $ctrl.resolve.bindings.message;
	};

	$ctrl.cancel = function() {
		$ctrl.dismiss({ $value: 'cancel' });
	};
}

app.component('warnDuplicateDialog', component);
