import app from '@/app';

app.factory('_unique', () => _unique)
	.factory('_get', () => _get)
	.factory('_plot', () => _plot)
	.factory('_diff', () => _plot);

function _get(key) {
	return function(object) {
		return object[key];
	};
}

function _unique(value, index, array) {
	return array.indexOf(value) == index;
}

function _plot(x, y) {
	return function(object) {
		return {
			x: object[x],
			y: object[y]
		};
	};
}

function _diff(key) {
	return function(lhs, rhs) {
		return lhs[key] - rhs[key];
	};
}
