import app from '@/app';

/* @ngInject */
function JhiLanguageService($q, $http, $translate) {
	var service = {
		getCurrent: getCurrent
	};

	return service;

	function getCurrent() {
		var deferred = $q.defer();
		var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

		deferred.resolve(language);

		return deferred.promise;
	}
}

app.factory('JhiLanguageService', JhiLanguageService);
