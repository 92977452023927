import app from '@/app';
import angular from 'angular';

/* @ngInject */
function resource($resource, DateUtils) {
	return $resource(
		'/api/budgetlimits/:id',
		{ id: '@id' },
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
          var result = JSON.parse(data);
          
					if (result) {
            for(var item of result.items){
              item.dateLimit = DateUtils.convertDateTimeFromServer(item.dateLimit);
            }
             
					}
					return result;
				}
			},
			create: { method: 'POST' },
			query: { method: 'GET' },
			all: { method: 'GET', params: { id: 'all' }, isArray: true },
			update: { method: 'PUT' }
		}
	);
}

app.factory('BudgetLimit', resource);