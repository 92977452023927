import app from '@/app';
import templateUrl from './mechanisticAnalysisData-search.html';

/* @ngInject */
function MechanisticAnalysisDataSearchController($controller, $state, $timeout, $uibModal, Upload, Principal) {
	var $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})
  
	$controller('AbstractController', { $ctrl });

	const transition = $ctrl.transition;

	$ctrl.upload = files => {
		if (!$ctrl.readonly && files && files.length) {
			$uibModal
				.open({
					component: 'mechanisticAnalysisDataImportDialog',
					size: 'md',
					resolve: {
						mechanisticAnalysisData: () => $ctrl.mechanisticAnalysisData,
						files: () => files
					}
				})
				.result.then(() => $state.reload());
		}
	};

	$ctrl.byOrganization = value =>
		$ctrl.organization && value.organization.id == $ctrl.organization.id;

	$ctrl.byOwner = value => $ctrl.organization && value.owner.id == $ctrl.organization.id;
}

const component = {
	templateUrl,
	controller: MechanisticAnalysisDataSearchController
};

app.component('mechanisticAnalysisDataSearch', component);
