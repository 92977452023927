import app from '@/app';
import templateUrl from './cost-table-detail.html';

/* @ngInject */
function Controller($controller, $timeout, CostTable, Principal) {
	const $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	})

	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: CostTable,
		type: 'costTable'
	});

	$ctrl.dirtyItems = () => {
		$timeout(() => $ctrl.setDirty($ctrl.costTableForm.items));
	};

	$ctrl.removeItem = id => {
		$ctrl.entity.items = $ctrl.entity.items.filter(it => it.id != id);
		$ctrl.dirtyItems();
	};
}

const component = {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^costTableSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<'
	}
};

app.component('costTableDetail', component);
