import app from '@/app';
import templateUrl from './multiselect.html';

/* @ngInject */
function MultiselectController($timeout, Principal) {
	var $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	$ctrl.doQuery = function(query) {
		$ctrl.onQuery({ query });
	};

	$ctrl.addItem = function() {
		let index = $ctrl.model.length;
		$ctrl.model.push(null);
	};

	$ctrl.removeItem = function(item) {
		$ctrl.model = $ctrl.model.filter(it => it != item);
		$timeout(() => $ctrl['multiselectForm_' + $ctrl.name].dirty.$setDirty());
	};
}

const component = {
	templateUrl,
	controller: MultiselectController,
	bindings: {
		name: '@',
		model: '=',
		items: '<',
		onQuery: '&'
	}
};

app.component('wfMultiselect', component);
