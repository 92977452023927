import app from '@/app';
import templateUrl from './reinforcementCalculation-result.html';

/* @ngInject */
function controller(
	$controller,
	$filter,
	$http,
	$httpParamSerializer,
	$state,
	$translate,
	DialogService,
	wfPivotUtils,
	wfPager2Service,
	PAGE_SIZES,
	SMALL_PAGE_SIZES,
  Principal
) {
	var $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	$controller('AbstractController', { $ctrl: $ctrl });

	function metricAggregator(metric) {
		return function() {
			return {
				data: null,
				push: function(record) {
					this.data = record[metric.name];
				},
				value: function() {
					return $filter('number')(this.data * metric.factor, metric.precision);
				}
			};
		};
	}

	$ctrl.options = {
		formatter: wfPivotUtils.formatters.asis(),
		rows: [
			{
				name: 'headMilestone',
				translation: 'common.headMilestone',
				formatter: wfPivotUtils.formatters.number(3)
			},
			{
				name: 'tailMilestone',
				translation: 'common.tailMilestone',
				formatter: wfPivotUtils.formatters.number(3)
			}
		],
		cols: [
			{
				name: 'year',
				translation: 'common.year'
			},
			{
				name: 'direction',
				translation: 'common.direction',
				sorter: wfPivotUtils.sorters.sortAs(['DEC', 'INC']),
				formatter: direction => $translate.instant('direction.' + direction)
			},
			{
				name: 'lane',
				translation: 'common.lane',
				formatter: lane => {
					const val = Math.abs(lane);
					return val == 100 ? 'acost' : val;
				}
			}
		]
	};

	$ctrl.yearSizes = SMALL_PAGE_SIZES;

	$ctrl.segmentSizes = PAGE_SIZES;

	var transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		transition(params);
		$ctrl.milestonePage = params.milestonePage != null ? parseInt(params.milestonePage) : 0;
		$ctrl.milestoneSize =
			params.milestoneSize != null ? parseInt(params.milestoneSize) : PAGE_SIZES[3];
		$ctrl.milestoneCount =
			($ctrl.entity.tailMilestone - $ctrl.entity.headMilestone) / $ctrl.entity.segmentLength;
		const milestoneRange = wfPager2Service.createPage(
			$ctrl.milestonePage,
			$ctrl.milestoneSize,
			$ctrl.milestoneCount,
			$ctrl.entity.segmentLength,
			$ctrl.entity.headMilestone
		);
		$ctrl.headMilestone = milestoneRange.head;
		$ctrl.tailMilestone = milestoneRange.tail;

		$ctrl.yearPage = params.yearPage != null ? parseInt(params.yearPage) : 0;
		$ctrl.yearSize = params.yearSize != null ? parseInt(params.yearSize) : SMALL_PAGE_SIZES[0];
		$ctrl.yearCount = $ctrl.entity.processedYears;
		const yearRange = wfPager2Service.createPage(
			$ctrl.yearPage,
			$ctrl.yearSize,
			$ctrl.entity.processedYears,
			1,
			$ctrl.entity.headYear
		);
		$ctrl.headYear = yearRange.head;
		$ctrl.tailYear = yearRange.tail;

		$ctrl.direction = params.direction;
		$ctrl.lanes = params.lanes;
		$ctrl.metric = params.metric;
		var metric = ($ctrl.metric && _.find({ code: $ctrl.metric })($ctrl.allMetrics)) || '';
		$ctrl.options.aggregator = metricAggregator(metric);
		$ctrl.road = $ctrl.entity.road.name;
		$ctrl.campaign = $ctrl.entity.campaign.name;
		const query = _.pick([
			'headMilestone',
			'tailMilestone',
			'headYear',
			'tailYear',
			'direction',
			'lanes',
			'metric',
			'road',
			'campaign'
		])($ctrl);

		$ctrl.exportLink =
			'/api/plans/' + $ctrl.entity.id + '/results/excel?' + $httpParamSerializer(query);
		$http
			.get('api/plans/' + $ctrl.entity.id + '/results', {
				params: query
			})
			.then(function(response) {
				$ctrl.entities = response.data;
			});
	};

	$ctrl.filterLane = function(lane) {
		return $ctrl.lanes || !_.includes(lane)($ctrl.lanes);
	};

	$ctrl.onQueryChange = function(query) {
		$state.go(
			$state.current,
			{
				milestonePage: $ctrl.milestonePage,
				milestoneSize: $ctrl.milestoneSize,
				yearPage: $ctrl.yearPage,
				yearSize: $ctrl.yearSize,
				direction: $ctrl.direction,
				lanes: $ctrl.lanes,
				metric: $ctrl.metric
			},
			{
				reload: true
			}
		);
	};

	$ctrl.onMilestonePageChange = function(page, size) {
		$state.go(
			$state.current,
			{
				milestonePage: page,
				milestoneSize: size
			},
			{
				reload: true
			}
		);
	};

	$ctrl.onYearPageChange = function(page, size) {
		$state.go(
			$state.current,
			{
				yearPage: page,
				yearSize: size
			},
			{
				reload: true
			}
		);
	};
}

const component = {
	templateUrl,
	controller,
	bindings: {
		$transition$: '<',
		allMetrics: '<',
		entity: '<'
	}
};

app.component('reinforcementCalculationResult', component);
