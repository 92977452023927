import app from '@/app';
import angular from 'angular';

/* @ngInject */
function Campaign($resource, DateUtils) {
	return $resource(
		'api/campaigns/:id',
		{ id: '@id' },
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					if (data) {
						data = angular.fromJson(data);
						data.headDate = DateUtils.convertDateTimeFromServer(data.headDate);
						data.tailDate = DateUtils.convertDateTimeFromServer(data.tailDate);
					}
					return data;
				}
			},
			create: { method: 'POST' },
			query: { method: 'GET' },
			all: { method: 'GET', params: { id: 'all' }, isArray: true },
			update: { method: 'PUT' }
		}
	);
}

app.factory('Campaign', Campaign);
