import app from '@/app';

const component = {
	templateUrl: '/app/admin/user/user-detail.html',
	controller: Controller,
	bindings: {
		$transition$: '<',
		entity: '<',
		allTimezones: '<'
	}
};

/* @ngInject */
function Controller($controller, $translate, User, Role, LANGUAGES, Principal) {
	$controller('AbstractEntityDetailController', { $ctrl: this, resource: User, type: 'user' });
	var $ctrl = this;
	$ctrl.languages = LANGUAGES;

  $ctrl.currentAccount = null;

  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});


	var transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		$ctrl.entity.timezone = 'America/Sao_Paulo';
		$ctrl.entity.language = 'pt-br';
	};

	$ctrl.queryRoles = query => {
		$ctrl.allRoles = [
			{ id: 2, name: 'Administrador' },
			{ id: 3, name: 'Análise' },
			{ id: 4, name: 'Agência' },
			{ id: 5, name: 'Carga de Dados' },
			{ id: 6, name: 'Consulta Dados' }
		];
		if (query) {
			return Role.query(
				{ query: query, page: 0, size: 15, sort: 'name,asc' },
				data => ($ctrl.allRoles = data.content)
			).$promise;
		}
	};
}

app.component('userDetail', component);
