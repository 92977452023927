import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, PAGE_SIZES) {
	var states = [
		{
			name: 'mechanisticAnalysisData',
			parent: 'analysis',
			url: '/mechanisticAnalysisDatas',
			data: {
				viewAuthorities: ['ORGANIZATIONS_WRITE'],
				editAuthorities: ['ORGANIZATIONS_WRITE', 'ANALYSIS_PARTIAL_WRITE', 'MECHANISTICANALYSISDATAS_IMPORTAR']
			},
			component: 'mechanisticAnalysisDataSearch'
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
