import app from '@/app';
import angular from 'angular';
import templateUrl from './measurement-pie-chart.html';

var component = {
  templateUrl,
  controller: Controller,
  require: {
    parent: '^measurementSearch'
  },
  bindings: {
    road: '<',
    $transition$: '<'
  }
};

/* @ngInject */
function Controller($controller, $scope, $translate) {
  var $ctrl = this;
  $controller('AbstractController', { $ctrl });

  var bad = $translate.instant('metricLimit.badLimit');
  var limit = $translate.instant('metricLimit.inLimit');
  var good = $translate.instant('metricLimit.goodLimit');

  $ctrl.metric = { "name": {}, "factor": {} };
  $ctrl.metricLimit = { "badLimit": {}, "goodLimit": {}  };

  var slice = _.countBy(entity => {
    var value = entity[$ctrl.metric.name] * $ctrl.metric.factor;
    if ($ctrl.metricLimit.goodLimit > $ctrl.metricLimit.badLimit) {
      return value > $ctrl.metricLimit.goodLimit
        ? good
        : value < $ctrl.metricLimit.badLimit
          ? bad
          : limit;
    } else {
      return value < $ctrl.metricLimit.goodLimit
        ? good
        : value > $ctrl.metricLimit.badLimit
          ? bad
          : limit;
    }
  });

  function laneDesc(lanes) {
    return lanes && lanes.length > 0
      ? ' - ' +
      $translate.instant(lanes.length == 1 ? 'common.lane' : 'common.lanes') +
      ' ' +
      lanes.join(', ')
      : '';
  }

  $ctrl.transition = function (params) {


    $ctrl.charts = [];
    angular.forEach(angular.copy(JSON.parse(params.filters)), function (item, key) {
      $ctrl.charts.push({
        "measurement": {
          "name": item.measurement.name,
          "code": item.measurement.code,
          "type": item.measurement.type
        },
        "metric": item.metric,
        "options":
        {
          pieceLabel: {
            render: ({ percentage }) => `${percentage}%`,
            fontColor: '#fff',
            fontStyle: 'bolder',
            precision: 1
          },
          backgroundColor: 'rgb(240, 240, 240)',
          title: {
            display: true,
            text: ''
          },
          scales: {
            yAxes: [],
            xAxes: []
          },
          legend: {
            display: true
          },
          animation: false,
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var label = data.labels[tooltipItem.index];
                var total = dataset.data.reduce(function (previousValue, currentValue) {
                  return previousValue + currentValue;
                });
                var currentValue = dataset.data[tooltipItem.index];
                var precentage = ((currentValue / total) * 100).toFixed(1);
                return label + ': ' + precentage + '%';
              }
            }
          }
        },
        "entities": {},
        "metricLimit": {
          "goodLimit": {},
          "badLimit": {}
        },
        "good": {},
        "bad": {},
        "grouped": {}

      });
    })


    $ctrl.road = $ctrl.parent.allRoads.content.find(it => it.id == params.road);

    angular.forEach($ctrl.charts, function (item, key) {
      item.metricLimit = $ctrl.parent.allMetricLimits.content.find(
        it => it.organization.id == $ctrl.road.owner.id && it.metric == item.metric
      );
      item.metric = _.find({ code: item.metric })($ctrl.parent.allMetrics);
      item.options.title.text = $ctrl.road.name + laneDesc($ctrl.parent.lanes);
      item.measurement_metric = 'measurement.' + item.measurement.name.toLowerCase() + '.' + item.metric.name;
      
      $scope.$on('chart-create', function (event, chart) {
        if('canvas-'+key == chart.canvas.id){
          item.exportLink = chart.toBase64Image();
        }
      });
      
      $ctrl.parent.allResources[item.measurement.code].query(angular.extend({}, params), data => {
        item.entities = data.content;
        $ctrl.refresh(item);
      });
    });
  }

  $ctrl.refresh = (item) => {
    item.labels = [];
    item.data = [];
    if (item.entities && item.entities.length) {
      $ctrl.metric.name = item.metric.name;
      $ctrl.metric.factor = item.metric.factor;
      $ctrl.metricLimit.goodLimit = item.metricLimit.goodLimit;
      $ctrl.metricLimit.badLimit = item.metricLimit.badLimit;
      item.grouped = slice(item.entities);
      for (var key in item.grouped) {
        var group = item.grouped[key];
        item.labels.push(key);
        item.data.push(group);
      }
    }
  };
  $ctrl.showTooltip = true;

  $ctrl.onClickShowTooltip = function (isChecked) {
    $ctrl.options.pieceLabel = isChecked
      ? {
        // https://github.com/emn178/Chart.PieceLabel.js/issues/31
        render: ({ percentage }) => `${percentage}%`,
        fontColor: '#fff',
        fontStyle: 'bolder',
        precision: 1
      }
      : null;
  };

  $ctrl.options = {
    pieceLabel: {
      render: ({ percentage }) => `${percentage}%`,
      fontColor: '#fff',
      fontStyle: 'bolder',
      precision: 1
    },
    backgroundColor: 'rgb(240, 240, 240)',
    title: {
      display: true,
      text: ''
    },
    scales: {
      yAxes: [],
      xAxes: []
    },
    legend: {
      display: true
    },
    animation: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var label = data.labels[tooltipItem.index];
          var total = dataset.data.reduce(function (previousValue, currentValue) {
            return previousValue + currentValue;
          });
          var currentValue = dataset.data[tooltipItem.index];
          var precentage = ((currentValue / total) * 100).toFixed(1);
          return label + ': ' + precentage + '%';
        }
      }
    }
  };

  $scope.$on('chart-create', function (event, chart) {
    $ctrl.exportLink = chart.toBase64Image();
  });
}

app.component('measurementPieChart', component);
