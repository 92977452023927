import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, PAGE_SIZES) {
	var states = [
		{
			name: 'asBuilt',
			parent: 'measurements',
			url:
				'/asBuilt?organization&road&headMilestone&tailMilestone&lanes&direction&campaigns&output&measurement&metric&roadtype',
			data: {
				viewAuthorities: ['MEASUREMENTS_READ', 'ASBUILT_READ'],
				editAuthorities: ['MEASUREMENTS_WRITE', 'ANALYSIS_WRITE', 'ASBUILT_WRITE']
			},
			component: 'asBuiltSearch',
			params: {
				organization: {
					type: 'int',
					value: null,
					squash: true,
					dynamic: true
				},
				road: {
					type: 'int',
					value: null,
					squash: true,
					dynamic: true
				},
				headMilestone: {
					value: null,
					squash: true,
					dynamic: true
				},
				tailMilestone: {
					value: null,
					squash: true,
					dynamic: true
				},
				direction: {
					value: null,
					squash: true,
					dynamic: true
				},
				lanes: {
					array: true,
					value: [],
					squash: true,
					dynamic: true
				},
				campaigns: {
					array: true,
					type: 'int',
					squash: true,
					dynamic: true
				},
				output: {
					value: 'table',
					squash: true,
					dynamic: true
				},
				measurement: {
					squash: true,
					dynamic: true
				},
				metric: {
					squash: true,
					dynamic: true
				},
				roadtype: {
					value: null,
					squash: true,
					dynamic: true
				},
				startDate: {
					value: null,
					squash: true,
					dynamic: true
				},
				endDate: {
					value: null,
					squash: true,
					dynamic: true
				},
				segmentLength: {
					value: null,
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allResources: Built => ({
					BUILT: Built
				}),
				/* @ngInject */
				allOrganizations: Organization => Organization.query({ sort: 'name,asc' }).$promise,
				/* @ngInject */
				allRoads: Road => Road.query({ sort: 'name,asc', empty: false }).$promise,
				/* @ngInject */
				allMeasurements: Metadata =>
					Metadata.getMeasurements({ measurement: 'KPI', view: 'ANALYSIS' }),
				/* @ngInject */
				allMetrics: Metadata => Metadata.getMetrics(),
				/* @ngInject */
				allMetricLimits: MetricLimit => MetricLimit.query().$promise
			}
		},
		{
			name: 'asBuilt.table',
			url: '/table?page&size&sort',
			component: 'asBuiltTable',
			params: {
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				size: {
					value: PAGE_SIZES[0],
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'campaign,asc',
					squash: true,
					dynamic: true
				}
			}
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
