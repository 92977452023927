import app from '@/app';
import templateUrl from './create-button.html';

const component = {
	templateUrl,
	bindings: {
		type: '@',
		state: '@',
		params: '<'
	}
};

app.component('prCreateButton', component);
