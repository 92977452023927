import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider) {
	$stateProvider.state({
		name: 'finishReset',
		parent: 'account',
		url: '/reset/finish?key',
		component: 'resetFinish'
	});
}

app.config(stateConfig);
