import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	const states = [
		stateHelperProvider.search({
			name: 'solution',
			parent: 'entity',
			data: {
				viewAuthorities: ['ENTITIES_READ', 'SOLUTIONS_READ'],
				editAuthorities: ['ENTITIES_WRITE', 'SOLUTIONS_WRITE']
			}
		}),
		{
			name: 'solution.detail',
			url: '/{id}',
			component: 'solutionDetail',
			resolve: {
				/* @ngInject */
				allMetrics: Metadata =>
					Metadata.getMetrics({ measurement: 'KPI', view: 'SOLUTION' }),

				/* @ngInject */
				entity: (Solution, $transition$, entityInjector) =>
					entityInjector(Solution, $transition$.params().id, { items: [] }),	
			}
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
