import app from '@/app';

app.config(stateConfig);

/* @ngInject */
function stateConfig($stateProvider) {
	$stateProvider
		.state('error', {
			parent: 'app',
			url: '/error',
			views: {
				'content@': {
					templateUrl: '/app/layouts/error/error.html'
				}
			}
		})
		.state('accessdenied', {
			parent: 'app',
			url: '/accessdenied',
			views: {
				'content@': {
					templateUrl: '/app/layouts/error/accessdenied.html'
				}
			}
		});
}
