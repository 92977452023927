import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, PAGE_SIZES, SMALL_PAGE_SIZES) {
  var states = [
		{
			name: 'plan',
			parent: 'analysis',
			url: '/plans?page&sort&size&road&campaign&plantype&organization&roadtype&project&situationPlan',
			data: {
				viewAuthorities: ['ANALYSIS_READ', 'PLANS_READ'],
				editAuthorities: ['ANALYSIS_WRITE', 'PLANS_WRITE']
			},
      component: 'planSearch',
			params: {
        size: {
					value: PAGE_SIZES[0],
					type: 'int',
					squash: true,
					dynamic: true
				},
        roadtype: {
					value: null,
					squash: true,
					dynamic: true
				},
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'name,asc',
					squash: true,
					dynamic: true
				},
				road: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				campaign: {
					type: 'int',
					squash: true,
					dynamic: true
				},

        organization: {
					type: 'int',
					squash: true,
					dynamic: true
				},

        project: {
					type: 'int',
					squash: true,
					dynamic: true
				},

        situationPlan: {
					type: 'int',
					squash: true,
					dynamic: true
				},

				plantype: {
					type: 'int',
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allOrganizations: [
					'Organization',
					Organization => {
						return Organization.query({ sort: 'name,asc' }).$promise;
					}
				],
				/* @ngInject */
				allRoads: Road => Road.query({ sort: 'name,asc', empty: false }).$promise,
				/* @ngInject */
				allCampaigns: Campaign =>
					Campaign.query({ sort: 'name,asc', empty: false }).$promise,
				/* @ngInject */
				allDecisionTrees: DecisionTree => DecisionTree.query({ sort: 'name,asc' }).$promise,
				/* @ngInject */
				allCostTables: CostTable => CostTable.query({ sort: 'name,asc' }).$promise,

        /* @ngInject */
        allGrowthRates: GrowthRate => GrowthRate.query({query: ''}).$promise,
			}
		},
		{
			name: 'plan.detail',
			url: '/{id}',
			component: 'planDetail',
			resolve: {
				/* @ngInject */
				entity: (Plan, $transition$, entityInjector) =>
					entityInjector(Plan, $transition$.params().id, {}),

        /* @ngInject */
				idGrowthRate: ($transition$) => ($transition$.params().idGrowthRate)
			}
		},
    {
			name: 'plan.historic',
			url: '/planhistoric/{id}',
			component: 'planHistoric',
			resolve: {
				/* @ngInject */
				entity: (Plan, $transition$, entityInjector) =>
					entityInjector(Plan, $transition$.params().id, {})
			}
		},
    {
			name: 'plan.historic.constanthistoric',
			url: '/constanthistoric?version',
			component: 'constantHistoric',
      params: {
        version: {
          type: 'int',
          squash: true,
          dynamic: true
        }
      },
			resolve: {
        /* @ngInject */
				idplan: function($transition$) {
          return $transition$.params().id;
        },
        /* @ngInject */
        version: function($transition$) {
          return $transition$.params().version;
        }
			}
		},
    {
			name: 'plan.historic.solutionhistoric',
			url: '/solutionhistoric?version',
			component: 'solutionHistoric',
      params: {
        version: {
          type: 'int',
          squash: true,
          dynamic: true
        }
      },
			resolve: {
        /* @ngInject */
				idplan: function($transition$) {
          return $transition$.params().id;
        },
        /* @ngInject */
        version: function($transition$) {
          return $transition$.params().version;
        }
			}
		},
    {
			name: 'plan.historic.costtablehistoric',
			url: '/costtablehistoric?version',
			component: 'costTableHistoric',
      params: {
        version: {
          type: 'int',
          squash: true,
          dynamic: true
        }
      },
			resolve: {
        /* @ngInject */
				idplan: function($transition$) {
          return $transition$.params().id;
        },
        /* @ngInject */
        version: function($transition$) {
          return $transition$.params().version;
        }
			}
		},
    {
			name: 'plan.historic.decisiontreehistoric',
			url: '/decisiontreehistoric/{id}',
			component: 'decisionTreeDetailHistoric',
      params: {
        idDecisionTree: {
          type: 'int',
          squash: true,
          dynamic: true
        }
      },
			resolve: {
				/* @ngInject */
				idDecisionTree: function($transition$) {
          return $transition$.params().idDecisionTree;
        },
        /* @ngInject */
        entity: (DecisionTree, $transition$, entityInjector) =>
					entityInjector(DecisionTree, $transition$.params().idDecisionTree, {})

      }
		},
		{
			name: 'plan.duplicate',
			url: '/{id}',
			component: 'planDetail',
			resolve: {
				/* @ngInject */
        entity: (Plan, $transition$, entityInjector) => {
					let planDetail = entityInjector(Plan, $transition$.params().id, {});

					return planDetail.then(function(response) {
              response.id = '';
              response.name = '';
              return response;
						});

        }

			}
		},
		{
			name: 'plan.result',
			url:
				'/{id}/result?milestonePage&milestoneSize&yearPage&yearSize&direction&lanes&metric&beforeBuilt',
			component: 'planResult',
			params: {
				milestonePage: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				milestoneSize: {
					value: PAGE_SIZES[3],
					type: 'int',
					squash: true,
					dynamic: true
				},
				yearSize: {
					value: SMALL_PAGE_SIZES[1],
					type: 'int',
					squash: true,
					dynamic: true
				},
				yearPage: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				direction: {
					value: null,
					squash: true,
					dynamic: true
				},
				lanes: {
					array: true,
					value: [],
					type: 'int',
					squash: true,
					dynamic: true
				},
				metric: {
					value: null,
					squash: true,
					dynamic: true
				},
				beforeBuilt: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allMetrics: Metadata =>
					Metadata.getMetrics({ measurement: 'KPI', view: 'ANALYSIS' }),
				/* @ngInject */
				entity: (Plan, $transition$, entityInjector) =>
					entityInjector(Plan, $transition$.params().id, {})
			}
		},
		{
			name: 'plan.chartLine',
			url:
				'/{id}/resultChartLine?milestonePage&milestoneSize&yearPage&yearSize&direction&lanes&metric&chartYear&beforeBuilt&years',
			component: 'planResultChartLine',
			params: {
				milestonePage: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				milestoneSize: {
					value: PAGE_SIZES[3],
					type: 'int',
					squash: true,
					dynamic: true
				},
				yearSize: {
					value: SMALL_PAGE_SIZES[1],
					type: 'int',
					squash: true,
					dynamic: true
				},
				yearPage: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				direction: {
					value: null,
					squash: true,
					dynamic: true
				},
				lanes: {
					array: true,
					value: [],
					type: 'int',
					squash: true,
					dynamic: true
				},
				metric: {
					value: null,
					squash: true,
					dynamic: true
				},
				beforeBuilt: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				years: {
					value: [],
					type: 'int',
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allMetrics: Metadata =>
					Metadata.getMetrics({ measurement: 'KPI', view: 'ANALYSIS' }),
				/* @ngInject */
				entity: (Plan, $transition$, entityInjector) =>
					entityInjector(Plan, $transition$.params().id, {})
			}
		},
		{
			name: 'plan.overview',
			url: '/{planId}/overviews/{overviewId}',
			component: 'planOverviewWrapper'
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
