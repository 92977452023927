import app from '@/app';

var component = {
	templateUrl: '/app/account/password/password.html',
	controller: Controller,
	bindings: {
		$transition$: '<'
	}
};

/* @ngInject */
function Controller(Auth, Principal) {
	var $ctrl = this;

	$ctrl.$onInit = () => {
		$ctrl.doNotMatch = null;
		$ctrl.error = null;
		$ctrl.success = null;
	};

	Principal.identity().then(account => ($ctrl.account = account));

	$ctrl.changePassword = () => {
		$ctrl.doNotMatch = null;
		$ctrl.error = null;
		if ($ctrl.password !== $ctrl.confirmPassword) {
			$ctrl.doNotMatch = 'ERROR';
		} else {
			Auth.changePassword($ctrl.password)
				.then(() => ($ctrl.success = 'OK'))
				.catch(() => {
					$ctrl.success = null;
					$ctrl.error = 'ERROR';
				});
		}
	};
}

app.component('password', component);
