import app from '@/app';
import templateUrl from './sorter2.html';

const component = {
	templateUrl,
	transclude: true,
	require: {
		parent: '^wfTableHolder'
	},
	bindings: {
		by: '@'
	}
};

app.component('wfSorter2', component);
