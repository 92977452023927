import app from '@/app';
import templateUrl from './gapIdentification-detail.html';

/* @ngInject */
function Controller($controller, GapIdentification, ConstantSet, Principal) {
	var $ctrl = this;

	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: GapIdentification,
		type: 'gapIdentification'
	});

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	$ctrl.gapIdentificationSave = () => {
    $ctrl.entity.rateType = 'FIXED';
		$ctrl.entity.trafficGrowthRate = 1;
		$ctrl.entity.planHorizon = 1;
		$ctrl.entity.plantype = 'GAPS';
		$ctrl.entity.requestedYears = 0;
		$ctrl.save();
	};

	$ctrl.onRoadSelected = road => {
		$ctrl.entity.road = road;
		$ctrl.entity.headMilestone = road.headMilestone;
		$ctrl.entity.tailMilestone = road.tailMilestone;
	};

	$ctrl.onOrganizationChange = organization => {
		$ctrl.entity.road = null;
		$ctrl.entity.headMilestone = null;
		$ctrl.entity.tailMilestone = null;
		$ctrl.entity.campaigns = null;
	};

	$ctrl.onRoadTypeChange = roadtype => {
		$ctrl.entity.road = null;
		$ctrl.entity.headMilestone = null;
		$ctrl.entity.tailMilestone = null;
		$ctrl.entity.campaigns = null;
	};

	$ctrl.queryConstantSets = query => {
		$ctrl.loading = true;
		return ConstantSet.query({ query: query, size: 10, sort: 'name,asc' }, data => {
			$ctrl.allConstantSets = data;
			$ctrl.loading = false;
		}).$promise;
	};

	$ctrl.byOrganization = value =>
		$ctrl.entity.organization && value.organization.id == $ctrl.entity.organization.id;

	$ctrl.byOwner = value =>
		$ctrl.entity.organization && value.owner.id == $ctrl.entity.organization.id;
}

app.component('gapIdentificationDetail', {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^gapIdentificationSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<'
	}
});
