import app from '@/app';

var component = {
	templateUrl: '/app/components/emptible/emptible.html',
	transclude: true,
	bindings: {
		entities: '<'
	}
};

app.component('wfEmptible', component);
