import app from '@/app';

app.factory('Sessions', Sessions);

/* @ngInject */

function Sessions($resource) {
	return $resource(
		'api/account/sessions/:series',
		{},
		{
			getAll: { method: 'GET', isArray: true }
		}
	);
}
