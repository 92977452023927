import app from '@/app';
import angular from 'angular';

var component = {
	templateUrl: '/app/components/dialog/warnIqd/warn-dialog.html',
	controller: Controller,
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	}
};

/* @ngInject */
function Controller() {
	var $ctrl = this;

	$ctrl.cancel = function() {
		$ctrl.dismiss({ $value: 'cancel' });
	};
}

app.component('warnIqdDialog', component);
