import app from '@/app';

/* @ngInject */
function Resource($resource) {
	return $resource(
		'/api/planhistoric/:idplan',
		{ id: '@idplan' },
		{
			query: { method: 'GET'}
		}
	);
}

app.factory('PlanHistoric', Resource);

