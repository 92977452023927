import app from '@/app';
import templateUrl from './cost-table-button.html';


  app.component('prCostTableButton', {
    templateUrl,
    bindings: {
      type: '@',
      state: '@',
      params: '<'
    }
  });
