import app from '@/app';
import templateUrl from './delete-button.html';

/* @ngInject */
function DeleteButtonController(DialogService) {
	const $ctrl = this;
	$ctrl.confirm = () => {
	    if(!$ctrl.readonly) {
            DialogService.confirmDelete(
                {
                    type: $ctrl.type,
                    count: $ctrl.count,
                    named: $ctrl.named
                },
                $ctrl.onClick.bind($ctrl)
            );
		}
	};
}

app.component('prDeleteButton', {
	templateUrl,
	controller: DeleteButtonController,
	bindings: {
		type: '@',
		named: '<',
		count: '<',
		readonly: '<',
		onClick: '&'
	}
});
