import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider) {
	$stateProvider.state({
		name: 'app',
		abstract: true,
		resolve: {
			/* @ngInject */
			authorize: Auth => Auth.authorize(),
			/* @ngInject */
			translatePartialLoader: ($translate, $translatePartialLoader) => {
				$translatePartialLoader.addPart('global');
				$translate.refresh();
			}
		}
	});
}

app.config(stateConfig);
