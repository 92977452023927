import app from '@/app';
import templateUrl from './solution-search.html';

/* @ngInject */
function Controller($controller, Solution, Principal) {
	const $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractEntitySearchController', {
		$ctrl,
		resource: Solution,
		type: 'solution'
	});
}

const component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<'
	}
};

app.component('solutionSearch', component);
