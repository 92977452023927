import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider, DEFAULT_LANGUAGE) {
	const states = [
		stateHelperProvider.search({
			name: 'user',
			parent: 'admin',
			url: '/users',
			component: 'userSearch',
			data: {
				viewAuthorities: ['USERS_READ'],
				editAuthorities: ['USERS_WRITE']
			}
		}),
		{
			name: 'user.detail',
			url: '/{id}',
			component: 'userDetail',
			resolve: {
				/* @ngInject */
				entity: (User, $transition$, entityInjector) =>
					entityInjector(User, $transition$.params().id, {
						activated: true,
						language: DEFAULT_LANGUAGE,
						timezone: 'America/Sao_Paulo'
					}),
				/* @ngInject */
				allTimezones: $http => $http.get('/api/timezones').then(response => response.data)
			}
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
