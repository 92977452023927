import React, { Component } from 'react';
import { Chart, ChartConfiguration } from 'chart.js';

interface IAbstractChartProps {
	height?: number;
	width?: number;
	options: ChartConfiguration;
	onClick?: React.MouseEventHandler;
}

Chart.plugins.register({
	beforeDraw: chart => {
		var ctx = chart.ctx;
		ctx.save();
		ctx.fillStyle = 'rgb(240, 240, 240)';
		ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height);
		ctx.restore();
	}
});

export default class AbstractChart extends Component<IAbstractChartProps> {
	canvas: HTMLCanvasElement;

	chart: Chart;

	constructor(props: IAbstractChartProps) {
		super(props);
		this.canvas = null;
		this.chart = null;
		this.ref = this.ref.bind(this);
	}

	ref(canvas: HTMLCanvasElement) {
		this.canvas = canvas;
	}

	componentDidMount() {
		this.chart = new Chart(this.canvas.getContext('2d'), this.props.options);
	}

	componentWillUnmount() {
		this.chart.destroy();
	}

	render() {
		const { height, width } = this.props;
		return <canvas ref={this.ref} height={height} width={width} onClick={this.props.onClick} />;
	}
}
