import app from '@/app';

app.directive('jhxSort', jhSort);

function jhSort() {
	var directive = {
		restrict: 'A',
		scope: {
			predicate: '=jhSort',
			ascending: '=',
			callback: '&'
		},
		controller: SortController,
		controllerAs: 'vm',
		bindToController: true
	};

	return directive;
}

/* @ngInject */
function SortController($scope, $element) {
	var vm = this;

	var sortIcon = 'glyphicon-sort';
	var sortAsc = 'glyphicon-sort-by-attributes';
	var sortDesc = 'glyphicon-sort-by-attributes-alt';

	vm.sort = function(field) {
		if (field !== vm.predicate) {
			vm.ascending = true;
		} else {
			vm.ascending = !vm.ascending;
		}
		vm.predicate = field;
		$scope.$apply();
		vm.callback();
	};

	vm.triggerApply = function(values) {
		resetClasses();
		if (values) {
			applyClass($element.find("th[jh-sort-by='" + values[0] + "']"));
		}

		function resetClasses() {
			var allThIcons = $element.find('.jh-sort-icon');
			allThIcons.removeClass(sortAsc + ' ' + sortDesc);
			allThIcons.addClass(sortIcon);
		}

		function applyClass(element) {
			var thisIcon = element.find('.jh-sort-icon');
			resetClasses();
			if (vm.ascending) {
				thisIcon.removeClass(sortIcon + ' ' + sortDesc);
				thisIcon.addClass(sortAsc);
			} else {
				thisIcon.removeClass(sortIcon + ' ' + sortAsc);
				thisIcon.addClass(sortDesc);
			}
		}
	};

	$scope.$watchGroup(['vm.predicate', 'vm.ascending'], vm.triggerApply);
	vm.triggerApply();
}
