import app from '@/app';
import templateUrl from './road-search.html';

/* @ngInject */
function Controller($controller, Road, $state, $http, Principal) {
	const $ctrl = this;
	$controller('AbstractEntitySearchController', {
		$ctrl,
		resource: Road,
		type: 'road'
	});

  Principal.identity().then(account => {
    $ctrl.currentAccount = account;
    })

	$ctrl.onQuerySearchRoad = query => {
		$http
			.get('/api/roads', {
				params: { page: 0, query: query.query, roadtype: query.roadtype }
			})
			.then(function(response) {
				$ctrl.entities = response.data.content;
			});
	};

	$ctrl.exportLinkRoadSegments = '/api/roads/geometry/excel';

}

const component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<',
		allOrganizations: '<'
	}
};

app.component('roadSearch', component);
