import app from '@/app';
import templateUrl from './mechanical-wrapper.html';
import Mechanical from './mechanical';
import { react2angular } from 'react2angular';

app.component('mechanical', react2angular(Mechanical, []));

/* @ngInject */
function MechanicalController($controller, $scope, Principal) {
	var $ctrl = this;
	$controller('AbstractController', { $ctrl });

	$ctrl.transition = params => {};

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

}

const component = {
	templateUrl,
	controller: MechanicalController,
	bindings: {
		$transition$: '<'
	}
};

app.component('mechanicalWrapper', component);
