import app from '@/app';

/* @ngInject */
function controller($controller, $ctrl, $state, ITEMS_PER_PAGE) {
	$controller('AbstractController', { $ctrl });

	var transition = $ctrl.transition;
	$ctrl.transition = params => {
		transition(params);
		$ctrl.page = params.page || 0;
		$ctrl.size = params.size || ITEMS_PER_PAGE;
		$ctrl.sort = params.sort;
	};

	$ctrl.onSortChange = sort => $state.go($state.current, { sort: sort });

	$ctrl.onPageChange = (page, size) =>
		$state.go($state.current, { page: page, size: size || $ctrl.size });

	$ctrl.onPageSortChange = (page, size, sort) =>
		$state.go($state.current, { page: page, size: size, sort: sort });
}

app.controller('AbstractPageSortController', controller);
