import app from '@/app';
import templateUrl from './road-detail.html';

/* @ngInject */
function Controller($controller, Road, Principal) {
	const $ctrl = this;
	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: Road,
		type: null
	});

  Principal.identity().then(account => {
    $ctrl.currentAccount = account;
    })

	$ctrl.addChild('Segment', 'segments', 'roadForm');
	$ctrl.addChild('Accessory', 'accessories', 'roadForm');
}

const component = {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^roadSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<'
	}
};

app.component('roadDetail', component);