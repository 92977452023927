import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, stateHelperProvider) {
	var states = [
		stateHelperProvider.search({
			name: 'growth-rate',
			component: 'growthRateSearch',
			parent: 'entity',
			data: {
				viewAuthorities: ['ENTITIES_READ'],
				editAuthorities: ['ENTITIES_WRITE']
			}
		}),
		{
			name: 'growth-rate.detail',
			url: '/{id}',
			component: 'growthRateDetail',
			resolve: {
        /* @ngInject */
        entity: (GrowthRate, $transition$, entityInjector) =>
        entityInjector(GrowthRate, $transition$.params().id, { items: [] }),

          /* @ngInject */
				idGrowthRate: ($transition$) => ($transition$.params().idGrowthRate)
			}
		},
    {
			name: 'growth-rate.result',
			url: '/{id}/result?idGrowthRate',
      component: 'growthRateResult',
      params: {
				idGrowthRate: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				entity: (GrowthRate, $transition$, entityInjector) =>
        entityInjector(GrowthRate, $transition$.params().id, { items: [] }),
			}
		},
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);