import app from '@/app';
import templateUrl from './home.html';

/* @ngInject */
function Controller($scope, Principal, LoginService) {
	var $ctrl = this;

	$ctrl.account = null;
	$ctrl.isAuthenticated = Principal.isAuthenticated;
	$ctrl.login = LoginService.open;

	$scope.$on('authenticationSuccess', function() {
		getAccount();
	});

	getAccount();

	function getAccount() {
		Principal.identity().then(account => {
			$ctrl.account = account;
		});
	}
}

var component = {
	templateUrl,
	controller: Controller
};

app.component('home', component);
