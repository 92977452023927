import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider) {
	const states = [
		{
			name: 'mechanical',
			parent: 'analysis',
			url: '/mechanical-analysis',
			component: 'mechanicalWrapper',
			data: {},
			params: {}
		}
	];
	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
