import app from '@/app';

/* @ngInject */
function Hs($resource) {
	return $resource(
		'api/hss/:id',
		{ id: '@id' },
		{
			query: { method: 'GET' },
			purge: { method: 'DELETE' }
		}
	);
}

app.factory('Hs', Hs);
