import app from '@/app';
import angular from 'angular';

/* @ngInject */
function Principal($q, User) {
	var identity;

	this.authenticate = id => {
		identity = id;
	};

	this.hasAuthorities = authorities =>
		identity && authorities.some(it => identity.authorities.includes(it));

	this.identity = (force = false) => {
		if (!force && identity) {
			return $q.resolve(identity);
		} else {
			return User.get({ id: 'current' }).$promise.then(
				user => {
					identity = user;
					return identity;
				},
				() => {
					identity = null;
					return identity;
				}
			);
		}
	};

	this.isAuthenticated = () => !!identity;
}

app.service('Principal', Principal);
