import app from '@/app';

/* @ngInject */
function authExpiredInterceptor($rootScope, $q, $injector, $state) {
	this.responseError = function(response) {
    var to = $rootScope.toState;
		if (
			response.status === 401 &&
			response.data.path &&
			(response.data.path.indexOf('/api/account') === -1 ||
			response.data.path.indexOf('/api/users/current') === -1)
		) {
			var Auth = $injector.get('Auth');
			
			var params = $rootScope.toStateParams;
			Auth.logout();
      /*Error: 'Auth.storePreviousState' function is unknown*/ 
			//if (to.name !== 'accessdenied') {
			//	Auth.storePreviousState(to.name, params);
			//}
			var LoginService = $injector.get('LoginService');
			LoginService.open();
		} else if ( (response.status === 401 ||  response.status === 403) &&  to.url.indexOf('/reset') ===-1 ){
      $state.go('home');            
    }
		return $q.reject(response);
	};
}

app.service('authExpiredInterceptor', authExpiredInterceptor);
