import app from '@/app';

/* @ngInject */

function paginationConfig(uibPaginationConfig, ITEMS_PER_PAGE) {
	uibPaginationConfig.itemsPerPage = ITEMS_PER_PAGE;
	uibPaginationConfig.maxSize = 5;
	uibPaginationConfig.boundaryLinks = true;
	uibPaginationConfig.firstText = '«';
	uibPaginationConfig.previousText = '‹';
	uibPaginationConfig.nextText = '›';
	uibPaginationConfig.lastText = '»';
}

app.config(paginationConfig);
