import app from '@/app';
import templateUrl from './solution-detail.html';

/* @ngInject */
function Controller($controller, $timeout, $scope, Solution, Principal, $http) {
	const $ctrl = this;
	
  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

  $ctrl.clearUiSelect = function($event, $select) {
    $event.stopPropagation();
    $select.selected = null;
    $select.search = null;
    $ctrl.entity.budgetLimit= null;

  };

  $controller('AbstractEntityDetailController', {
		$ctrl,
		resource: Solution,
		type: 'solution'
	});

	$ctrl.idgen = -1;

	$ctrl.addItem = () => $ctrl.entity.items.push({ id: --$ctrl.idgen });

	$ctrl.dirtyItems = () => {
		$timeout(() => $ctrl.setDirty($ctrl.solutionForm.items));
	};

	$ctrl.removeItem = id => {
		$ctrl.entity.items = $ctrl.entity.items.filter(it => it.id != id);
		$ctrl.dirtyItems();
		$ctrl.items = true;
	};

  $ctrl.hasStepGrade = it => {
    if(it.shoulderSolution){
		  return it.grade != it.shoulderSolution.grade;
    }
	};

  this.$onInit = function() {
    $http.get('api/solutions/solutionsList/').then(function(response) {
      $ctrl.allSolutions = response.data.filter(it => it.id != $ctrl.entity.id);
    })

  }
}

const component = {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^solutionSearch'
	},
	bindings: {
		$transition$: '<',
		allMetrics: '<',
		entity: '<'
	}
};

app.component('solutionDetail', component);
