import app from '@/app';
import angular from 'angular';

/* @ngInject */
function resource($resource) {
	return $resource(
		'/api/systemparameters/:id',
		{ id: '@id' },
		{
			get: {
				method: 'GET'
			},
			query: { method: 'GET' },
			all: { method: 'GET', params: { id: 'all' }, isArray: true },
			update: { method: 'PUT' }
		}
	);
}

app.factory('SystemParameters', resource);