import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider) {
	$stateProvider.state('system', {
		abstract: true,
		parent: 'app'
	});
}

app.config(stateConfig);
