import app from '@/app';
import angular from 'angular';
import templateUrl from './asBuilt-table.html';

/* @ngInject */
function Controller($controller, $httpParamSerializer, $http, DialogService, Principal) {
	var $ctrl = this;
	$controller('AbstractPageSortController', { $ctrl });

	var transition = $ctrl.transition;

  $ctrl.currentAccount = null;

  Principal.identity().then(account => {
    $ctrl.currentAccount = account;
    })

	$ctrl.transition = params => {
		transition(params);

		$ctrl.params = angular.extend({}, params);
		$ctrl.startDate = params.startDate;
		$ctrl.segmentLength = params.segmentLength;
		$ctrl.endDate = params.endDate;
		$ctrl.resource = $ctrl.parent.allResources["BUILT"];
		$ctrl.measurement = $ctrl.parent.allMeasurements.find(it => it.code == params.measurement);
		$ctrl.metrics = $ctrl.parent.allMetrics.filter(it => it.measurement == params.measurement);

		// $http
		//     .get('/api/builts/query', {params: params})
		//     .then(function(response) {
		//       $ctrl.entities = response.data.content;
		//       $ctrl.count = response.data.totalElements;
		//       $ctrl.exportLink =
		//         '/api/' + $ctrl.measurement.name + 's/excel?' + $httpParamSerializer($ctrl.params);
		// });

		$ctrl.resource.query(params, data => {
			$ctrl.entities = data.content;
			$ctrl.count = data.totalElements;
			$ctrl.exportLink =
				'/api/' + $ctrl.measurement.name + 's/excel?' + $httpParamSerializer($ctrl.params);
		});

		const query = _.pick([
			'headMilestone',
			'tailMilestone',
			'organization',
			'direction',
			'lanes',
			'road',
			'startDate',
			'endDate',
			'segmentLength'
		])($ctrl.params);

		$ctrl.exportLinkP21 = '/api/builts/results/excel?' + $httpParamSerializer(query);
	};

	$ctrl.remove = () =>
		$ctrl.resource.purge($ctrl.params, () => $ctrl.transition($ctrl.params)).$promise;
}

app.component('asBuiltTable', {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^asBuiltSearch'
	},
	bindings: {
		asBuilt: '<',
		$transition$: '<'
	}
});
