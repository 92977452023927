import React from 'react';

function SelectData(props) {
	return {
		layer1ThicknessVectorType1: [
			{
				value: '5',
				name: '5'
			},
			{
				value: '6',
				name: '6'
			},
			{
				value: '7',
				name: '7'
			},
			{
				value: '8',
				name: '8'
			},
			{
				value: '9',
				name: '9'
			},
			{
				value: '10',
				name: '10'
			},
			{
				value: '11',
				name: '11'
			},

			{
				value: '12',
				name: '12'
			},
			{
				value: '13',
				name: '13'
			},
			{
				value: '14',
				name: '14'
			},
			{
				value: '15',
				name: '15'
			},
			{
				value: '16',
				name: '16'
			},
			{
				value: '17',
				name: '17'
			},
			{
				value: '18',
				name: '18'
			},
			{
				value: '19',
				name: '19'
			},
			{
				value: '20',
				name: '20'
			}
		],
		layer2ThicknessVectorType1: [
			{
				value: '15',
				name: '15'
			},
			{
				value: '16',
				name: '16'
			},
			{
				value: '17',
				name: '17'
			},
			{
				value: '18',
				name: '18'
			},
			{
				value: '19',
				name: '19'
			},
			{
				value: '20',
				name: '20'
			},
			{
				value: '25',
				name: '25'
			},
			{
				value: '30',
				name: '30'
			}
		],
		layer3ThicknessVectorType1: [
			{
				value: '15',
				name: '15'
			},
			{
				value: '16',
				name: '16'
			},
			{
				value: '17',
				name: '17'
			},
			{
				value: '18',
				name: '18'
			},
			{
				value: '19',
				name: '19'
			},
			{
				value: '20',
				name: '20'
			},
			{
				value: '25',
				name: '25'
			},
			{
				value: '30',
				name: '30'
			},
			{
				value: '35',
				name: '35'
			},
			{
				value: '40',
				name: '40'
			},

			{
				value: '45',
				name: '45'
			},
			{
				value: '50',
				name: '50'
			},
			{
				value: '55',
				name: '55'
			},
			{
				value: '60',
				name: '60'
			}
		],
		subgradeThicknessVectorType1: [
			{
				value: '0',
				name: '0'
			}
		],

		layer1PoissonVectorType1: [
			{
				value: '0.30',
				name: '0,30'
			}
		],
		layer2PoissonVectorType1: [
			{
				value: '0.35',
				name: '0,35'
			}
		],
		layer3PoissonVectorType1: [
			{
				value: '0.35',
				name: '0,35'
			}
		],
		subgradePoissonVectorType1: [
			{
				value: '0.40',
				name: '0,40'
			}
		],

		layer1ModuleVectorType1: [
			{
				value: '30000',
				name: '30000'
			},
			{
				value: '35000',
				name: '35000'
			},
			{
				value: '40000',
				name: '40000'
			},
			{
				value: '45000',
				name: '45000'
			}
		],
		layer2ModuleVectorType1: [
			{
				value: '1000',
				name: '1000'
			},
			{
				value: '1500',
				name: '1500'
			},
			{
				value: '2000',
				name: '2000'
			},
			{
				value: '2500',
				name: '2500'
			},
			{
				value: '3000',
				name: '3000'
			},
			{
				value: '3500',
				name: '3500'
			},
			{
				value: '4000',
				name: '4000'
			},
			{
				value: '4500',
				name: '4500'
			}
		],
		layer3ModuleVectorType1: [
			{
				value: '1000',
				name: '1000'
			},
			{
				value: '1500',
				name: '1500'
			},
			{
				value: '2000',
				name: '2000'
			},
			{
				value: '2500',
				name: '2500'
			},
			{
				value: '3000',
				name: '3000'
			},
			{
				value: '3500',
				name: '3500'
			},
			{
				value: '4000',
				name: '4000'
			},
			{
				value: '4500',
				name: '4500'
			}
		],
		subgradeModuleVectorType1: [
			{
				value: '400',
				name: '400'
			},
			{
				value: '500',
				name: '500'
			},
			{
				value: '600',
				name: '600'
			},
			{
				value: '700',
				name: '700'
			},
			{
				value: '800',
				name: '800'
			},
			{
				value: '900',
				name: '900'
			},
			{
				value: '1000',
				name: '1000'
			},
			{
				value: '1100',
				name: '1100'
			},
			{
				value: '1200',
				name: '1200'
			},
			{
				value: '1300',
				name: '1300'
			},
			{
				value: '1400',
				name: '1400'
			},
			{
				value: '1500',
				name: '1500'
			},
			{
				value: '1600',
				name: '1600'
			},
			{
				value: '1700',
				name: '1700'
			},
			{
				value: '1800',
				name: '1800'
			},
			{
				value: '2000',
				name: '2000'
			}
		],

		// Semirrigido -----------------------------------------------

		layer1ThicknessVectorType2: [
			{
				value: '5',
				name: '5'
			},
			{
				value: '6',
				name: '6'
			},
			{
				value: '7',
				name: '7'
			},
			{
				value: '8',
				name: '8'
			},
			{
				value: '9',
				name: '9'
			},
			{
				value: '10',
				name: '10'
			},
			{
				value: '11',
				name: '11'
			},

			{
				value: '12',
				name: '12'
			},
			{
				value: '13',
				name: '13'
			},
			{
				value: '14',
				name: '14'
			},
			{
				value: '15',
				name: '15'
			},
			{
				value: '16',
				name: '16'
			},
			{
				value: '17',
				name: '17'
			},
			{
				value: '18',
				name: '18'
			},
			{
				value: '19',
				name: '19'
			},
			{
				value: '20',
				name: '20'
			}
		],
		layer2ThicknessVectorType2: [
			{
				value: '15',
				name: '15'
			},
			{
				value: '16',
				name: '16'
			},
			{
				value: '17',
				name: '17'
			},
			{
				value: '18',
				name: '18'
			},
			{
				value: '19',
				name: '19'
			},
			{
				value: '20',
				name: '20'
			},
			{
				value: '21',
				name: '21'
			},
			{
				value: '22',
				name: '22'
			},
			{
				value: '25',
				name: '25'
			},
			{
				value: '30',
				name: '30'
			},
			{
				value: '35',
				name: '35'
			},
			{
				value: '40',
				name: '40'
			}
		],
		layer3ThicknessVectorType2: [
			{
				value: '15',
				name: '15'
			},
			{
				value: '18',
				name: '18'
			},
			{
				value: '20',
				name: '20'
			},
			{
				value: '25',
				name: '25'
			},
			{
				value: '30',
				name: '30'
			},
			{
				value: '35',
				name: '35'
			},
			{
				value: '40',
				name: '40'
			},
			{
				value: '45',
				name: '45'
			},
			{
				value: '50',
				name: '50'
			},
			{
				value: '55',
				name: '55'
			},
			{
				value: '60',
				name: '60'
			}
		],
		subgradeThicknessVectorType2: [
			{
				value: '0',
				name: '0'
			}
		],

		layer1PoissonVectorType2: [
			{
				value: '0.30',
				name: '0,30'
			}
		],
		layer2PoissonVectorType2: [
			{
				value: '0.20',
				name: '0,20'
			}
		],
		layer3PoissonVectorType2: [
			{
				value: '0.35',
				name: '0,35'
			}
		],
		subgradePoissonVectorType2: [
			{
				value: '0.40',
				name: '0,40'
			}
		],

		layer1ModuleVectorType2: [
			{
				value: '30000',
				name: '30000'
			},
			{
				value: '35000',
				name: '35000'
			},
			{
				value: '40000',
				name: '40000'
			},
			{
				value: '45000',
				name: '45000'
			}
		],
		layer2ModuleVectorType2: [
			{
				value: '50000',
				name: '50000'
			},
			{
				value: '60000',
				name: '60000'
			},
			{
				value: '70000',
				name: '70000'
			},
			{
				value: '80000',
				name: '80000'
			},
			{
				value: '90000',
				name: '90000'
			},
			{
				value: '100000',
				name: '100000'
			},
			{
				value: '110000',
				name: '110000'
			},
			{
				value: '120000',
				name: '120000'
			},
			{
				value: '150000',
				name: '150000'
			},
			{
				value: '180000',
				name: '180000'
			}
		],
		layer3ModuleVectorType2: [
			{
				value: '1000',
				name: '1000'
			},
			{
				value: '1500',
				name: '1500'
			},
			{
				value: '2000',
				name: '2000'
			},
			{
				value: '2500',
				name: '2500'
			},
			{
				value: '3000',
				name: '3000'
			},
			{
				value: '3500',
				name: '3500'
			},
			{
				value: '4000',
				name: '4000'
			},
			{
				value: '4500',
				name: '4500'
			}
		],
		subgradeModuleVectorType2: [
			{
				value: '400',
				name: '400'
			},
			{
				value: '500',
				name: '500'
			},
			{
				value: '600',
				name: '600'
			},
			{
				value: '700',
				name: '700'
			},
			{
				value: '800',
				name: '800'
			},
			{
				value: '900',
				name: '900'
			},
			{
				value: '1000',
				name: '1000'
			},
			{
				value: '1100',
				name: '1100'
			},
			{
				value: '1200',
				name: '1200'
			},
			{
				value: '1300',
				name: '1300'
			},
			{
				value: '1400',
				name: '1400'
			},
			{
				value: '1500',
				name: '1500'
			},
			{
				value: '1600',
				name: '1600'
			},
			{
				value: '1700',
				name: '1700'
			},
			{
				value: '1800',
				name: '1800'
			},
			{
				value: '2000',
				name: '2000'
			}
		],

		// Invertido -----------------------------------------------

		layer1ThicknessVectorType3: [
			{
				value: '5',
				name: '5'
			},
			{
				value: '6',
				name: '6'
			},
			{
				value: '7',
				name: '7'
			},
			{
				value: '8',
				name: '8'
			},
			{
				value: '9',
				name: '9'
			},
			{
				value: '10',
				name: '10'
			},
			{
				value: '11',
				name: '11'
			},

			{
				value: '12',
				name: '12'
			},
			{
				value: '13',
				name: '13'
			},
			{
				value: '14',
				name: '14'
			},
			{
				value: '15',
				name: '15'
			},
			{
				value: '16',
				name: '16'
			},
			{
				value: '17',
				name: '17'
			},
			{
				value: '18',
				name: '18'
			},
			{
				value: '19',
				name: '19'
			},
			{
				value: '20',
				name: '20'
			}
		],
		layer2ThicknessVectorType3: [
			{
				value: '12',
				name: '12'
			},
			{
				value: '13',
				name: '13'
			},
			{
				value: '14',
				name: '14'
			},
			{
				value: '15',
				name: '15'
			},
			{
				value: '16',
				name: '16'
			},
			{
				value: '17',
				name: '17'
			},
			{
				value: '18',
				name: '18'
			},
			{
				value: '19',
				name: '19'
			},
			{
				value: '20',
				name: '20'
			}
		],
		layer3ThicknessVectorType3: [
			{
				value: '15',
				name: '15'
			},
			{
				value: '16',
				name: '16'
			},
			{
				value: '17',
				name: '17'
			},
			{
				value: '18',
				name: '18'
			},
			{
				value: '19',
				name: '19'
			},
			{
				value: '20',
				name: '20'
			},
			{
				value: '21',
				name: '21'
			},
			{
				value: '22',
				name: '22'
			},
			{
				value: '25',
				name: '25'
			}
		],
		subgradeThicknessVectorType3: [
			{
				value: '0',
				name: '0'
			}
		],

		layer1PoissonVectorType3: [
			{
				value: '0.30',
				name: '0,30'
			}
		],
		layer2PoissonVectorType3: [
			{
				value: '0.35',
				name: '0,35'
			}
		],
		layer3PoissonVectorType3: [
			{
				value: '0.20',
				name: '0,20'
			}
		],
		subgradePoissonVectorType3: [
			{
				value: '0.40',
				name: '0,40'
			}
		],

		layer1ModuleVectorType3: [
			{
				value: '30000',
				name: '30000'
			},
			{
				value: '35000',
				name: '35000'
			},
			{
				value: '40000',
				name: '40000'
			},
			{
				value: '45000',
				name: '45000'
			}
		],
		layer2ModuleVectorType3: [
			{
				value: '2000',
				name: '2000'
			},
			{
				value: '2500',
				name: '2500'
			},
			{
				value: '3000',
				name: '3000'
			},
			{
				value: '3500',
				name: '3500'
			},
			{
				value: '4000',
				name: '4000'
			}
		],
		layer3ModuleVectorType3: [
			{
				value: '50000',
				name: '50000'
			},
			{
				value: '60000',
				name: '60000'
			},
			{
				value: '70000',
				name: '70000'
			},
			{
				value: '80000',
				name: '80000'
			},
			{
				value: '90000',
				name: '90000'
			},
			{
				value: '100000',
				name: '100000'
			},
			{
				value: '110000',
				name: '110000'
			},
			{
				value: '120000',
				name: '120000'
			},
			{
				value: '150000',
				name: '150000'
			},
			{
				value: '180000',
				name: '180000'
			}
		],
		subgradeModuleVectorType3: [
			{
				value: '400',
				name: '400'
			},
			{
				value: '500',
				name: '500'
			},
			{
				value: '600',
				name: '600'
			},
			{
				value: '700',
				name: '700'
			},
			{
				value: '800',
				name: '800'
			},
			{
				value: '900',
				name: '900'
			},
			{
				value: '1000',
				name: '1000'
			},
			{
				value: '1100',
				name: '1100'
			},
			{
				value: '1200',
				name: '1200'
			},
			{
				value: '1300',
				name: '1300'
			},
			{
				value: '1400',
				name: '1400'
			},
			{
				value: '1500',
				name: '1500'
			},
			{
				value: '1600',
				name: '1600'
			},
			{
				value: '1700',
				name: '1700'
			},
			{
				value: '1800',
				name: '1800'
			},
			{
				value: '2000',
				name: '2000'
			}
		],

		textWarningP1:
			'O método mecanístico foi concebido de forma a se analisar as tensões, deformações e deslocamentos no intuito de se aperfeiçoar as espessuras a serem executadas. A análise de desempenho das estruturas é feita através da verificação da compatibilidade entre os esforços solicitantes (devidos à aplicação de cargas de tráfego) e os esforços resistentes, intrínsecos de cada material, traduzidos pelas equações de fadiga.',
		textWarningP2:
			'Foi empregado o modelo da teoria de camadas elásticas no cálculo de tensões, deformações e deslocamentos, uma vez que este é relativamente simples e capaz de lidar com as características mais importantes do pavimento. Nesta tarefa, fez-se uso do programa do Elsym5 – Elastic Layered System, que aplica o método das diferenças finitas no cálculo destes parâmetros, produzidos por um eixo de cargas, em qualquer ponto da estrutura do pavimento.',
		textWarningP3:
			'As análises no presente sistema são realizadas com um carregamento composto por 4 rodas com cargas de 2.050 kgf e 5,60 kgf/cm² de pressão cada, posicionadas nos seguintes pontos:',
		textWarningP4:
			'Os deslocamentos, tensões e deformações foram verificados entre as duas primeiras rodas no ponto a 14,4 cm da origem (14,4;0).',
		textWarningP5:
			'Ressalta-se que as estruturas analisadas também são verificadas pelo método do DNER/DNIT, que tem como premissa básica o cálculo da espessura granular equivalente de forma a proteger as camadas subjacentes e o suleito quanto à ruptura por cisalhamento.',
		textWarningP6:
			'Por fim vale se observar que o presente sistema tem como objetivo fornecer uma ferramenta para auxílio no processo de validação e análise das estruturas de projetos ou estudos de alternativas. Dito isto, esta aplicação não deve ser utilizada para substituir qualquer outro programa de análise de tensões e deformações para efeito de dimensionamento de estruturas para projeto de pavimentação.'
	};
}

export default SelectData;
