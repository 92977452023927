import app from '@/app';
import templateUrl from './index-crossing-detail.html';

/* @ngInject */
function Controller(
	$controller,
	IndexCrossing,
	ConstantSet,
	BudgetLimit,
	GrowthRate,
	Principal,
	Project,
	$http,
	$translate
) {
	var $ctrl = this;

	$ctrl.currentAccount = null;

	$ctrl.allRoadtype = ['ROAD', 'STRETCH', 'RIVER'];

	$ctrl.allRateType = ['FIXED', 'VARIABLE'];

	$ctrl.projectType = 'SELECT';

	const indexCrossingMetrics = [
		'KPI_NOISE',
		'KPI_IFI',
		'KPI_VRD',
		'KPI_GN',
		'KPI_SNC',
		'KPI_IGG',
		'KPI_HEAVY_VEHICLE_DAILY_AVERAGE',
		'KPI_USACE_VEHICLE_FACTOR',
		'KPI_USACE_NUMBER',
		'KPI_HS',
		'KPI_ARROW',
		'KPI_DC',
		'KPI_D120',
		'KPI_RADIUS',
		'KPI_TR',
		'KPI_IRI',
		'PAVING_TYPE'
	];

	var projectDefault = { id: null, name: null, status: 'SELECT' };

	$ctrl.onProjectChange = () => {
		switch ($ctrl.projectType) {
			case 'NEW':
				$ctrl.entity.project.name = null;
				$ctrl.entity.project.id = null;
				break;
			case 'SELECT':
				$ctrl.entity.project.name = null;
				$ctrl.entity.project.id = null;
				break;
			case 'OLD':
				$ctrl.entity.project = null;
				break;
			default:
		}
	};

	Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: IndexCrossing,
		type: 'indexCrossing'
	});

	$ctrl.$onInit = () => {
		$ctrl.allMetrics = $ctrl.allMetrics.filter(item =>
			/**item.code != 'KPI_INTERVENTION_YEAR' && */ inFilter(indexCrossingMetrics, item.code)
		);
		$ctrl.model = $ctrl.model || {
			type: 'GROUP',
			operator: 'OR',
			items: $ctrl.entity.items ? $ctrl.entity.items : []
		};
		$ctrl.allPavingTypes = [];

		$http.get('api/projects', { params: { onlyUsed: false } }).then(function(response) {
			$ctrl.allProjects = response;
		});

		if ($ctrl.entity.project == null) {
			$ctrl.entity.project = projectDefault;
		}

		$http.get('api/budgetlimits?query=null').then(function(response) {
			$ctrl.allBudgetLimit = response;
		});
	};

	$ctrl.planSave = () => {
		$ctrl.entity.plantype = 'INDEX_CROSSING';
		$ctrl.entity.items = $ctrl.model.items;
		$ctrl.entity.decisionTree = null;
		$ctrl.entity.planHorizon = 1;
		$ctrl.entity.trafficgrowthrate = 1;
		$ctrl.entity.rateType = 'FIXED';
		$ctrl.entity.requestedYears = 1;
		$ctrl.entity.indexCrossingGroupOperator = $ctrl.entity.indexCrossingGroupOperator
			? $ctrl.entity.indexCrossingGroupOperator
			: 'OR';

		//[{"id":1,"operator":"GT","lhsMetric":"KPI_IRI","lhsValue":null,"rhsMetric":null,"rhsValue":0,"dtype":"SINGLE"}

		var conditions = "";

		for (var i = 0; i < $ctrl.model.items.length; i++) {
		//for (var item of $ctrl.model.items) {
			var item = $ctrl.model.items[i];
			var lhsMetric = "";
			var rhsMetric = "";

			if(item.lhsMetric){
				lhsMetric = $ctrl.allMetrics.find(metric => metric.code === item.lhsMetric);
				conditions = conditions + $translate.instant(lhsMetric.name);
			}
			conditions = conditions
			+ " "
			+ $translate.instant("operators." + item.operator)
			+ " ";

			if(item.rhsMetric){
				rhsMetric = $ctrl.allMetrics.find(metric => metric.code === item.rhsMetric);
				conditions = conditions + $translate.instant(rhsMetric.name);
			}

			conditions = conditions
			+ " "
			+ item.rhsValue
			+ " ";

			if(i != $ctrl.model.items.length - 1){
				conditions = conditions + $translate.instant("operators." + $ctrl.entity.indexCrossingGroupOperator) + " ";
			}

		}

		$ctrl.entity.conditions = conditions.replace("null", "").replace("null", "");

		$ctrl.save();
	};

	$ctrl.onRoadSelected = road => {
		$ctrl.entity.road = road;
		$ctrl.entity.headMilestone = road.headMilestone;
		$ctrl.entity.tailMilestone = road.tailMilestone;
	};

	$ctrl.onOrganizationChange = organization => {
		$ctrl.entity.road = null;
		$ctrl.entity.headMilestone = null;
		$ctrl.entity.tailMilestone = null;
		$ctrl.entity.campaigns = null;
	};

	$ctrl.onRoadTypeChange = roadtype => {
		$ctrl.entity.road = null;
		$ctrl.entity.headMilestone = null;
		$ctrl.entity.tailMilestone = null;
		$ctrl.entity.campaigns = null;
	};

	$ctrl.queryConstantSets = query => {
		$ctrl.loading = true;
		return ConstantSet.query({ query: query, size: 10, sort: 'name,asc' }, data => {
			$ctrl.allConstantSets = data;
			$ctrl.loading = false;
		}).$promise;
	};

	$ctrl.queryGrowthRates = query => {
		$ctrl.loading = true;
		return GrowthRate.query({ query: query, size: 10, sort: 'name,asc' }, data => {
			$ctrl.allGrowthRates = data;
			$ctrl.loading = false;
		}).$promise;
	};

	$ctrl.queryBudgetLimit = query => {
		if (!query) {
			query = '';
		}
		$ctrl.loading = true;
		return BudgetLimit.query({ query: query, size: 10, sort: 'name,asc' }, data => {
			$ctrl.allBudgetLimit = data;
			$ctrl.loading = false;
		}).$promise;
	};

	$ctrl.byOrganization = value =>
		$ctrl.entity.organization && value.organization.id == $ctrl.entity.organization.id;

	$ctrl.byOwner = value =>
		$ctrl.entity.organization && value.owner.id == $ctrl.entity.organization.id;

	$ctrl.clearUiSelect = function($event, $select) {
		$event.stopPropagation();
		$select.selected = null;
		$select.search = null;
		$ctrl.entity.budgetLimit = null;
	};

	/**Condition filters */
	$ctrl.isPaving = false;

	$ctrl.pavingTypes = [1, 2, 3, 4];

	$ctrl.translatePavingType = value => {
		if (value) {
			switch (value) {
				case 1:
					return 'Flexível';
				case 2:
					return 'Semirrígido';
				case 3:
					return 'Semirrígido Invertido';
				case 4:
					return 'Rígido';
				case '1':
					return 'Flexível';
				case '2':
					return 'Semirrígido';
				case '3':
					return 'Semirrígido Invertido';
				case '4':
					return 'Rígido';
				default:
					return 'Não identificado';
			}
		}
	};

	$ctrl.checkIfIsPaving = lhsMetric => {
		if (lhsMetric == 'PAVING_TYPE') {
			$ctrl.isPaving = true;
		}
	};

	$ctrl.onRhsMetricChange = index => {
		var node = $ctrl.model.items[index];
		if (node.rhsMetric) {
			node.rhsValue = undefined;
		}
	};

	$ctrl.getFactor = code => (code ? $ctrl.allMetrics.find(item => item.code == code).factor : 1);

	$ctrl.addItem = () => {
		if ($ctrl.entity.recalculate == true || $ctrl.entity.id == null) {
			$ctrl.model.items.push({ dtype: 'SINGLE' });
		}
	};

	$ctrl.removeNode = node => {
		$ctrl.model.items = $ctrl.model.items.filter(item => item != node);
	};
	/**Condition filters - end*/

	function inFilter(array, value) {
		if (array.indexOf(value) !== -1) {
			return true;
		} else {
			return false;
		}
	}
}

app.component('indexCrossingDetail', {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^indexCrossingSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<',
		idGrowthRate: '<',
		model: '=',
		allMetrics: '<',
		allCampaigns: '<'
	}
});
