import app from '@/app';
import templateUrl from './pavementPerformance-detail.html';

/* @ngInject */
function Controller($controller, PavementPerformance, ConstantSet, GrowthRate, Principal) {
	var $ctrl = this;

  $ctrl.currentAccount = null;

  $ctrl.allRateType = ["FIXED", "VARIABLE"];
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: PavementPerformance,
		type: 'pavementPerformance'
	});

	$ctrl.pavementPerformanceSave = () => {
		$ctrl.entity.plantype = 'PAVEMENTPERFORMANCE';
		$ctrl.entity.criteriaSet.paved == 'true'
			? $ctrl.entity.planHorizon
			: ($ctrl.entity.planHorizon = 10);
		$ctrl.save();
	};

	$ctrl.onRoadSelected = road => {
		$ctrl.entity.road = road;
		$ctrl.entity.headMilestone = road.headMilestone;
		$ctrl.entity.tailMilestone = road.tailMilestone;
	};

	$ctrl.onOrganizationChange = organization => {
		$ctrl.entity.road = null;
		$ctrl.entity.headMilestone = null;
		$ctrl.entity.tailMilestone = null;
		$ctrl.entity.campaigns = null;
	};

	$ctrl.onRoadTypeChange = roadtype => {
		$ctrl.entity.road = null;
		$ctrl.entity.headMilestone = null;
		$ctrl.entity.tailMilestone = null;
		$ctrl.entity.campaigns = null;
	};

  $ctrl.queryGrowthRates = query => {
		$ctrl.loading = true;
		return GrowthRate.query({ query: query, size: 10, sort: 'name,asc' }, data => {
			$ctrl.allGrowthRates = data;
			$ctrl.loading = false;
		}).$promise;
	};

	$ctrl.queryConstantSets = query => {
		$ctrl.loading = true;
		return ConstantSet.query({ query: query, size: 10, sort: 'name,asc' }, data => {
			$ctrl.allConstantSets = data;
			$ctrl.loading = false;
		}).$promise;
	};

	$ctrl.byOrganization = value =>
		$ctrl.entity.organization && value.organization.id == $ctrl.entity.organization.id;

	$ctrl.byOwner = value =>
		$ctrl.entity.organization && value.owner.id == $ctrl.entity.organization.id;
}

app.component('pavementPerformanceDetail', {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^pavementPerformanceSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<'
	}
});
