import app from '@/app';
import angular from 'angular';

var component = {
	templateUrl: '/app/components/dialog/login/login-dialog.html',
	controller: Controller,
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	}
};

/* @ngInject */
function Controller($rootScope, $state, $timeout, Auth) {
	var $ctrl = this;

	$ctrl.rememberMe = true;

	$ctrl.cancel = function() {
		$ctrl.dismiss({ $value: 'cancel' });
	};

	$ctrl.login = function(event) {
		event.preventDefault();
		Auth.login({
			username: $ctrl.username,
			password: $ctrl.password,
			rememberMe: $ctrl.rememberMe
		})
			.then(function() {
				$ctrl.close({ $value: null });
				if (
					$state.current.name === 'register' ||
					$state.current.name === 'activate' ||
					$state.current.name === 'finishReset' ||
					$state.current.name === 'requestReset'
				) {
					$state.go('home');
				}

				$rootScope.$broadcast('authenticationSuccess');

				// previousState was set in the authExpiredInterceptor before being redirected to login modal.
				// since login is succesful, go to stored previousState and clear previousState
				var previousState = Auth.getPreviousState();
				if (previousState) {
					Auth.resetPreviousState();
					$state.go(previousState.name, previousState.params);
				}
			})
			.catch(function() {
				$ctrl.authenticationError = true;
			});
	};

	$ctrl.register = function() {
		$ctrl.close({ $value: null });
		$state.go('register');
	};

	$ctrl.requestResetPassword = function() {	
    $ctrl.cancel();   
    $state.go('requestReset');	
	};

	$timeout(function() {
		angular.element('#loginForm_username').focus();
	});
}

app.component('loginDialog', component);
