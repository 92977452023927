import app from '@/app';
import templateUrl from './insert-button.html';

app.component('prInsertButton', {
	templateUrl,
	bindings: {
		isDisabled: '<',
		onClick: '&'
	}
});
