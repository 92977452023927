import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngAria from 'angular-aria';
import ngCacheBusterImport from 'angular-cache-buster';
const ngCacheBuster = 'ngCacheBuster';
import ngChartJs from 'angular-chart.js';
import ngCookies from 'angular-cookies';
import ngDynamicLocale from 'angular-dynamic-locale';
import ngFileUpload from 'ng-file-upload';
import ngLoadingBar from 'angular-loading-bar';
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';
import ngStorageImport from 'ngstorage';
const ngStorage = ngStorageImport.name;
import ngTranslate from 'angular-translate';
import 'angular-translate-interpolation-messageformat';
import 'angular-translate-loader-partial';
import 'angular-translate-storage-cookie';
import uiBootstrap from 'angular-ui-bootstrap';
import uiRouter from '@uirouter/angularjs';
import uiSelect from 'ui-select';
import uiValidate from 'angular-ui-validate';
import 'bootstrap/dist/js/bootstrap';

export default angular.module('pavrunner', [
	ngLoadingBar,
	ngChartJs,
	ngAnimate,
	ngAria,
	ngCacheBuster,
	ngCookies,
	ngFileUpload,
	ngResource,
	ngSanitize,
	ngStorage,
	ngTranslate,
	ngDynamicLocale,
	uiBootstrap,
	uiRouter,
	uiSelect,
	uiValidate
]);
