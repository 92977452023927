import app from '@/app';
import templateUrl from './navbar.html';

/* @ngInject */
function Controller($state, Auth, Principal, LoginService) {
	var $ctrl = this;

	$ctrl.isNavbarCollapsed = true;
	$ctrl.isAuthenticated = Principal.isAuthenticated;

	$ctrl.login = login;
	$ctrl.logout = logout;
	$ctrl.toggleNavbar = toggleNavbar;
	$ctrl.collapseNavbar = collapseNavbar;
	$ctrl.$state = $state;

	function login() {
		collapseNavbar();
		LoginService.open();
	}

	async function logout() {
		collapseNavbar();
		await Auth.logout();
		$state.go('home');
	}

	function toggleNavbar() {
		$ctrl.isNavbarCollapsed = !$ctrl.isNavbarCollapsed;
	}

	function collapseNavbar() {
		$ctrl.isNavbarCollapsed = true;
	}
}

app.component('prNavbar', { templateUrl, controller: Controller });
