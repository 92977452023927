import app from '@/app';
import templateUrl from './select.html';

/* @ngInject */
function SelectController($scope) {
	const $ctrl = this;
	$ctrl.items = [];
	$ctrl.$onInit = () => {
		$ctrl.name = $ctrl.name || $ctrl.label || 'select_' + $scope.$id;
	};
}

app.component('wfSelect', {
	templateUrl,
	controller: SelectController,
	transclude: true,
	require: {
		form: '^form'
	},
	bindings: {
		model: '=',
		name: '@',
		label: '@',
		readonly: '<',
		disabled: '<',
		required: '<',
		onChange: '&'
	}
});

/* @ngInject */
function OptionController($translate) {
	const $ctrl = this;
	$ctrl.$onInit = function() {
		$ctrl.select.items.push({
			label: $translate.instant($ctrl.label),
			value: $ctrl.value,
			group: $translate.instant($ctrl.group)
		});
	};
}

app.component('wfOption', {
	controller: OptionController,
	require: {
		select: '^wfSelect'
	},
	bindings: {
		label: '@',
		value: '@',
		group: '@'
	}
});
