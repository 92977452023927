import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, PAGE_SIZES, SMALL_PAGE_SIZES) {
	var states = [
		{
			name: 'auditLog',
			parent: 'admin',
			url:
				'/auditLogs?page&sort&size&userName&operation&module&operationDate',
			data: {
				viewAuthorities: ['ORGANIZATIONS_READ', 'ORGANIZATIONS_READ'],
				editAuthorities: ['ORGANIZATIONS_WRITE', 'ORGANIZATIONS_WRITE']
			},
			component: 'auditLogSearch',
			params: {
				size: {
					value: PAGE_SIZES[0],
					type: 'int',
					squash: true,
					dynamic: true
				},
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'name,asc',
					squash: true,
					dynamic: true
				},
				userName: {
					value: '',
					squash: true,
					dynamic: true
				},
				operation: {
					value: null,
					squash: true,
					dynamic: true
				},
				module: {
					value: null,
					squash: true,
					dynamic: true
				},
				operationDate: {
					value: null,
					squash: true,
					dynamic: true
				}
			}
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
