import app from '@/app';
import angular from 'angular';

/* @ngInject */
function errorHandlerInterceptor($q, $translate, AlertService) {
	this.responseError = function(response) {
		switch (response.status) {
			case 0:
				AlertService.error('message.server.unreachable');
				break;
			case 400:
				if (response.data) {
					if (response.data.fieldErrors) {
						for (var index in response.data.fieldErrors) {
							var error = response.data.fieldErrors[index];
							AlertService.error('message.' + error.code, {
								entity: error.objectName,
								field: error.field.replace(/\[\d*\]/g, '[]')
							});
						}
					} else if (response.data.code) {
						AlertService.error('message.' + response.data.code, response.data);
					} else {
						AlertService.error(response.data);
					}
				} else {
					AlertService.error(response.status);
				}
				break;
			case 401:
				if (
					!(
						response.data === '' ||
						(response.data.path &&
							(response.data.path.includes('/api/users/current') ||
								response.data.path.includes('/api/account')))
					)
				) {
					AlertService.error('login.failed');
				}
				break;
			case 403:
				AlertService.error('message.resource.forbidden');
				break;
			case 404:
				if (response.data.path && !response.data.path.includes('/api/account')) {
					AlertService.error('message.resource.notfound');
				}
				break;
			case 504:
				break;
			default:
				if (response.data && response.data.message) {
					AlertService.error(response.data.message);
				} else {
					AlertService.error(angular.toJson(response));
				}
		}
		return $q.reject(response);
	};
}

app.service('errorHandlerInterceptor', errorHandlerInterceptor);
