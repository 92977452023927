import app from '@/app';


/* @ngInject */
function stateConfig($stateProvider) {
	$stateProvider.state( {
    
    name : 'requestReset',
		parent: 'account',    
		url: '/reset/request',
		component: 'requestReset',    
	});
}

app.config(stateConfig);
