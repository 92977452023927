import app from '@/app';
import templateUrl from './road-accessory-detail.html';

/* @ngInject */
function controller($controller, $state, Principal) {
	var $ctrl = this;
	$controller('AbstractController', { $ctrl: $ctrl });

	var transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		transition(params);
		$ctrl.entity = $ctrl.parent.loadAccessory(params.iid);
	};

  Principal.identity().then(account => {
    $ctrl.currentAccount = account;
    })

	$ctrl.save = function() {
		$ctrl.parent.saveAccessory($ctrl.entity);
		$state.go('^');
	};
}

const component = {
	templateUrl,
	controller,
	require: {
		parent: '^roadDetail'
	},
	bindings: {
		$transition$: '<'
	}
};

app.component('roadAccessoryDetail', component);