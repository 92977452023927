import app from '@/app';
import templateUrl from './decision-tree-node-detail.html';

/* @ngInject */
function DecisionTreeNodeDetailController($controller, $state, Solution, DecisionTree, Principal) {
	const $ctrl = this;
	$controller('AbstractController', { $ctrl });

	$ctrl.idgen = -1;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	const transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		transition(params);
		$ctrl.key = params.iid;
		$ctrl.entity = $ctrl.parent.loadNode($ctrl.key);
		$ctrl.entity.type = $ctrl.entity.type || 'LEAF';
	};

	$ctrl.save = function() {
		$ctrl.parent.saveNode($ctrl.key, $ctrl.entity);
		$state.go('^');
	};

	$ctrl.onConditionChange = function(condition) {
		$ctrl.entity.condition = condition;
	};
}

const component = {
	templateUrl,
	controller: DecisionTreeNodeDetailController,
	require: {
		parent: '^decisionTreeDetail'
	},
	bindings: {
		$transition$: '<',
		allMetrics: '<'
	}
};

app.component('decisionTreeNodeDetail', component);
