import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider) {
	$stateProvider.state({
		name: 'password',
		parent: 'account',
		url: '/password',
		component: 'password'
	});
}

app.config(stateConfig);
