import app from '@/app';

app.factory('PasswordResetInit', PasswordResetInit);

/* @ngInject */

function PasswordResetInit($resource) {
	var service = $resource('api/account/reset-password/init', {}, {});

	return service;
}
