import app from '@/app';
import templateUrl from './criteria-set-detail.html';

/* @ngInject */
function Controller($controller, CriteriaSet, Principal) {
	
  var $ctrl = this;

  $ctrl.currentAccount = 
  
  $controller('AbstractEntityDetailController', {
		$ctrl: this,
		resource: CriteriaSet,
		type: 'criteriaSet'
	});

  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	})

}

var component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<',
		entity: '<'
	}
};

app.component('criteriaSetDetail', component);
