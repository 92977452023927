import app from '@/app';

function filter() {
	return val =>
		Array.isArray(val)
			? val.filter((value, index, self) => self.indexOf(value) === index)
			: null;
}

app.filter('uniq', filter);
