import app from '@/app';
import templateUrl from './unifilarParametersComments-segment-detail.html';

/* @ngInject */
function controller($controller, $state, ConstantSet) {
	var $ctrl = this;
	$controller('AbstractController', { $ctrl: $ctrl });

	var transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		transition(params);
		$ctrl.origin = _.find({ id: parseInt(params.iid) })($ctrl.parent.entity);
		$ctrl.entity = angular.extend({}, $ctrl.origin);
	};

	$ctrl.save = function() {
		if ($ctrl.origin) {
			angular.extend($ctrl.origin, $ctrl.entity);
		}
		$ctrl.parent.entity.filter(it => {
			if (it.id == $ctrl.entity.id) {
				it.dateConclusion = $ctrl.entity.dateConclusion;
				it.expectedSolution = $ctrl.entity.expectedSolution;

				it.workItem = $ctrl.entity.workItem;
				it.startDate = $ctrl.entity.startDate;
				it.endDate = $ctrl.entity.endDate;
				it.comments = $ctrl.entity.comments;
			}
		});
		$state.go('^');
	};
}

const component = {
	templateUrl,
	controller,
	require: {
		parent: '^unifilarParametersCommentsDetail'
	},
	bindings: {
		$transition$: '<'
	}
};

app.component('unifilarParametersCommentsSegmentDetail', component);
