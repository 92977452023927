import app from '@/app';
import templateUrl from './sorter.html';

function SorteableController() {
	var $ctrl = this;

	$ctrl.toggle = function(field) {
		$ctrl.onChange({
			sort: [field, $ctrl.field == field && $ctrl.order == 'asc' ? 'desc' : 'asc'].join(',')
		});
	};

	$ctrl.$onChanges = function(changes) {
		if (changes.sort && $ctrl.sort) {
			var parts = $ctrl.sort.split(',');
			$ctrl.field = parts[0];
			$ctrl.order = parts[1];
		}
	};
}

app.component('wfSorteable', {
	template: '<ng-transclude></ng-tranclude>',
	controller: SorteableController,
	transclude: true,
	bindings: {
		sort: '<',
		onChange: '&'
	}
});

app.component('wfSort', {
	templateUrl,
	transclude: true,
	require: {
		parent: '^wfSorteable'
	},
	bindings: {
		by: '@'
	}
});
