import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, PAGE_SIZES, SMALL_PAGE_SIZES) {
	const states = [
		{
			name: 'allPlansByProject',
			parent: 'analysis',
			url: '/allPlansByProject?page&sort&size&road&campaign&iqdtype',
			component: 'allPlansByProjectSearch',
			data: {
				viewAuthorities: ['ANALYSIS_READ', 'IQDTEST_READ'],
				editAuthorities: ['ANALYSIS_WRITE', 'ANALYSIS_PARTIAL_WRITE', 'IQDTEST_WRITE']
			},
			params: {
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'name,asc',
					squash: true,
					dynamic: true
				},
				road: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				campaign: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				iqdtype: {
					type: 'int',
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allOrganizations: [
					'Organization',
					Organization => {
						return Organization.query({ sort: 'name,asc' }).$promise;
					}
				],
				/* @ngInject */
				allRoads: Road => Road.query({ sort: 'name,asc', empty: false }).$promise,
				/* @ngInject */
				allCampaigns: Campaign =>
					Campaign.query({ sort: 'name,asc', empty: false }).$promise
			}
		},
		{
			name: 'allPlansByProject.detail',
			url: '/{id}',
			component: 'allPlansByProjectDetail',
			resolve: {
				/* @ngInject */
        entity: (AllPlansByProject, $transition$, entityInjector) =>
					entityInjector(AllPlansByProject, $transition$.params().id),
        /* @ngInject */
				allPlans: Plan =>
        Plan.query({plantype: 'DEFAULT'}).$promise,

			}
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
