import app from '@/app';
import templateUrl from './user-search.html';

/* @ngInject */
function controller($controller, $http, $state, User, Principal, LANGUAGES) {
	var $ctrl = this;
	$controller('AbstractEntitySearchController', { $ctrl: $ctrl, resource: User, type: 'user' });

	$ctrl.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
	$ctrl.languages = LANGUAGES;
	$ctrl.currentAccount = null;

	Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$ctrl.setActive = function(user, isActive) {
		$http.put('api/users/' + user.id + '/active', isActive).then(function() {
			user.active = isActive;
			//$state.reload();
		});
	};
}

const component = {
	templateUrl,
	controller,
	bindings: {
		$transition$: '<'
	}
};

app.component('userSearch', component);
