import app from '@/app';

/* @ngInject */
function Service($filter) {
	this.formatters = {
		asis: () => value => value,
		number(digits) {
			return value => $filter('number')(value, digits);
		}
	};
	this.aggregators = {
		count() {
			return () => ({
				data: 0,
				push(item) {
					this.data++;
				},
				value() {
					return this.data;
				}
			});
		},
		sum(field) {
			return () => ({
				data: 0,
				push(item) {
					const value = parseFloat(item[field]);
					if (value) {
						this.data += value;
					}
				},
				value() {
					return this.data;
				}
			});
		},
		avg() {
			return () => ({
				count: this.count(),
				sum: this.sum(),
				push(value) {
					this.count.push(value);
					this.sum.push(value);
				},
				value() {
					return this.sum.value() / this.count.value();
				}
			});
		}
	};

	const parts = /(\d+)|(\D+)/g;
	const digits = /\d/;
	const zero = /^0/;
	this.sorters = {
		naturalSort: function(lhs, rhs) {
			if (rhs != null && lhs == null) {
				return -1;
			} else if (lhs != null && rhs == null) {
				return 1;
			} else if (Number.isNaN(lhs)) {
				return -1;
			} else if (Number.isNaN(rhs)) {
				return 1;
			} else {
				const lhsNumber = Number(lhs);
				const rhsNumber = Number(rhs);
				if (lhsNumber < rhsNumber) {
					return -1;
				} else if (lhsNumber > rhsNumber) {
					return 1;
				} else if (typeof lhs === 'number' && typeof rhs !== 'number') {
					return -1;
				} else if (typeof lhs !== 'number' && typeof rhs === 'number') {
					return 1;
				} else if (typeof lhs === 'number' && typeof rhs === 'number') {
					return 0;
				} else if (!isNaN(lhsNumber) && isNaN(rhsNumber)) {
					return -1;
				} else if (isNaN(lhsNumber) && !isNaN(rhsNumber)) {
					return 1;
				} else {
					const lhsString = String(lhs);
					const rhsString = String(rhs);
					if (lhsString === rhsString) {
						return 0;
					} else if (!digits.test(lhsString) || !digits.test(rhsString)) {
						return lhsString > rhsString ? 1 : -1;
					} else {
						lhsMatch = lhsString.match(rx);
						rhsMatch = rhsString.match(rx);
						while (lhsMatch.length && rhsMatch.length) {
							let lhsPart = lhsMatch.shift();
							let rhsPart = rhsMatch.shift();
							if (lhsPart !== rhsPart) {
								if (rd.test(lhsPart) && rd.test(rhsPart)) {
									return (
										lhsPart.replace(zero, '.0') - rhsPart.replace(zero, '.0')
									);
								} else {
									return lhsPart > rhsPart ? 1 : -1;
								}
							}
						}
						return lhsMatch.length - rhsMatch.length;
					}
				}
			}
		},
		sortAs: function(order) {
			return function(lhs, rhs) {
				let lhsIndex = order.indexOf(lhs);
				let rhsIndex = order.indexOf(rhs);
				if (lhsIndex >= 0 && rhsIndex >= 0) {
					return lhsIndex - rhsIndex;
				} else if (lhsIndex < 0) {
					return -1;
				} else if (rhsIndex < 0) {
					return 1;
				} else {
					return 0;
				}
			};
		}
	};
}

app.service('wfPivotUtils', Service);
