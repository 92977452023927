import app from '@/app';

app.factory('PasswordResetFinish', PasswordResetFinish);

/* @ngInject */

function PasswordResetFinish($resource) {
	var service = $resource('api/account/reset-password/finish', {}, {});

	return service;
}
