import app from '@/app';

/* @ngInject */
function DateUtils($filter) {
	var service = {
		convertDateTimeFromServer: convertDateTimeFromServer,
		convertLocalDateFromServer: convertLocalDateFromServer,
		convertLocalDateToServer: convertLocalDateToServer,
		dateformat: dateformat
	};

	return service;

	function convertDateTimeFromServer(date) {
		return date ? new Date(date) : null;
	}

	function convertLocalDateFromServer(date) {
		if (date) {
			var dateString = date.split('-');
			return new Date(dateString[0], dateString[1] - 1, dateString[2]);
		}
		return null;
	}

	function convertLocalDateToServer(date) {
		if (date) {
			return $filter('date')(date, 'yyyy-MM-dd');
		} else {
			return null;
		}
	}

	function dateformat() {
		return 'yyyy-MM-dd';
	}
}

app.factory('DateUtils', DateUtils);
