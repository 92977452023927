import app from '@/app';
import templateUrl from './update-button.html';

app.component('prUpdateButton', {
	templateUrl,
	bindings: {
		type: '@',
		state: '@',
		params: '<'
	}
});
