import app from '@/app';
import templateUrl from './road-segment-detail.html';

/* @ngInject */
function controller($controller, $state, ConstantSet, Principal) {
	var $ctrl = this;
	$controller('AbstractController', { $ctrl: $ctrl });

	var transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		transition(params);
		$ctrl.entity = $ctrl.parent.loadSegment(params.iid);
	};

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
    $ctrl.currentAccount = account;
    })

	$ctrl.validateIntersection = function(headMilestone, tailMilestone, type) {
		var intersections = $ctrl.parent.entity.segments.filter(function(segment) {
			if (segment.id === $ctrl.entity.id) {
				return false;
			} else if (headMilestone >= segment.headMilestone) {
				return headMilestone < segment.tailMilestone;
			} else {
				return segment.headMilestone < tailMilestone;
			}
		});
		return intersections.length == 0;
	};

	$ctrl.queryConstantSets = function(query) {
		$ctrl.loading = true;
		return ConstantSet.query({ query: query, size: 10, sort: 'name,asc' }, function(data) {
			$ctrl.allConstantSets = data;
			$ctrl.loading = false;
		}).$promise;
	};

	$ctrl.save = function() {
		$ctrl.parent.saveSegment($ctrl.entity);
		$state.go('^');
	};
}

const component = {
	templateUrl,
	controller,
	require: {
		parent: '^roadDetail'
	},
	bindings: {
		$transition$: '<'
	}
};

app.component('roadSegmentDetail', component);