import app from '@/app';
import { GrowthRateResourceClient } from '../../pavrunner-api';
import templateUrl from './system-parameters-search.html';

/* @ngInject */
function Controller($controller, SystemParameters, Principal) {
	
  var $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});
  
  $controller('AbstractEntitySearchController', {
		$ctrl: this,
		resource: SystemParameters,
		type: 'systemParameters'
	});

}

const component = {
	templateUrl,
	controller: Controller,
	bindings: {
		$transition$: '<'
	}
};

app.component('systemParametersSearch', component);