import app from '@/app';
import templateUrl from './plan-historic.html';

/* @ngInject */
function Controller($controller, PlanHistoric, Principal) {
	var $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
    $ctrl.currentAccount = account;
    })
  
  $controller('AbstractController', { $ctrl });

  this.$onInit = function() {
    PlanHistoric.query(
      {
        idplan: $ctrl.entity.id 
      },
      data => {
        $ctrl.entities = data.content;
        $ctrl.count = data.totalElements;
      }
    );
  };

  


}

app.component('planHistoric', {
	templateUrl,
	controller: Controller,
	require: {
		parent: '^planSearch'
	},
	bindings: {
		$transition$: '<',
		entity: '<'
	}
});

