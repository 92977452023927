import app from '@/app';

/* @ngInject */
function Service($uibModal) {
	var modalInstance = null;

	var resetModal = function() {
		modalInstance = null;
	};

	this.open = function() {
		if (modalInstance === null) {
			modalInstance = $uibModal.open({
				component: 'loginDialog'
			});
			modalInstance.result.then(resetModal, resetModal);
		}
	};
}

app.service('LoginService', Service);
