import app from '@/app';

/* @ngInject */
function stateConfig($stateProvider, PAGE_SIZES, SMALL_PAGE_SIZES) {
	const states = [
		{
			name: 'reinforcementCalculation',
			parent: 'analysis',
			url: '/reinforcementCalculations?page&sort&size&road&campaign&plantype',
			component: 'reinforcementCalculationSearch',
			data: {
				viewAuthorities: ['ANALYSIS_READ', 'REINFORCEMENTCALCULATION_READ'],
				editAuthorities: ['ANALYSIS_WRITE', 'ANALYSIS_PARTIAL_WRITE', 'REINFORCEMENTCALCULATION_WRITE']
			},
			params: {
				page: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				sort: {
					value: 'name,asc',
					squash: true,
					dynamic: true
				},
				road: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				campaign: {
					type: 'int',
					squash: true,
					dynamic: true
				},
				plantype: {
					type: 'int',
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allOrganizations: [
					'Organization',
					Organization => {
						return Organization.query({ sort: 'name,asc' }).$promise;
					}
				],
				/* @ngInject */
				allRoads: Road => Road.query({ sort: 'name,asc', empty: false }).$promise,
				/* @ngInject */
				allCampaigns: Campaign =>
					Campaign.query({ sort: 'name,asc', empty: false }).$promise,

        /* @ngInject */
        allGrowthRates: GrowthRate => GrowthRate.query({query: ''}).$promise
			}
		},
		{
			name: 'reinforcementCalculation.detail',
			url: '/{id}',
			component: 'reinforcementCalculationDetail',
			resolve: {
				/* @ngInject */
				entity: (ReinforcementCalculation, $transition$, entityInjector) =>
					entityInjector(ReinforcementCalculation, $transition$.params().id, {})
			}
		},
		{
			name: 'reinforcementCalculation.result',
			url:
				'/{id}/result?milestonePage&milestoneSize&yearPage&yearSize&direction&lanes&metric',
			component: 'reinforcementCalculationResult',
			params: {
				milestonePage: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				milestoneSize: {
					value: PAGE_SIZES[3],
					type: 'int',
					squash: true,
					dynamic: true
				},
				yearSize: {
					value: SMALL_PAGE_SIZES[1],
					type: 'int',
					squash: true,
					dynamic: true
				},
				yearPage: {
					value: 0,
					type: 'int',
					squash: true,
					dynamic: true
				},
				direction: {
					value: null,
					squash: true,
					dynamic: true
				},
				lanes: {
					array: true,
					value: [],
					type: 'int',
					squash: true,
					dynamic: true
				},
				metric: {
					value: null,
					squash: true,
					dynamic: true
				}
			},
			resolve: {
				/* @ngInject */
				allMetrics: Metadata =>
					Metadata.getMetrics({ measurement: 'KPI', view: 'REINFORCEMENT' }),
				/* @ngInject */
				entity: (ReinforcementCalculation, $transition$, entityInjector) =>
					entityInjector(ReinforcementCalculation, $transition$.params().id, {})
			}
		},
		{
			name: 'reinforcementCalculation.overview',
			url: '/{reinforcementCalculationId}/overviews/{overviewId}',
			component: 'reinforcementCalculationOverviewWrapper'
		}
	];

	states.forEach(state => $stateProvider.state(state));
}

app.config(stateConfig);
