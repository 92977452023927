import angular from 'angular';
import 'chartjs-plugin-annotation';
import 'chart.piecelabel.js';

import './css/bootswatch/yetiModified/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'ui-select/dist/select.css';
import './css/main.css';
import './css/alert.css';
import './css/sorter.css';
import './css/tree.css';

import './app/app.module';

const context = require.context('./app', true, /\.(js|html|json)$/);
context
	.keys()
	.filter(file => !/app\.module\.js$/.test(file))
	.forEach(context);

//angular.bootstrap(document.createElement('div'), ['pavrunner']);
