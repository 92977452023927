import app from '@/app';
import angular from 'angular';

const component = {
	templateUrl: '/app/account/reset/finish/reset.finish.html',
	controller: Controller,
	bindings: {
		$transition$: '<'
	}
};

/* @ngInject */
function Controller(Auth, LoginService, $timeout) {
	var $ctrl = this;

	$ctrl.$onInit = function() {
		$ctrl.key = $ctrl.$transition$.params().key;
		$ctrl.keyMissing = angular.isUndefined($ctrl.key);
		$ctrl.login = LoginService.open;
		$ctrl.doNotMatch = null;
		$ctrl.error = null;
		$ctrl.success = null;
	};

	$timeout(() => angular.element('#password').focus());

	$ctrl.changePassword = function() {
		$ctrl.doNotMatch = null;
		$ctrl.error = null;
		if ($ctrl.password !== $ctrl.confirmPassword) {
			$ctrl.doNotMatch = 'ERROR';
		} else {
			Auth.resetPasswordFinish({ key: $ctrl.key, newPassword: $ctrl.password })
				.then(function() {
					$ctrl.success = 'OK';
				})
				.catch(function() {
					$ctrl.success = null;
					$ctrl.error = 'ERROR';
				});
		}
	};
}

app.component('resetFinish', component);
