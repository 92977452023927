import app from '@/app';
import angular from 'angular';
import templateUrl from './decision-tree-detail-historic.html';

/* @ngInject */
function DecisionTreeDetailController($controller, $scope, $timeout, DecisionTree, Principal) {
	const $ctrl = this;

  $ctrl.currentAccount = null;
  
  Principal.identity().then(account => {
	$ctrl.currentAccount = account;
	})

	$controller('AbstractEntityDetailController', {
		$ctrl,
		resource: DecisionTree,
		type: 'decisionTree'
	});

	$ctrl.dirtyItems = function() {
		$timeout(() => $ctrl.decisionTreeForm.items.$setDirty());
	};

	$ctrl.touchNode = function(action, key, value) {
		let item =
			value &&
			value.type &&
			!(value.type === 'LEAF' && value.solutions.length == 0 && value.subtrees.length == 0)
				? value
				: null;
		let node;
		for (var index = 0; index < key.length; ++index) {
			var last = index + 1 == key.length;
			var code = key.charAt(index);
			switch (code) {
				case 'R':
					if (last && action === 'set') {
						$ctrl.entity.root = item;
					}
					node = $ctrl.entity.root;
					break;
				case 'P':
					if (last && action === 'set') {
						node.positive = item;
					}
					node = node.positive;
					break;
				case 'N':
					if (last && action === 'set') {
						node.negative = item;
					}
					node = node.negative;
					break;
			}
		}
		return angular.extend({ type: 'LEAF', key: key, solutions: [], subtrees: [] }, node);
	};

	$ctrl.loadNode = function(key) {
		return $ctrl.touchNode('get', key);
	};

	$ctrl.saveNode = function(key, node) {
		$ctrl.touchNode('set', key, node);
		$ctrl.dirtyItems();
	};
}

const component = {
	templateUrl,
	controller: DecisionTreeDetailController,
	require: {
		parent: '^planHistoric'
	},
	bindings: {
		$transition$: '<',
		allSolutions: '<',
		entity: '<'
	}
};

app.component('decisionTreeDetailHistoric', component);
