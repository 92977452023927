import app from '@/app';
import templateUrl from './plan-resultChartLine.html';
import angular from 'angular';

/* @ngInject */
function controller(
	$controller,
	$filter,
	$http,
	$httpParamSerializer,
	$state,
	$translate,
	DialogService,
	wfPivotUtils,
	wfPager2Service,
	PAGE_SIZES,
	SMALL_PAGE_SIZES,
	Principal,
	$scope
) {
	var $ctrl = this;

	$ctrl.currentAccount = null;

	$ctrl.showChart = false;

	$ctrl.labels = [];
	$ctrl.series = [];
	$ctrl.data = [];
	$ctrl.roadSegments = [];

	Principal.identity().then(account => {
		$ctrl.currentAccount = account;
	});

	$controller('AbstractController', { $ctrl: $ctrl });

	$ctrl.onQueryChange = function(query) {
		$state.go(
			$state.current,
			{
				milestonePage: $ctrl.milestonePage,
				milestoneSize: $ctrl.milestoneSize,
				yearPage: $ctrl.yearPage,
				yearSize: $ctrl.yearSize,
				direction: $ctrl.direction,
				lanes: $ctrl.lanes,
				metric: $ctrl.metric,
				chartYear: $ctrl.chartYear,
				years: $ctrl.years
			},
			{
				reload: true
			}
		);
	};

	var transition = $ctrl.transition;
	$ctrl.transition = function(params) {
		transition(params);

		$ctrl.milestonePage = params.milestonePage != null ? parseInt(params.milestonePage) : 0;
		$ctrl.milestoneSize =
			params.milestoneSize != null ? parseInt(params.milestoneSize) : PAGE_SIZES[3];
		$ctrl.milestoneCount =
			1 +
			($ctrl.entity.tailMilestone - $ctrl.entity.headMilestone) / $ctrl.entity.segmentLength;

		const milestoneRange = wfPager2Service.createPage(
			$ctrl.milestonePage,
			$ctrl.milestoneSize,
			$ctrl.milestoneCount,
			$ctrl.entity.segmentLength,
			$ctrl.entity.headMilestone
		);
		$ctrl.headMilestone = milestoneRange.head;
		$ctrl.tailMilestone = milestoneRange.tail;

		$ctrl.yearPage = params.yearPage != null ? parseInt(params.yearPage) : 0;
		$ctrl.yearSize = params.yearSize != null ? parseInt(params.yearSize) : SMALL_PAGE_SIZES[0];
		$ctrl.yearCount = $ctrl.entity.processedYears;

		$ctrl.direction = params.direction;
		$ctrl.lanes = params.lanes;
		$ctrl.metric = params.metric;
		$ctrl.beforeBuilt = params.beforeBuilt == 0 ? false : true;

		$ctrl.direction = params.direction;
		$ctrl.lanes = params.lanes;
		$ctrl.metric = params.metric;
		$ctrl.chartYear = params.chartYear;
		$ctrl.years = params.years;

		var metric = $ctrl.metric && _.find({ code: $ctrl.metric })($ctrl.allMetrics);

		const query = _.pick([
			'headMilestone',
			'tailMilestone',
			'headYear',
			'tailYear',
			'direction',
			'lanes',
			'metric',
			'road',
			'campaign',
			'years'
		])($ctrl);

		$ctrl.allMetrics = $ctrl.allMetrics.filter(item => item.code.indexOf('BEFORE_') == -1);
		$ctrl.labels = $ctrl.entity.roadSegments;

		$http
			.get('api/plans/' + $ctrl.entity.id + '/results', {
				params: query
			})
			.then(function(response) {
				$ctrl.entities = response.data;
			});

		//For loading chart --> version Angular.js bug
		setTimeout(() => {
			$scope.showChart = true;
		}, 1000);

		$http
			.get('api/plans/' + $ctrl.entity.id + '/resultsChartLine', {
				params: query
			})
			.then(function(response) {
				if (response.data && response.data.length > 0) {
					//Ordenando lista pelo km inicial
					//Sorting list by km-starting
					$ctrl.entities = $filter('orderBy')(response.data, 'headMilestone');

					//Ordenando e extraindo faixas
					//Sorting and extracting lanes
					$ctrl.chartLanes = $filter('orderBy')(
						$ctrl.entities
							.map(function(obj) {
								return { lane: obj.lane, year: obj.year };
							})
							.filter(function(item, index, self) {
								return self.indexOf(item) === index;
							})
					);

					//Criando lista de valores do eixo y
					//Creating list of y-axis values
					$ctrl.chartLanes.forEach(function(lane) {
						$ctrl.dataForLane = [];
						// eslint-disable-next-line no-redeclare
						for (var item of $ctrl.entities) {
							if (item.lane == lane.lane) {
								$ctrl.dataForLane.push(
									item[metric.name] != 'NaN' ? item[metric.name] : 0
								);
							}
						}
						$ctrl.data.push($ctrl.dataForLane);
					});

					//Carregando título
					//Loading title
					$ctrl.chartTitle = $translate.instant('measurement.kpi.' + metric.name);

					$ctrl.refresh();

					//Configurando gráfico
					//Configuring chart
					$ctrl.datasetOverride = [{}];
					$ctrl.optionsChart = {
						backgroundColor: 'rgb(240, 240, 240)',
						elements: {
							line: {
								fill: false,
								tension: 0
							}
						},
						animation: false,
						scales: {
							xAxes: [
								{
									scaleLabel: {
										display: true,
										labelString: $translate.instant('common.milestone')
									}
								}
							],
							yAxes: [
								{
									scaleLabel: {
										display: true,
										labelString: $translate.instant('entity.detail.value')
									}
								}
							]
						},
						title: {
							display: true,
							text: $ctrl.chartTitle
						},
						legend: {
							display: true
						},
						responsive: true
						//maintainAspectRatio: false
					};
				}
			});

		$ctrl.refresh = () => {
			$ctrl.series = [];
			$ctrl.data = [];
			if ($ctrl.entities && $ctrl.entities.length) {
				const points = $ctrl.entities.map(entity => ({
					serie:
						entity.lane != 100 && entity.lane != -100
							? entity.year +
							  '-' +
							  $translate.instant('common.lane') +
							  ' ' +
							  Math.abs(entity.lane) +
							  ' (' +
							  $translate.instant(`direction.${entity.direction}.short`) +
							  ')'
							: entity.year +
							  '-' +
							  ' ' +
							  'Acostamento' +
							  ' (' +
							  $translate.instant(`direction.${entity.direction}.short`) +
							  ')',
					x: entity.milestone,
					y: entity[metric.name] * (metric.factor || 1.0)
				}));

				$ctrl.grouped = _.flow(_.sortBy('x'), _.groupBy('serie'))(points);

				for (var key in $ctrl.grouped) {
					var group = $ctrl.grouped[key];
					$ctrl.series.push(key);
					$ctrl.data.push(group);
				}
			}
		};
	};
}

const component = {
	templateUrl,
	controller,
	bindings: {
		$transition$: '<',
		allMetrics: '<',
		entity: '<',
		chartYears: '<'
	}
};

app.component('planResultChartLine', component);
